import { ListItem, ListItemButton, ListItemIcon, ListItemText, Modal, Box, List, Badge } from "@mui/material";
import React from "react";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "60%",
	bgcolor: "background.paper",
	borderRadius: "4px",
	// padding: "3em",
	// border: "2px solid #000",
	boxShadow: 24,
	maxHeight: "80vh",
	p: 0,
	textAlign: "center",
	overflow: "auto",
};
function ShowAnswerScriptModal({ open, handler, answers }) {
	// const answers = [
	// 	{ ans: "prashne... ", que: "sdfsdfdsf" },
	// 	{ ans: "prashne... ", que: "sdfsdfdsf" },
	// 	{ ans: "prashne... ", que: "sdfsdfdsf" },
	// 	{ ans: "prashne... ", que: "sdfsdfdsf" },
	// 	{ ans: "prashne... ", que: "sdfsdfdsf" },
	// 	{ ans: "prashne... ", que: "sdfsdfdsf" },
	// 	{ ans: "prashne... ", que: "sdfsdfdsf" },
	// 	{ ans: "prashne... ", que: "sdfsdfdsf" },
	// 	{ ans: "prashne... ", que: "sdfsdfdsf" },
	// 	{ ans: "prashne... ", que: "sdfsdfdsf" },
	// 	{ ans: "prashne... ", que: "sdfsdfdsf" },
	// 	{ ans: "prashne... ", que: "sdfsdfdsf" },
	// ];

	return (
		<Modal
			open={open}
			onClose={handler}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box style={style}>
				<List sx={{ bgcolor: "#e1f5fe" }}>
					<ListItem
						sx={{
							display: "list-item",
							bgcolor: "white",
						}}
					>
						<h4>Answers</h4>
					</ListItem>
					{Array.isArray(answers) &&
						answers.map((answer, index) => (
							<ListItem
								key={index}
								disablePadding
								sx={{
									display: "list-item",
									bgcolor: "#f1f8e",
								}}
							>
								<ListItemButton>
									<ListItemIcon>
										<Badge badgeContent={index + 1} color="primary" sx={{ ml: 1.5 }}></Badge>
									</ListItemIcon>

									<ListItemText
										primary={answer.que}
										primaryTypographyProps={{
											fontSize: 15,
											fontWeight: "600",
											letterSpacing: 0,
											marginLeft: "-25px",
										}}
									/>
								</ListItemButton>

								<ListItemText
									sx={{
										paddingLeft: "5em",
										paddingTop: "1em",
										paddingBottom: "1em",
										bgcolor: "#b3e5fc",
									}}
									primary={answer.ans}
								></ListItemText>
							</ListItem>
						))}
				</List>
			</Box>
		</Modal>
	);
}

export default ShowAnswerScriptModal;
