import baseApi from "./@baseURL";

const enrollForCourse = async (body) => {
	try {
		const res = await baseApi.post("enroll/", body);
		return res.data;
	} catch (error) {
		//TODO: add custom error
		if (error.response.status === 403) {
			alert("Already Enrolled");
		}

		console.log(error);
	}
};
const allEnrolledCourses = async (id) => {
	try {
		const res = await baseApi.get("enroll/all/" + id);
		return res.data;
	} catch (error) {
		//TODO: add custom error

		console.log(error);
	}
};
const allCompletedCourses = async (id) => {
	try {
		const res = await baseApi.get("enroll/all/completed/" + id);
		return res.data;
	} catch (error) {
		//TODO: add custom error

		console.log(error);
	}
};
const getEnrollment = async (body) => {
	console.log(body);
	try {
		const res = await baseApi.get("enroll/", body);
		return res.data;
	} catch (error) {
		//TODO: add custom error

		console.log(error);
	}
};

export { enrollForCourse, allEnrolledCourses, allCompletedCourses, getEnrollment };
