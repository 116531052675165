import React from "react";
import { Typography, Button, Box, Alert } from "@mui/material";
import Logo from "../assets/images/logo.png";
import { signUp } from "../util/api/instructor";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

//TODO:handle errors
//TODO:add custom error to instructor 401 {message:request account aprovel}
function InstructorSignUp() {
	const {
		register,
		getValues,
		formState: { errors },
		handleSubmit,
	} = useForm();

	const navigate=useNavigate()
	const onSubmit = async(data) => {
		const res =await signUp({email:data.email,password:data.password2,name:data.name})
		if(res){console.log(res)}
		//TODO:custom alert
		alert("Please wait until admins approve this account")
		navigate("/signin",{replace:true})
	};

	return (
		<div>
			<div className="loginContainer">
				<div
					style={{
						display: "flex",
						alignContent: "center",
						flexWrap: "wrap",
						justifyContent: "center",
						flexDirection: "column",
						margin: "0px",
					}}
				>
					<center>
						<div className="logoContainer">
							<img src={Logo} width="150px" alt="seekahost"></img>
						</div>
					</center>
					<Box
						sx={{
							boxShadow: "0 0 1px rgb(0 0 0 / 10%), 0 2px 24px rgb(0 0 0 / 8%)",
							width: "100%",
							maxWidth: "420px",
							margin: "1em",
							borderRadius: "1%",
							padding: "3em",
						}}
						component="form"
						onSubmit={handleSubmit(onSubmit)}
					>
						<center>
							<Typography
								variant="h1"
								sx={{
									color: "#0c0d0f",
									fontSize: "32px",
									lineHeight: "40px",
									marginBottom: "32px",
									fontWeight: "700",
									fontFamily: "inherit",
									textTransform: "capitalize",
								}}
							>
								{"Instructor Sign up"}
							</Typography>
							<Alert
								severity="error"
								sx={{ marginBottom: "2px" }}
								hidden={!(errors.email || errors.password1 || errors.password2)}
							>
								Invalid Credentials
							</Alert>
						</center>
						<div className="formControll">
							<label className="label">Email Address</label>
							<input
								type="email"
								name="username"
								placeholder="Enter Email"
								className="inputLogin"
								{...register("email", { required: true })}
							></input>
						</div>
						<div className="formControll">
							<label className="label">Name</label>
							<input
								name="name"
								placeholder="Name"
								className="inputLogin"
								{...register("name", { required: true })}
							></input>
						</div>
						<div className="formGrp">
							<label className="label">Password</label>
							<input
								type="password"
								name="password"
								placeholder="Password"
								className="inputLogin"
								{...register("password1", { required: true, minLength: 6 })}
							></input>
						</div>
						<div className="formGrp">
							<label className="label">Confirm Password</label>
							<input
								type="password"
								name="password"
								placeholder="Password"
								className="inputLogin"
								{...register("password2", {
									required: true,
									minLength: 6,

									validate: {
										matchesPreviousPassword: (value) => {
											const { password1 } = getValues();
											return password1 === value || "Passwords should match!";
										},
									},
								})}
							></input>
						</div>
						<center>
							<Button variant="contained" className="loginButton" size="large" type="submit">
								Sign Up
							</Button>
						</center>
						<center></center>
					</Box>
				</div>
			</div>
		</div>
	);
}
export default InstructorSignUp;
