import {
	ListItemButton,
	ListItem,
	ListItemIcon,
	Divider,
	ListItemText,
	Button,
} from "@mui/material";
import HelpCenterOutlined from "@mui/icons-material/HelpCenterOutlined";
import React from "react";

function QuestionsView({
	index,
	QuestionTitle,
	Question,
	onSelect,
	selcted: selected,
}) {
	return (
		<ListItem
			disablePadding
			sx={{
				display: "list-item",
				bgcolor: selected(index) ? "#f0f0f0" : "#FFFF",
			}}
		>
			<ListItemButton onClick={() => onSelect(index)}>
				<ListItemIcon>
					<HelpCenterOutlined />
				</ListItemIcon>

				<ListItemText
					primary={QuestionTitle}
					primaryTypographyProps={{
						fontSize: 15,
						fontWeight: "600",
						letterSpacing: 0,
						marginLeft: "-25px",
					}}
				/>

				{selected(index) ? (
					<Button color="warning">Cancel</Button>
				) : (
					<></>
				)}
			</ListItemButton>
			<Divider />
			<ListItemText
				sx={{ paddingLeft: "5em" }}
				primary={Question}
			></ListItemText>
		</ListItem>
	);
}

export default QuestionsView;