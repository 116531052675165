import { Box } from "@mui/system";
import Button from "@mui/material/Button";
// import Lottie from "react-lottie";
// import animationData from "../assets/lotties/AccessDenied.json";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link } from "react-router-dom";

function AccessDenied() {
	// const defaultOptions = {
	// 	loop: true,
	// 	autoplay: true,
	// 	animationData: animationData,
	// 	rendererSettings: {
	// 		preserveAspectRatio: "xMidYMid slice",
	// 	},
	// };

	return (
		<div>
			<center>
				<Box sx={{ width: "100%", padding: "10px" }}>
					{/* <Lottie options={defaultOptions} height={300} style={{ maxWidth: "300px" }} /> */}
				</Box>
				<h2 style={{ fontFamily: "sans-serif", color: "#9a9fa1" }}>Access Denied</h2>
				<Link to="/">
					<Button>
						<KeyboardBackspaceIcon />
						Go back home
					</Button>
				</Link>
			</center>
		</div>
	);
}
export default AccessDenied;
