import React, { useContext, createContext, useState, useEffect } from "react";
import {
	handleSignIn,
	handleSignOut,
	handleUpdatePassword,
	handleAdminSignIn,
	handleAdminSignout,
	handleAdminUpdatepassword,
} from "../api/auth";
import { vallidateUser } from "../validations/dataValidation";

const authContext = createContext();

//TODO:remove btoa
const AuthProvider = ({ children }) => {
	const [user, setuser] = useState("");
	const [admin, setadmin] = useState("");
	const [DP, setDP] = useState("");

	const signIn = async (username, password, userType) => {
		const res = await handleSignIn(username, password, userType);
		if (res) {
			const { image, ...rest } = res.user;
			sessionStorage.setItem("user", btoa(JSON.stringify(rest)));
			setuser(rest);
			setDP(image);
			sessionStorage.setItem("DP", JSON.stringify(image));
		} else {
			return { error: "Faild to Loging" };
		}
	};

	const changeDP = (image) => {
		setDP(image);
		sessionStorage.setItem("DP", JSON.stringify(image));
	};

	const signOut = () => {
		//TODO:signout with back end
		const res = handleSignOut(user._id, user.type);
		if (res) {
			setuser("");

			sessionStorage.removeItem("user");
			sessionStorage.removeItem("_AT");
			localStorage.removeItem("_RT");
			setDP("");
			sessionStorage.removeItem("DP");
		} else {
			return { error: "Faild to SignOut" };
		}
	};
	const updatePassword = async (body) => {
		//TODO:signout with back end
		const res = await handleUpdatePassword(user._id, body, user.type);
		if (res) {
			setuser("");
			sessionStorage.removeItem("user");
		} else {
			return { error: "Faild to update the Password" };
		}
	};

	//===========================Admin
	const adminSignIn = async (username, password) => {
		const res = await handleAdminSignIn({ username, password });
		if (res) {
			setadmin(res.user);
			sessionStorage.setItem("sudo", btoa(JSON.stringify(res.user)));
		} else {
			return { error: "Faild to update the Password" };
		}
	};
	const adminSignOut = async () => {
		handleAdminSignout(admin._id);
		sessionStorage.removeItem("_AT");
		localStorage.removeItem("_RT");
		setadmin("");
		sessionStorage.removeItem("sudo");
	};
	const adminUpdatePassword = async (body) => {
		//TODO:signout with back end
		const res = await handleAdminUpdatepassword(admin._id, body);
		if (res) {
			setadmin("");
			sessionStorage.removeItem("sudo");
		} else {
			return { error: "Faild to update the Password" };
		}
	};

	useEffect(() => {
		try {
			const localuser = atob(sessionStorage.getItem("user"));
			if (localuser && vallidateUser(JSON.parse(localuser))) {
				setuser(JSON.parse(localuser));
			}
		} catch (error) {
			//TODO: handle errors
		}
	}, []);
	useEffect(() => {
		try {
			const localDP = sessionStorage.getItem("DP");
			if (localDP) {
				setDP(JSON.parse(localDP));
			}
		} catch (error) {
			//TODO: handle errors
		}
	}, []);
	useEffect(() => {
		try {
			const adminuser = atob(sessionStorage.getItem("sudo"));
			if (adminuser && vallidateUser(JSON.parse(adminuser))) {
				setadmin(JSON.parse(adminuser));
			}
		} catch (error) {
			//TODO: handle errors
		}
	}, []);
	//===========================EXPORTING
	const authData = {
		user,
		DP,
		changeDP,
		signIn,
		signOut,
		updatePassword,
		admin,
		adminSignIn,
		adminSignOut,
		adminUpdatePassword,
	};
	return <authContext.Provider value={authData}>{children}</authContext.Provider>;
};

function useAuth() {
	return useContext(authContext);
}

export default useAuth;
export { AuthProvider };
