import React, { useRef, useState } from "react";
import { Box, Button, Paper } from "@mui/material";
import Vimeo from "@u-wave/react-vimeo";

function LecturePreview({ lecturePreview }) {
	const player = useRef(null);
	const handleSave = () => {
		// setcurrentPossition((prev) => {
		// 	if (prev[1] < numOfPerCurrentSection) {
		// 		return [prev[0], prev[1] + 1];
		// 	} else {
		// 		return [prev[0] + 1, 1];
		// 	}
		// });
	};

	const start = parseFloat(localStorage.getItem(lecturePreview._id + "pr")) || 0;
	const [videoProgress, setvideoProgress] = useState(
		parseFloat(localStorage.getItem(lecturePreview._id + "pe")) || 0
	);
	return (
		<Paper sx={{ borderRadius: "0.5em" }}>
			<Box
				sx={{
					borderTopLeftRadius: "0.5em",
					borderTopRightRadius: "0.5em",
					padding: "0.2em",
					paddingLeft: "1em",
					bgcolor: "#0670bf",
					color: "white",
				}}
			>
				{lecturePreview.title || "LECTURE TITLE IS EMPTY"}
			</Box>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					padding: "1em",
					flexDirection: "column",
				}}
			>
				{lecturePreview.catergory === "DV" && lecturePreview.contentURL ? (
					<Vimeo
						id={lecturePreview._id}
						start={start}
						onTimeUpdate={(e) => {
							if (e.seconds > 3) {
								localStorage.setItem(lecturePreview._id + "pr", e.seconds);
								localStorage.setItem(lecturePreview._id + "pe", e.percent);
								setvideoProgress(e.percent);
							}
						}}
						video={lecturePreview.contentURL}
						style={{ borderRadius: "1em" }}
						ref={player}
					/>
				) : (
					<></>
				)}
				<h6>{lecturePreview.description}</h6>
			</Box>
			<Box>
				{lecturePreview.catergory === "DV" ? (
					<Button disabled={videoProgress < 0.8} onClick={handleSave}>
						Save and Next
					</Button>
				) : (
					<Button onClick={handleSave}>Save and Next</Button>
				)}
			</Box>
		</Paper>
	);
}

export default LecturePreview;
