import { Box } from "@mui/system";
import { useState, useEffect } from "react";
import { getApprovedCourses } from "../util/api/courses";
import CourseCard from "../components/Couses/CourseCard";

import LoaderDefault from "../components/loaders/LoaderDefault";
import { useSnackbar } from "notistack";

//TODO:deseriealize the content from response
//TODO: add fee ,instructor name ,image url to course model
//TODO:add page limiting

function AllCourses() {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [courseDetails, setCourseDetails] = useState([]);

	const [loading, setloading] = useState(false);

	useEffect(() => {
		async function getCouses() {
			setloading(true);
			const res = await getApprovedCourses();
			setloading(false);
			if (res.error) {
				enqueueSnackbar("Unknown Error Ouccured", {
					variant: "error",
					autoHideDuration: 6000,
				});
			} else {
				setCourseDetails(res);
			}
		}
		getCouses();
	}, [enqueueSnackbar, closeSnackbar]);

	return loading ? (
		<LoaderDefault></LoaderDefault>
	) : (
		<Box sx={{ margin: "0px", width: "100%" }}>
			<center>
				<h1
					style={{
						fontFamily: "Open Sans",
						fontSize: "35px",
						color: "white",
						backgroundColor: "rgb(14 46 89)",
						marginBottom: "0px",
						padding: "10px",
						paddingTop: "35px",
						paddingBottom: "35px",
						// borderRadius: "50px",
					}}
				>
					Our Courses
				</h1>
				<Box
					sx={{
						display: "flex",
						flexWrap: "wrap",
						justifyContent: "center",
						backgroundColor: "#0e0b2c",
						marginBottom: "32px",
						padding: "70px",
						margin: "0px",
					}}
				>
					{courseDetails &&
						courseDetails.map((course) => (
							<CourseCard
								id={course._id}
								data={course}
								key={courseDetails.indexOf(course)}
								student_count={course.studentCount}
								fees="FREE"
								searchId={course.searchId}
								instructor={
									course.instructor && course.instructor.name
								}
								course_name={course.title}
								img_url={course.image}
							/>
						))}
				</Box>
			</center>
		</Box>
	);
}
export default AllCourses;
