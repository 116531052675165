import React, { useRef } from "react";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

function RichTextEditor({ setouput, initial }) {
	// const [editorData, seteditorData] = useState("");
	const editor = useRef();

	// The sunEditor parameter will be set to the core suneditor instance when this function is called
	const getSunEditorInstance = (sunEditor) => {
		editor.current = sunEditor;
		// setouput(editor.current.getContents());
	};
	// fixed bug :0024
	return (
		<div style={{ textAlign: "-webkit-auto" }}>
			{/* <p> My Other Contents </p> */}
			<SunEditor
				setDefaultStyle="font-family: Vazir; font-size: 18px;"
				defaultValue={initial}
				getSunEditorInstance={getSunEditorInstance}
				setOptions={{
					height: "auto",
					imageHeightShow: true,

					buttonList: buttonList.complex,
				}}
				onChange={() => {
					setouput(editor.current.getContents());
				}}
			/>
			{/* <button onClick={()=>{seteditorData(editor.current.getContents())}}>Show</button>
            <div className="cutom-div" dangerouslySetInnerHTML={{__html:editorData}}></div> */}
		</div>
	);
}
export default RichTextEditor;

// import { convertFromHTML, convertToRaw } from "draft-js";
// import { EditorState, ContentState } from "draft-js";
// import draftToHtml from "draftjs-to-html";
// import React, { useState } from "react";
// import { Editor } from "react-draft-wysiwyg";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// function RichTextEditor({ setouput, initial }) {
// 	const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

// 	const onEditorStateChange = (e) => {
// 		setouput(draftToHtml(convertToRaw(e.getCurrentContent())));
// 		//console.log(draftToHtml(convertToRaw(e.getCurrentContent())));
// 		setEditorState(e);
// 	};

// 	return (
// 		<div>
// 			<p>Editor</p>

// 			<div style={{ minHeight: "200px" }}>
// 				{initial ? (
// 					<InitialEditor initial={initial} onEditorStateChange={onEditorStateChange} />
// 				) : (
// 					<NewEditor editorState={editorState} onEditorStateChange={onEditorStateChange} />
// 				)}
// 			</div>
// 		</div>
// 	);
// }

// export default RichTextEditor;

// function readFileAsync(file) {
// 	return new Promise((resolve, reject) => {
// 		let reader = new FileReader();
// 		reader.onload = () => {
// 			resolve({ data: { link: reader.result } });
// 		};

// 		reader.onerror = reject;
// 		reader.readAsDataURL(file);
// 	});
// }
// function InitialEditor({ initial, onEditorStateChange }) {
// 	//const raw = JSON.parse(initial);
// 	const blocksFromHTML = convertFromHTML(initial);
// 	const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
// 	const onchange = (e) => {
// 		onEditorStateChange(e);
// 		setneweditor(e);
// 	};

// 	const [initEditorState, setneweditor] = useState(() => EditorState.createWithContent(state));
// 	return (
// 		<div>
// 			<Editor
// 				defaultContentState={state}
// 				editorState={initEditorState}
// 				toolbarClassName="toolbarClassName"
// 				wrapperClassName="wrapperClassName"
// 				editorClassName="editorClassName"
// 				onEditorStateChange={onchange}
// 				toolbar={{
// 					inline: { inDropdown: false },
// 					list: { inDropdown: false },
// 					textAlign: { inDropdown: true },
// 					link: { inDropdown: true },
// 					fontFamily: { options: ["Source Sans Pro, Helvetica, sans-serif"] },
// 					history: { inDropdown: true },
// 					image: {
// 						previewImage: true,
// 						uploadCallback: readFileAsync,
// 						alt: { present: false, mandatory: false },
// 					},
// 				}}
// 			/>
// 		</div>
// 	);
// }

// function NewEditor({ editorState, onEditorStateChange }) {
// 	return (
// 		<div>
// 			<Editor
// 				editorState={editorState}
// 				toolbarClassName="toolbarClassName"
// 				wrapperClassName="wrapperClassName"
// 				editorClassName="editorClassName"
// 				onEditorStateChange={onEditorStateChange}
// 				toolbar={{
// 					inline: { inDropdown: false },
// 					list: { inDropdown: false },
// 					textAlign: { inDropdown: true },
// 					link: { inDropdown: true },
// 					history: { inDropdown: true },
// 					image: {
// 						previewImage: true,
// 						uploadCallback: readFileAsync,
// 						alt: { present: false, mandatory: false },
// 					},
// 				}}
// 			/>
// 		</div>
// 	);
// }
