import React from "react";

function Error() {
	return (
		<div>
			<h1 style={{ fontSize: "4em" }}>Error</h1>
		</div>
	);
}

export default Error;
