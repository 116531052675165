import React from "react";
import "../../styles/loaders/loadingBouncer.css";
import loader from "../../assets/images/loader_new.gif";

function LoaderDefault() {
	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				padding: "48px",
			}}
		>
			<img src={loader} alt="loader" width="144px" height="144px"></img>
		</div>
	);
}

export default LoaderDefault;
