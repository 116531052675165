import React from "react";
import { Link } from "react-router-dom";
import { encode } from "../../../util/base64Encorder";

const Cardcomp = ({ image, title, description, id }) => {
	const getImage = (image) => {
		if (image) {
			if (image.type === "Buffer") {
				return "data:image/png;base64," + encode(image.data).toString();
			} else if (typeof image === "string") {
				return image;
			} else {
				return "";
			}
		} else {
			return "";
		}
	};
	return (
		<>
			<div className="col-sm-12 col-md-6 col-lg-3 centering">
				<div className="course-card">
					<div className="course-image">
						<img src={getImage(image)} alt={title} />
					</div>
					<div className="course-details">
						<Link to={"/courses/" + id} style={{ textDecoration: "none" }}>
							<p className="course-title">{title}</p>
						</Link>
						<p className="course-conduct-by">
							{"by "}
							<span className="course-conducter">
								<a
									href={"/courses/" + id}
									style={{
										textDecoration: "none",
									}}
								>
									{description}
								</a>
							</span>
						</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default Cardcomp;
