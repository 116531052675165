import { Button, List, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import QuestionsView from "./QuestionsView";
import { useForm } from "react-hook-form";
import { addQuiz, deleteQuiz, getQuiz, updateQuiz } from "../../util/api/quiz";
import { useNavigate, useParams } from "react-router-dom";
import LoaderDefault from "../loaders/LoaderDefault";

function QuizeEditor() {
	const params = useParams();
	const navigate = useNavigate();
	const { register, handleSubmit, reset, setValue } = useForm();
	const [questions, setquestions] = useState([]);
	const [selectedIndex, setselectedIndex] = useState(-1);
	const [isSelected, setisSelected] = useState(false);
	const [quizID, setquizID] = useState("");
	const [loading, setloading] = useState(false);

	const uploadQuiz = async () => {
		if (quizID) {
			setloading(true);
			const res = await updateQuiz(quizID, { questions });
			setloading(false);
			if (res) {
				alert("Added Successfully");
				// navigate("/Courses/editor/" + params.id, { replace: true });
			} else {
				alert("Oops! Something went wrong!");
			}
		} else {
			if (params && params.id) {
				setloading(true);
				const res = await addQuiz(params.id, { questions });
				if (res) {
					setquizID(res._id);
					setloading(false);
					alert("Added Successfully");
					// navigate("/Courses/editor/" + params.id, { replace: true });
					// navigate("/Courses/editor/" + params.id, { replace: true });
				} else {
					alert("Oops! Something went wrong!");
				}
			} else {
				//TODO:hndle errors
				alert("Invalid course");
			}
		}
	};

	const removeQuiz = async () => {
		const ans = window.confirm("Are you sure you want to delete the quiz ?");

		if (quizID && ans) {
			setloading(true);
			await deleteQuiz(quizID);
			navigate("/Courses/editor/" + params.id, { replace: true });
		}
	};

	function deleteQuestion() {
		if (isSelected) {
			setisSelected(false);
			setquestions((prev) => {
				return prev.filter((item, indx) => indx !== selectedIndex);
			});
		}
	}

	const onSubmit = (data, e) => {
		if (isSelected) {
			setquestions(
				questions
					.map((e, i) =>
						i === selectedIndex
							? { question: data.quiz.question, questionNum: parseInt(data.quiz.questionNum) }
							: e
					)
					.sort((a, b) => a.questionNum - b.questionNum)
			);
			setisSelected(false);
			setValue("quiz.question", "");
			setValue("quiz.questionNum", "");
		} else {
			setquestions(
				[...questions, { question: data.quiz.question, questionNum: parseInt(data.quiz.questionNum) }].sort(
					(a, b) => a.questionNum - b.questionNum
				)
			);
			setValue("quiz.question", "");
			setValue("quiz.questionNum", "");
		}
	};
	const onError = (errors, e) => e.preventDefault();

	const handleSelect = (index) => {
		if (index === selectedIndex) {
			setisSelected(!isSelected);
		} else {
			setisSelected(true);
			setselectedIndex(index);
		}
	};
	const viewSelect = (index) => {
		return isSelected && selectedIndex === index;
	};

	useEffect(() => {
		if (isSelected && questions[selectedIndex]) {
			setValue("quiz.question", questions[selectedIndex].question);
			setValue("quiz.questionNum", questions[selectedIndex].questionNum);
		} else {
			setValue("quiz.question", "");
			setValue("quiz.questionNum", "");
		}
	}, [isSelected, selectedIndex, questions, setValue, reset]);
	useEffect(() => {
		async function getData() {
			if (params && params.id) {
				let res = await getQuiz(params.id);
				if (res) {
					setquizID(res._id);
					res = Array.isArray(res.quizzes) ? res.quizzes : [];

					setquestions(res);
				}
			}
		}
		getData();
	}, [params]);
	return (
		<>
			{loading ? (
				<LoaderDefault />
			) : (
				<Box sx={{ padding: "20px", bgcolor: "#1d2b3a", height: "100vh" }}>
					<center>
						<Box
							sx={{
								maxWidth: "968px",
								display: "flex",
								minWidth: "300px",
							}}
						>
							<Box
								bgcolor="#348ceb"
								width="100px"
								sx={{
									height: "35px",
									justifyContent: "center",
									display: "flex",
									borderRadius: "5px 5px 0px 0px",
									color: "white",
								}}
							>
								<h4 style={{ margin: "5px" }}>Quiz</h4>
							</Box>
						</Box>
						<Box
							sx={{
								maxWidth: "968px",
								display: "flex",
								bgcolor: "white",
								borderRadius: "0px 5px 5px 5px ",
							}}
						>
							<Box
								sx={{
									padding: "1em",
									width: "100%",
									overflowX: "hidden",
								}}
							>
								<List sx={{ overflowX: "clip" }}>
									{Array.isArray(questions) &&
										questions.map((q, index) => (
											<QuestionsView
												key={index}
												index={index}
												QuestionTitle={"Question " + q.questionNum}
												Question={q.question}
												onSelect={handleSelect}
												selcted={viewSelect}
											></QuestionsView>
										))}
								</List>
							</Box>
							<Box
								sx={{
									borderLeft: "3px solid #348ceb",
								}}
							>
								<center>
									<form onSubmit={handleSubmit(onSubmit, onError)}>
										<TextField
											sx={{ margin: "1em", width: "25vw" }}
											variant="filled"
											type="number"
											label="Question Number"
											required
											InputLabelProps={{ shrink: true }}
											{...register("quiz.questionNum")}
										></TextField>
										<br />
										<TextField
											sx={{ margin: "1em", width: "25vw" }}
											variant="filled"
											type="text"
											label="Question"
											required
											InputLabelProps={{ shrink: true }}
											{...register("quiz.question")}
										></TextField>
										<br />

										<Button sx={{ margin: "1em" }} type="submit" variant="contained">
											{isSelected ? "Edit" : "Add"}
										</Button>
										{isSelected && (
											<Button
												sx={{ margin: "1em" }}
												onClick={deleteQuestion}
												color="error"
												variant="contained"
											>
												{"Delete"}
											</Button>
										)}
									</form>

									<br />

									<Button sx={{ margin: "1em" }} variant="contained" onClick={uploadQuiz}>
										Upload Quiz
									</Button>
									<Button
										disabled={!quizID}
										sx={{ margin: "1em" }}
										variant="contained"
										color="error"
										onClick={removeQuiz}
									>
										Delete Quiz
									</Button>
								</center>
							</Box>
						</Box>
					</center>
				</Box>
			)}
		</>
	);
}

export default QuizeEditor;

/*
import { Button, List, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import QuestionsView from "./QuestionsView";
import { useForm } from "react-hook-form";

function QuizeEditor() {
	const { register, handleSubmit, reset, setValue } = useForm();
	const [questions, setquestions] = useState([]);
	const [selectedIndex, setselectedIndex] = useState(-1);
	const [isSelected, setisSelected] = useState(false);

	const uploadQuiz = () => {
		console.log(questions);
	};

	const onSubmit = (data, e) => {
		if (isSelected) {
			setquestions(
				questions
					.map((e, i) =>
						i === selectedIndex
							? {
									question: data.quiz.question,
									questionNum: parseInt(
										data.quiz.questionNum
									),
							  }
							: e
					)
					.sort((a, b) => a.questionNum - b.questionNum)
			);
			setisSelected(false);
			reset({});
		} else {
			setquestions(
				[
					...questions,
					{
						question: data.quiz.question,
						questionNum: parseInt(data.quiz.questionNum),
					},
				].sort((a, b) => a.questionNum - b.questionNum)
			);
			reset({});
		}
	};
	const onError = (errors, e) => e.preventDefault();

	const handleSelect = (index) => {
		if (index === selectedIndex) {
			setisSelected(!isSelected);
		} else {
			setisSelected(true);
			setselectedIndex(index);
		}
	};
	const viewSelect = (index) => {
		return isSelected && selectedIndex === index;
	};

	useEffect(() => {
		if (isSelected && questions[selectedIndex]) {
			setValue("quiz.question", questions[selectedIndex].question);
			setValue("quiz.questionNum", questions[selectedIndex].questionNum);
		} else reset({});
	}, [isSelected, selectedIndex, questions, setValue, reset]);
	useEffect(() => {
		let res = JSON.parse(`[{
			"question":"name?",
			"questionNum":1
		  },
		  {
			"question":"age?",
			"questionNum":2
		  }]`);

		res = Array.isArray(res) ? res : [];
		setquestions(res);
	}, []);
	return (
		<Box sx={{ padding: "20px", bgcolor: "#1d2b3a", height: "100vh" }}>
			<center>
				<Box
					sx={{
						maxWidth: "968px",
						display: "flex",
					}}
				>
					<Box
						bgcolor="#348ceb"
						width="100px"
						sx={{
							height: "25px",
							justifyContent: "center",
							display: "flex",
							borderRadius: "5px 5px 0px 0px",
							color: "white",
						}}
					>
						<h4 style={{ marginTop: "2px" }}>Quiz</h4>
					</Box>
				</Box>
				<Box
					sx={{
						maxWidth: "968px",
						display: "flex",
						bgcolor: "white",
						borderRadius: "0px 5px 5px 5px ",
					}}
				>
					<Box
						sx={{
							padding: "1em",
							width: "500px",
							overflowX: "scroll",
						}}
					>
						<List sx={{ overflowX: "scroll" }}>
							{Array.isArray(questions) &&
								questions.map((q, index) => (
									<QuestionsView
										key={index}
										index={index}
										QuestionTitle={
											"Question " + q.questionNum
										}
										Question={q.question}
										onSelect={handleSelect}
										selcted={viewSelect}
									></QuestionsView>
								))}
						</List>
					</Box>
					<Box
						sx={{
							borderLeft: "3px solid #348ceb",
						}}
					>
						<center>
							<form onSubmit={handleSubmit(onSubmit, onError)}>
								<TextField
									sx={{ margin: "1em", width: "25vw" }}
									variant="filled"
									type="number"
									label="Question Number"
									required
									{...register("quiz.questionNum")}
								></TextField>
								<br />
								<TextField
									sx={{ margin: "1em", width: "25vw" }}
									variant="filled"
									type="text"
									label="Question"
									required
									{...register("quiz.question")}
								></TextField>
								<br />

								<Button
									sx={{ margin: "1em" }}
									type="submit"
									variant="contained"
								>
									{isSelected ? "Edit" : "Add"}
								</Button>
							</form>

							<br />

							<Button
								sx={{ margin: "1em" }}
								variant="contained"
								onClick={uploadQuiz}
							>
								Upload Quiz
							</Button>
						</center>
					</Box>
				</Box>
			</center>
		</Box>
	);
}

export default QuizeEditor;




*/
