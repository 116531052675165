import React, { useState, useEffect } from "react";

import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import AddIcon from "@mui/icons-material/Add";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

import { getActiveCourses, getDraftCourses, getPendingCourses } from "../util/api/instructor";
import { deleteCourse, updateToDraft, updateToPending } from "../util/api/courses";
import LoaderDefault from "../components/loaders/LoaderDefault";
import CreateCourseModal from "../components/modals/CreateCourseModal";
import { Link } from "react-router-dom";
import useAuth from "../util/state/authState";
import { getAnswerScripts, passTheStudent, repeatTheStudent } from "../util/api/quiz";
import ShowAnswerScriptModal from "../components/modals/ShowAnswerScriptModal";

//TODO: add warnings before do criticle actions (like okay-cancel model)

// const answerScriptsRows = [
// 	{
// 		student_name: "Janith Samee",
// 		photo_url: "https://i.pravatar.cc/",
// 		answers_script: "#",
// 		course: "Pyton Fundamentals",
// 	},
// ];

export default function InstructorDashboard() {
	const [activeCourses, setActiveCourses] = useState([]);
	const [pendingCourses, setPendingCourses] = useState([]);
	const [answerScripts, setanswerScripts] = useState([]);
	const [draftCourse, setDraftCourse] = useState([]);
	const [loading, setloading] = useState(false);
	const [contentUpdated, setcontentUpdated] = useState(false);
	//const [answertableupdated, setanswertableupdated] = useState(false);
	const [instructorID, setinstructorID] = useState("");
	const [answerScriptmodalopen, setanswerScriptmodalopen] = useState(false);

	const { user } = useAuth();

	//========= Modals

	const handleOpenAnswerScriptModal = () => {
		setanswerScriptmodalopen(true);
	};
	const handleCloseAnswerScriptModal = () => {
		setanswerScriptmodalopen(false);
	};

	//		============Creator
	const [openCreator, setopenCreator] = useState(false);
	const creatorClosehandler = () => {
		setopenCreator(false);
	};
	const creatorOpenhandler = () => {
		setopenCreator(true);
	};

	//TODO: Implemet this

	//TODO:for not appreoved instructor desable "request buttons"
	useEffect(() => {
		async function getData() {
			setcontentUpdated(false);
			setloading(true);
			const resActive = await getActiveCourses(instructorID);
			const resDraft = await getDraftCourses(instructorID);
			const resPending = await getPendingCourses(instructorID);
			const res = await getAnswerScripts(user._id);
			if (res && Array.isArray(res)) {
				setanswerScripts(res);
			}

			setDraftCourse(resDraft);
			setPendingCourses(resPending);
			setActiveCourses(resActive);
			setloading(false);
		}
		setinstructorID(user._id);
		if (instructorID) getData();
	}, [contentUpdated, user, instructorID]);

	// useEffect(() => {
	// 	async function getData() {
	// 		//setanswertableupdated(false);

	// 		setloading(true);
	// 		const res = await getAnswerScripts(user._id);
	// 		if (res && Array.isArray(res)) {
	// 			setanswerScripts(res);
	// 		}
	// 		setloading(false);
	// 	}
	// 	getData();
	// }, [user]);

	return loading ? (
		<LoaderDefault></LoaderDefault>
	) : (
		<Box>
			<CreateCourseModal
				open={instructorID ? openCreator : false}
				handler={creatorClosehandler}
				loading={loading}
				setloading={setloading}
				updater={setcontentUpdated}
				instructorID={instructorID}
			/>
			<Box mb={3}>
				<Box sx={{ textAlign: "right" }} m={5}>
					<Button variant="contained" startIcon={<AddIcon />} onClick={creatorOpenhandler}>
						Add Course
					</Button>
				</Box>
				<Box>
					<center>
						<Box mt={1}>
							<Typography fontWeight="bold">Active Courses</Typography>
							<TableContainer component={Paper} sx={{ maxWidth: "800px", marginTop: "10px" }}>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>Title</TableCell>
											<TableCell>Students</TableCell>

											<TableCell>Delete</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{activeCourses &&
											activeCourses.map((row) => (
												<TableRow key={row._id}>
													<TableCell>
														<a
															href={"/courses/" + row.searchId}
															style={{
																color: "black",
															}}
														>
															{row.title}
														</a>
													</TableCell>
													<TableCell>{row.studentCount}</TableCell>

													<TableCell>
														<Button
															onClick={async () => {
																const cnf = window.prompt(
																	"Are you sure you want to delete?\nType " +
																		row._id.slice(0, 6) +
																		" to remove."
																);
																if (cnf === row._id.slice(0, 6)) {
																	setloading(true);
																	await deleteCourse(row._id);
																	setcontentUpdated(true);
																}
															}}
														>
															<DeleteIcon></DeleteIcon>
														</Button>
													</TableCell>
												</TableRow>
											))}
									</TableBody>
								</Table>
							</TableContainer>
						</Box>
						<Box mt={5}>
							<Typography fontWeight="bold">Pending Approval</Typography>
							<TableContainer component={Paper} sx={{ maxWidth: "800px", marginTop: "10px" }}>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>Title</TableCell>
											<TableCell>Students</TableCell>

											<TableCell>Delete</TableCell>
											<TableCell>Move to Draft</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{pendingCourses &&
											pendingCourses.map((row) => (
												<TableRow key={row._id}>
													<TableCell>
														<a
															href={"/courses/" + row.searchId}
															style={{
																color: "black",
															}}
														>
															{row.title}
														</a>
													</TableCell>
													<TableCell>{row.studentCount}</TableCell>

													<TableCell>
														<Button
															onClick={async () => {
																//fixed bug: 0016
																if (
																	window.confirm(
																		"Are you sure you want to Delete?"
																	) === true
																) {
																	setloading(true);
																	await deleteCourse(row._id);
																	setcontentUpdated(true);
																}
															}}
														>
															<DeleteIcon></DeleteIcon>
														</Button>
													</TableCell>
													<TableCell>
														<Button
															onClick={async () => {
																setloading(true);
																await updateToDraft(row._id);
																setcontentUpdated(true);
															}}
														>
															<ArrowRightAltOutlinedIcon></ArrowRightAltOutlinedIcon>
														</Button>
													</TableCell>
												</TableRow>
											))}
									</TableBody>
								</Table>
							</TableContainer>
						</Box>
						<Box mt={5}>
							<Typography fontWeight="bold">Draft</Typography>
							<TableContainer component={Paper} sx={{ maxWidth: "800px", marginTop: "10px" }}>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>Title</TableCell>

											<TableCell>Edit</TableCell>
											<TableCell>Delete</TableCell>
											<TableCell>Request Approval</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{draftCourse &&
											draftCourse.map((row) => (
												<TableRow key={row._id}>
													<TableCell>
														<a
															href={"/courses/" + row.searchId}
															style={{
																color: "black",
															}}
														>
															{row.title}
														</a>
													</TableCell>

													<TableCell>
														<Link to={"/Courses/editor/" + row._id}>
															<EditIcon />
														</Link>
													</TableCell>
													<TableCell>
														<Button
															onClick={async () => {
																if (
																	window.confirm(
																		"Are you sure you want to delete?"
																	) === true
																) {
																	setloading(true);
																	await deleteCourse(row._id);
																	setcontentUpdated(true);
																}
															}}
														>
															<DeleteIcon></DeleteIcon>
														</Button>
													</TableCell>
													<TableCell>
														<Button
															variant="contained"
															color="warning"
															onClick={async () => {
																setloading(true);
																await updateToPending(row._id);
																setcontentUpdated(true);
															}}
														>
															Request
														</Button>
													</TableCell>
												</TableRow>
											))}
									</TableBody>
								</Table>
							</TableContainer>
						</Box>
						<Box mt={5}>
							<Typography fontWeight="bold">Answer Scripts</Typography>
							<TableContainer component={Paper} sx={{ maxWidth: "800px", marginTop: "10px" }}>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>Student</TableCell>
											<TableCell>Course</TableCell>
											<TableCell>Answer Script</TableCell>
											<TableCell>Pass/Fail</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{answerScripts.map((row) => (
											<TableRow>
												<TableCell>{row.studentId && row.studentId.name}</TableCell>
												<TableCell>{row.courseId && row.courseId.title}</TableCell>
												<TableCell>
													<ShowAnswerScriptModal
														open={answerScriptmodalopen}
														handler={handleCloseAnswerScriptModal}
														answers={row.finalQuizAnswers}
													/>
													<Button onClick={handleOpenAnswerScriptModal}>Show</Button>
												</TableCell>
												<TableCell>
													<Button
														onClick={async () => {
															const cnf = window.prompt(
																"Are you sure you want to pass?\nType " +
																	"'pass'" +
																	" to Pass the student."
															);
															if (cnf === "pass") {
																setloading(true);
																await passTheStudent(row._id);
																setloading(false);
																setcontentUpdated(true);
															}
														}}
														variant="contained"
														color="success"
														size="small"
													>
														Pass
													</Button>

													<Button
														onClick={async () => {
															const cnf = window.prompt(
																"Are you sure you want to delete?\nType " +
																	"'repeat'" +
																	" to remove."
															);
															if (cnf === "repeat") {
																setloading(true);
																await repeatTheStudent(row._id);
																setloading(false);
																setcontentUpdated(true);
															}
														}}
														variant="contained"
														color="error"
														size="small"
														style={{
															marginLeft: "10px",
														}}
													>
														Fail
													</Button>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Box>
					</center>
				</Box>
			</Box>
		</Box>
	);
}
