import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button, Box } from "@mui/material";
import React from "react";

export default function CourseEvaluation({ isFinished }) {
	return (
		<div>
			<center>
				<Box
					sx={{
						width: "100%",
						display: "flex",
						// bgcolor: "yellow",
						padding: "10px",
						marginBottom: "1em",
						borderRadius: "10px",
						boxShadow: "-1px 0px 11px 0px #888888",
						flexWrap: "wrap",
					}}
				>
					<Box
						sx={{
							width: "50px",
							height: "50px",
							color: "#1e85e6",
							alignContent: "center",
							justifyContent: "center",
						}}
					>
						<Box sx={{ bgcolor: "white", zIndex: "5", borderRadius: "50%" }}>
							{isFinished ? (
								<CheckCircleIcon
									sx={{
										height: "40px",
										width: "40px",
										marginTop: "-5px",
									}}
								/>
							) : (
								<RadioButtonUncheckedIcon
									sx={{
										height: "40px",
										width: "40px",
										marginTop: "-5px",
									}}
								/>
							)}
						</Box>
					</Box>
					<Box
						sx={{
							width: "240px",
							height: "50px",
							// ml: "5px",
							display: "flex",
							alignContent: "center",
							justifyContent: "center",
						}}
					>
						<Button
							variant="contained"
							size="small"
							sx={{
								width: "230px",
								overflow: "clip",
							}}
						>
							{"Evaluation"}
						</Button>
					</Box>
				</Box>
			</center>
		</div>
	);
}
