import React from "react";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import PinterestIcon from "@mui/icons-material/Pinterest";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Footer = () => {
	return (
		<>
			<footer>
				<div className="cwUs">
					<div className="container">
						<div className="row">
							<div className="col-md-2"></div>
							<div className="col-md-8">
								<div className="socialBtns" style={{ color: "#fff" }}>
									<a href="https://www.facebook.com/seekahost">
										<FacebookIcon />
									</a>
									<a href="https://twitter.com/atSeekaHost">
										<TwitterIcon />
									</a>
									<p>Connect With Us</p>
									<a href="https://www.pinterest.com/seekahost/">
										<PinterestIcon />
									</a>
									<a href="https://www.linkedin.com/company/seekahost">
										<LinkedInIcon />
									</a>
								</div>
							</div>
							<div className="col-md-2"></div>
						</div>
					</div>
				</div>
				<div className="footer-sec">
					<div className="container ">
						<div className="row footer-sec-1">
							<div className="col-md-3">
								<p className="footer-sec-head">COURSES</p>
								<ul className="footer-sec-links">
									<li>
										<a href="https://university.seekahost.com/courses/seo-training-course/">
											SEO Training Course
										</a>{" "}
									</li>
									<li>
										<a href="https://university.seekahost.com/courses/local-seo-blueprint/">
											Local SEO Blueprint
										</a>{" "}
									</li>
									<li>
										<a href="https://university.seekahost.com/courses/seo-blueprint/">
											The SEO Blueprint
										</a>{" "}
									</li>
									<li>
										<a href="https://university.seekahost.com/courses/facebook-remarketing/">
											Facebook Remarketing
										</a>{" "}
									</li>
									<li>
										<a href="https://university.seekahost.com/courses/blogging-course/">
											Ultimate Blogging Course
										</a>{" "}
									</li>
									<li>
										<a href="https://university.seekahost.com/courses/seo-content-writing-course/">
											SEO Content Writing
										</a>{" "}
									</li>
									<li>
										<a href="https://university.seekahost.com/courses/blog-profits-blueprint/">
											Blog Profits Blueprint
										</a>{" "}
									</li>
									<li>
										<a href="https://university.seekahost.com/courses/google-adwords-course/">
											Google AdWords Course
										</a>{" "}
									</li>
								</ul>
							</div>
							<div className="col-md-3">
								<p className="footer-sec-head">SUPPORT</p>
								<ul className="footer-sec-links">
									<li>
										<a href="https://university.seekahost.com/author/manuelawillbold/">
											Submit a Request
										</a>{" "}
									</li>
								</ul>
							</div>
							<div className="col-md-3">
								<p className="footer-sec-head">UNIVERSITY</p>
								<ul className="footer-sec-links">
									<li>
										<a href="https://university.seekahost.com/courses/">Courses</a>{" "}
									</li>
									<li>
										<a href="https://university.seekahost.com/about-us">About Us</a>{" "}
									</li>
									<li>
										<a href="https://university.seekahost.com/guides/">Guides</a>{" "}
									</li>
									<li>
										<a href="https://university.seekahost.com/contact-us">Contact</a>{" "}
									</li>
									<li>
										<Link to="/signin">Sign In as Instructor</Link>{" "}
									</li>
								</ul>
							</div>
							<div className="col-md-3">
								<p className="footer-sec-head">UNIVERSITY</p>
								<ul className="footer-sec-links">
									<li>
										<a href="https://university.seekahost.com/privacy-policy/">Privacy Policy</a>{" "}
									</li>
									<li>
										<a href="https://university.seekahost.com/terms-and-conditions/">
											Terms and Conditions
										</a>{" "}
									</li>
									<li>
										<a href="https://university.seekahost.com/cancellation-and-refund-policy/">
											Cancellation and Refund Policy
										</a>{" "}
									</li>
									<li>
										<a href="https://university.seekahost.com/cookie-policy/">Cookie Policy</a>{" "}
									</li>
								</ul>
							</div>
							{/* <div className="col-md-3">
								<img
									src={footerLogo}
									alt="seekahost"
									width="168"
									className="img-fluid"
								/>
								<p className="footer-sec-head logo-copyright-text">
									© 2021 SeekaHost™ Ltd <br />
									Web Hosting Company
								</p>
							</div> */}
						</div>
						<div className="row copyrigth">
							<div className="col-md-6 mb-4">
								<p className="copyright-text">Copyright © 2021 SeekaHost Ltd. All Right Reserved.</p>
								<div className="">
									<p>By using this site, You agree to the Universal Terms of Service.</p>
									<p>
										<span className="right-border">
											<a
												href="https://www.seekahost.app/terms-and-conditions/"
												style={{ color: "#fff" }}
											>
												Terms and Policies
											</a>
										</span>
										<span className="right-border">
											<a
												href="https://www.seekahost.app/privacy-policy/"
												style={{ color: "#fff" }}
											>
												Privacy Policies
											</a>
										</span>
										<span className="right-border">
											<a
												href="https://university.seekahost.com/cookie-policy/"
												style={{ color: "#fff" }}
											>
												Cookie Policy
											</a>
										</span>
										<span className="right-border">
											<a
												href="https://www.seekahost.app/refund-policy/"
												style={{ color: "#fff" }}
											>
												Refund Policy
											</a>
										</span>
									</p>
								</div>
							</div>
							{/* <div className="col-md-6 text-center">
								<p>Pay with confidence & security</p>
								<a href="/" className="payment-icons m-1">
									<i className="fab fa-cc-visa"></i>
								</a>
								<a href="/" className="payment-icons m-1">
									<i className="fab fa-cc-visa"></i>
								</a>
								<a href="/" className="payment-icons m-1">
									<i className="fab fa-cc-mastercard"></i>
								</a>
								<a href="/" className="payment-icons m-1">
									<i className="fab fa-cc-mastercard"></i>
								</a>
								<a href="/" className="payment-icons m-1">
									<i className="fab fa-cc-jcb"></i>
								</a>
								<a href="/" className="payment-icons m-1">
									<i className="fab fa-cc-paypal"></i>
								</a>
							</div> */}
						</div>
					</div>
				</div>
			</footer>
		</>
	);
};

export default React.memo(Footer);
