import { Button, Modal, TextField, Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { updateSection } from "../../util/api/section";

function EdditSectionModal({
	open,
	handler,
	value,
	sections,
	sectionViewUpdater,
}) {
	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 400,
		bgcolor: "background.paper",
		border: "2px solid #000",
		boxShadow: 24,
		p: 4,
		textAlign: "center",
		// margin: 4,
	};

	const { setValue, getValues, register } = useForm();
	if (sections !== []) {
		setValue("title", sections[value] && sections[value].title);
		setValue("index", sections[value] && sections[value].sectionIndex);
	}
	const [loading, setloading] = useState(false);

	const submit = async () => {
		setloading(true);
		await updateSection(sections[value]._id, {
			title: getValues("title"),
			sectionIndex: getValues("index"),
		});
		setloading(false);
		sectionViewUpdater(true);
		handler();
	};

	return (
		<Box>
			<Modal
				open={open}
				onClose={handler}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Typography variant="h5" component="h5">
						Edit Section
					</Typography>
					<TextField
						sx={{ margin: 3 }}
						id="outlined-basic"
						label="Section Name"
						variant="outlined"
						required
						{...register("title")}
					/>
					<TextField
						sx={{ margin: 3, width: 100 }}
						id="outlined-basic"
						label="section index"
						variant="standard"
						type="number"
						required
						{...register("index")}
					/>
					<br />
					<Box>
						<Button
							disabled={loading}
							type="submit"
							sx={{ margin: 3 }}
							variant="contained"
							onClick={submit}
						>
							Submit
						</Button>

						<Button
							type="submit"
							sx={{ margin: 3 }}
							variant="contained"
							color="warning"
							onClick={handler}
						>
							Cancel
						</Button>
					</Box>
				</Box>
			</Modal>
		</Box>
	);
}

export default EdditSectionModal;
