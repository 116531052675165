import React, { useState } from "react";
import { Modal, Typography, Box, Divider, TextField, Button } from "@mui/material";
import {} from "@emotion/styled";
import { useForm } from "react-hook-form";
import { addCourse } from "../../util/api/courses";
import LoaderDefault from "../loaders/LoaderDefault";
import CloseIcon from "@mui/icons-material/Close";
import RichTextEditor from "../TextFormater/RichTextEditor";
import { useSnackbar } from "notistack";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	bgcolor: "background.paper",
	textAlign: "center",
	pr: "5%",
	pl: "5%",
	pt: "5%",
	height: "100%",
	width: "100%",
	overflow: "scroll",
};

function CreateCourseModal({ open, handler, updater, instructorID }) {
	const { enqueueSnackbar } = useSnackbar();
	//=============Form
	//TODO: form validations
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const onSubmit = async (data, e) => {
		setloading(true);
		// //TODO: remove instructor instructor
		// console.log({ ...data, description, instructor: instructorID });
		const res = await addCourse({ ...data, description, instructor: instructorID });
		setloading(false);
		if (res.error) {
			enqueueSnackbar(res.error, {
				variant: "error",
				autoHideDuration: 6000,
			});
		}
		updater(true);
		handler();

		//instructor, category, description, title, introVideo, imageURL
	};
	const onError = (errors, e) => console.log(errors, e);

	//loading
	const [loading, setloading] = useState(false);
	const [description, setdescription] = useState("");

	return (
		<Modal open={open} onClose={handler}>
			{loading ? (
				<Box>
					<LoaderDefault></LoaderDefault>
				</Box>
			) : (
				<Box sx={style}>
					<Typography id="modal-modal-title" variant="h4" component="h2" mb={2}>
						Create Course
					</Typography>
					<Button
						sx={{
							position: "fixed",
							top: "10px",
							right: "20px",
							width: "50px",
							height: "50px",
						}}
						color="warning"
						width="50px"
						onClick={handler}
					>
						<CloseIcon sx={{ width: "30px", height: "30px" }} />
					</Button>
					<Divider />
					<form onSubmit={handleSubmit(onSubmit, onError)}>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
							}}
						>
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
								}}
							>
								<TextField
									label="Course Title"
									sx={{ margin: "2%" }}
									variant="outlined"
									placeholder="Course Title"
									error={errors.title && true}
									helperText={errors.title && "Input a title"}
									{...register("title", {
										required: true,
									})}
								></TextField>
								<TextField
									label="Category"
									sx={{ margin: "2%" }}
									error={errors.category && true}
									helperText={errors.category && "Input a category"}
									{...register("category", {
										required: true,
									})}
								></TextField>
							</Box>
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
								}}
							>
								<TextField
									label="Introduction Video url"
									sx={{ margin: "2%" }}
									{...register("introVideo")}
								></TextField>
							</Box>
							<Typography variant="h6" component="h2">
								Course Description
							</Typography>
							<RichTextEditor setouput={setdescription} />

							<Box>
								<Button variant="contained" sx={{ marginTop: 1, width: "30%" }} type="submit">
									Submit
								</Button>
							</Box>
						</Box>
					</form>
				</Box>
			)}
		</Modal>
	);
}

export default CreateCourseModal;
