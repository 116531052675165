import { signIn as StudentSignIn, updatePassword as studentUpdatepassword } from "./student";
import {
	signIn as InstructorSignIn,
	updatePassword as instructorUpdatePassword,
	signOut as instructorSignOut,
} from "./instructor";
import { signIn as adminSignIn, updatePasssword as adminUpdatePassword, signOut as adminSignout } from "./admin";
const handleSignIn = (username, password, type) => {
	let out = undefined;
	switch (type) {
		case "student":
			out = StudentSignIn({ username, password });
			break;
		case "instructor":
			out = InstructorSignIn({ username, password });
			break;

		default:
			out = undefined;
			break;
	}
	return out;
};
const handleSignOut = (id, type) => {
	//TODO: signout from backend Important
	let out = undefined;
	switch (type) {
		case "student":
			out = { message: "success" };
			break;
		case "instructor":
			out = instructorSignOut();
			out = { message: "success" };
			break;
		default:
			out = undefined;
			break;
	}
	return out;
};
const handleUpdatePassword = async (id, body, type) => {
	let out = undefined;
	switch (type) {
		case "student":
			out = await studentUpdatepassword(id, body);
			break;
		case "instructor":
			out = await instructorUpdatePassword(id, body);
			break;
		default:
			out = undefined;
			break;
	}
	return out;
};

const handleAdminSignIn = async (body) => {
	const res = await adminSignIn(body);
	return res;
};

const handleAdminSignout = async (id, body) => {
	const res = await adminSignout();
	return res;
};
const handleAdminUpdatepassword = async (id, body) => {
	const res = await adminUpdatePassword(id, body);
	return res;
};

export {
	handleSignIn,
	handleSignOut,
	handleUpdatePassword,
	handleAdminSignIn,
	handleAdminSignout,
	handleAdminUpdatepassword,
};
