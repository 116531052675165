// import React from "react";
// import { useNavigate, Link } from "react-router-dom";
// import useAuth from "../../util/state/authState";
// import { Avatar } from "@mui/material";
// import AdminTaskBar from "../Admin/AdminTaskBar";
// import { encode } from "../../util/base64Encorder";

// const Navbar = () => {
// 	const { user, signOut, DP } = useAuth();
// 	const navigate = useNavigate();
// 	return (
// 		<div>
// 			<div style={{ width: "100%" }}>
// 				<AdminTaskBar />
// 			</div>
// 			<div>
// 				<div className="navik-header header-shadow navik-mega-menu mega-menu-fullwidth">
// 					<div className="container">
// 						<div className="navik-header-container">
// 							<div className="logo">
// 								<img
// 									className="img-fluid"
// 									src="https://www.seekahost.app/wp-content/themes/clickdo-main-theme/images/logo.png"
// 									alt="site logo"
// 								/>
// 							</div>

// 							<div className="burger-menu">
// 								<div className="line-menu line-half first-line"></div>
// 								<div className="line-menu"></div>
// 								<div className="line-menu line-half last-line"></div>
// 							</div>

// 							<nav className="navik-menu menu-caret submenu-top-border">
// 								<ul>
// 									<li>
// 										<a href="https://seekahost.app/">
// 											Home
// 										</a>
// 									</li>
// 									<li className="mega-menu dropdown_menu">
// 										<a href="https://www.seekahost.app/pbn-manager/">
// 											PBN Manager
// 										</a>
// 										<ul>
// 											<li>
// 												<div className="mega-menu-container">
// 													<div className="mega-menu-fullwidth-container container">
// 														<div className="row">
// 															<div className="col-lg-3">
// 																<div className="mega-menu-box">
// 																	<div className="mega-menu-media">
// 																		<div className="mega-menu-media-img">
// 																			<div className="mega-menu-thumbnail">
// 																				<a href="https://www.seekahost.app/how-to-use-wordpress-blog-hosting-control-panel/">
// 																					<img
// 																						src="https://www.seekahost.app/wp-content/uploads/2021/09/Getting-Started.png"
// 																						alt="Getting-Started"
// 																					/>
// 																				</a>
// 																			</div>
// 																		</div>
// 																		<div className="mega-menu-media-info">
// 																			<h4 className="mega-menu-heading">
// 																				<a href="https://www.seekahost.app/how-to-use-wordpress-blog-hosting-control-panel/">
// 																					Getting
// 																					Started
// 																				</a>
// 																			</h4>
// 																		</div>
// 																	</div>
// 																</div>
// 															</div>
// 															<div className="col-lg-3">
// 																<div className="mega-menu-box">
// 																	<div className="mega-menu-media">
// 																		<div className="mega-menu-media-img">
// 																			<div className="mega-menu-thumbnail">
// 																				<a href="https://www.seekahost.app/multiple-ip-hosting/">
// 																					<img
// 																						src="https://www.seekahost.app/wp-content/uploads/2021/09/Multiple-IP-Hosting.png"
// 																						alt="Multiple-IP-Hosting"
// 																					/>
// 																				</a>
// 																			</div>
// 																		</div>
// 																		<div className="mega-menu-media-info">
// 																			<h4 className="mega-menu-heading">
// 																				<a href="https://www.seekahost.app/multiple-ip-hosting/">
// 																					Multiple
// 																					IP
// 																					Hosting
// 																				</a>
// 																			</h4>
// 																			<div className="mega-menu-desc"></div>
// 																		</div>
// 																	</div>
// 																</div>
// 															</div>
// 															<div className="col-lg-3">
// 																<div className="mega-menu-box">
// 																	<div className="mega-menu-media">
// 																		<div className="mega-menu-media-img">
// 																			<div className="mega-menu-thumbnail">
// 																				<a href="https://www.seekahost.app/domain-name-search/">
// 																					<img
// 																						src="https://www.seekahost.app/wp-content/uploads/2021/09/Domain-Name-Search.png"
// 																						alt="Domain-Name-Search"
// 																					/>
// 																				</a>
// 																			</div>
// 																		</div>
// 																		<div className="mega-menu-media-info">
// 																			<h4 className="mega-menu-heading">
// 																				<a href="https://www.seekahost.app/domain-name-search/">
// 																					Domain
// 																					Search
// 																				</a>
// 																			</h4>
// 																			<div className="mega-menu-desc"></div>
// 																		</div>
// 																	</div>
// 																</div>
// 															</div>
// 															<div className="col-lg-3">
// 																<div className="mega-menu-box">
// 																	<div className="mega-menu-media">
// 																		<div className="mega-menu-media-img">
// 																			<div className="mega-menu-thumbnail">
// 																				<a href="https://www.seekahost.app/pbn-hosting-faqs/">
// 																					<img
// 																						src="https://www.seekahost.app/wp-content/uploads/2021/08/PBN-Footprints.png"
// 																						alt="FAQ"
// 																					/>
// 																				</a>
// 																			</div>
// 																		</div>
// 																		<div className="mega-menu-media-info">
// 																			<h4 className="mega-menu-heading">
// 																				<a href="https://www.seekahost.app/pbn-hosting-faqs/">
// 																					FAQ's
// 																				</a>
// 																			</h4>
// 																			<div className="mega-menu-desc"></div>
// 																		</div>
// 																	</div>
// 																</div>
// 															</div>
// 														</div>
// 													</div>
// 												</div>
// 											</li>
// 										</ul>
// 									</li>
// 									<li>
// 										<a href="https://www.seekahost.app/buy-domain-name/">
// 											Buy Domains
// 										</a>
// 									</li>
// 									<li>
// 										<a href="https://www.seekahost.app/pbn-hosting-reviews/">
// 											Reviews
// 										</a>
// 									</li>
// 									<li className="mega-menu dropdown_menu">
// 										<a href="https://www.seekahost.app/tutorials/">
// 											Tutorials
// 										</a>
// 										<ul>
// 											<li>
// 												<div className="mega-menu-container">
// 													<div className="mega-menu-fullwidth-container container">
// 														<div className="row sub-menu-flex">
// 															<div className="col-lg-3">
// 																<div className="mega-menu-box">
// 																	<div className="mega-menu-media">
// 																		<div className="mega-menu-media-img">
// 																			<div className="mega-menu-thumbnail">
// 																				<a href="https://www.seekahost.app/what-is-private-blog-network/">
// 																					<img
// 																						src="https://www.seekahost.app/wp-content/uploads/2021/08/Private-Blog-Network.png"
// 																						alt="Private-Blog-Network"
// 																					/>
// 																				</a>
// 																			</div>
// 																		</div>
// 																		<div className="mega-menu-media-info">
// 																			<h4 className="mega-menu-heading">
// 																				<a href="https://www.seekahost.app/what-is-private-blog-network/">
// 																					Blog
// 																					Network
// 																				</a>
// 																			</h4>
// 																			<div className="mega-menu-desc"></div>
// 																		</div>
// 																	</div>
// 																</div>
// 															</div>
// 															<div className="col-lg-3">
// 																<div className="mega-menu-box">
// 																	<div className="mega-menu-media">
// 																		<div className="mega-menu-media-img">
// 																			<div className="mega-menu-thumbnail">
// 																				<a href="https://www.seekahost.app/pbn-sites-examples-list/">
// 																					<img
// 																						src="https://www.seekahost.app/wp-content/uploads/2021/08/PBN-Site-Examples.png"
// 																						alt="PBN-Site-Examples"
// 																					/>
// 																				</a>
// 																			</div>
// 																		</div>
// 																		<div className="mega-menu-media-info">
// 																			<h4 className="mega-menu-heading">
// 																				<a href="https://www.seekahost.app/pbn-sites-examples-list/">
// 																					PBN
// 																					Site
// 																					Examples
// 																				</a>
// 																			</h4>
// 																			<div className="mega-menu-desc"></div>
// 																		</div>
// 																	</div>
// 																</div>
// 															</div>
// 															<div className="col-lg-3">
// 																<div className="mega-menu-box">
// 																	<div className="mega-menu-media">
// 																		<div className="mega-menu-media-img">
// 																			<div className="mega-menu-thumbnail">
// 																				<a href="https://www.seekahost.app/how-to-update-nameservers-in-seekapanel/">
// 																					<img
// 																						src="https://www.seekahost.app/wp-content/uploads/2021/08/Nameserver-Updating.png"
// 																						alt="Nameserver-Updating"
// 																					/>
// 																				</a>
// 																			</div>
// 																		</div>
// 																		<div className="mega-menu-media-info">
// 																			<h4 className="mega-menu-heading">
// 																				<a href="https://www.seekahost.app/how-to-update-nameservers-in-seekapanel/">
// 																					Nameservers
// 																				</a>
// 																			</h4>
// 																			<div className="mega-menu-desc"></div>
// 																		</div>
// 																	</div>
// 																</div>
// 															</div>
// 															<div className="col-lg-3">
// 																<div className="mega-menu-box">
// 																	<div className="mega-menu-media">
// 																		<div className="mega-menu-media-img">
// 																			<div className="mega-menu-thumbnail">
// 																				<a href="https://www.seekahost.app/pbn-footprints/">
// 																					<img
// 																						src="https://www.seekahost.app/wp-content/uploads/2021/08/PBN-Footprints.png"
// 																						alt="PBN-Footprints"
// 																					/>
// 																				</a>
// 																			</div>
// 																		</div>
// 																		<div className="mega-menu-media-info">
// 																			<h4 className="mega-menu-heading">
// 																				<a href="https://www.seekahost.app/pbn-footprints/">
// 																					PBN
// 																					Footprints
// 																				</a>
// 																			</h4>
// 																			<div className="mega-menu-desc"></div>
// 																		</div>
// 																	</div>
// 																</div>
// 															</div>
// 														</div>
// 													</div>
// 												</div>
// 											</li>
// 										</ul>
// 									</li>
// 									{user ? (
// 										<UserComponent
// 											DP={DP}
// 											type={user.type}
// 											signOut={signOut}
// 											navigate={navigate}
// 											user={user}
// 										/>
// 									) : (
// 										<li className="nav-item">
// 											<Link
// 												className="nav-link"
// 												to="/signin"
// 											>
// 												Sign In
// 											</Link>
// 										</li>
// 									)}
// 								</ul>
// 							</nav>
// 						</div>
// 					</div>
// 				</div>
// 			</div>
// 		</div>
// 	);
// };

// const UserComponent = ({ type, signOut, navigate, user, DP }) => {
// 	if (type === "student") {
// 		return (
// 			<SignComponent
// 				dp={DP}
// 				user={user}
// 				path={"/student/profile"}
// 				type="student"
// 				navigate={navigate}
// 				signOut={signOut}
// 			/>
// 		);
// 	} else if (type === "instructor") {
// 		return (
// 			<SignComponent
// 				dp={DP}
// 				user={user}
// 				path={"/instructor/profile"}
// 				type="instructor"
// 				navigate={navigate}
// 				signOut={signOut}
// 			/>
// 		);
// 	} else {
// 		return <></>;
// 	}
// };

// const SignComponent = ({ user, path, navigate, signOut, type, dp }) => {
// 	return (
// 		<li className="nav-item dropdown">
// 			<Avatar
// 				sx={{ marginLeft: "0.5em" }}
// 				src={
// 					dp && dp.type === "Buffer"
// 						? "data:image/jpeg;base64," + encode(dp.data)
// 						: "#"
// 				}
// 				alt={(user.name || " ") + "-Profile"}
// 				about={user.name || " "}
// 				id="navbarProfileDropdown"
// 				role="button"
// 				data-toggle="dropdown"
// 				aria-haspopup="true"
// 				aria-expanded="false"
// 			/>
// 			<div
// 				className="dropdown-menu"
// 				aria-labelledby="navbarProfileDropdown"
// 			>
// 				<Link className="dropdown-item" to={path || "/"}>
// 					Profile
// 				</Link>
// 				{type === "instructor" ? (
// 					<Link
// 						className="dropdown-item"
// 						to={"/instructor/dashboard"}
// 					>
// 						Dashboard
// 					</Link>
// 				) : (
// 					<></>
// 				)}
// 				<a className="dropdown-item" href="/">
// 					My Courses
// 				</a>
// 				<div className="dropdown-divider"></div>
// 				<button
// 					className="dropdown-item"
// 					onClick={() => {
// 						signOut();
// 						navigate("/", { replace: true });
// 					}}
// 				>
// 					Sign Out
// 				</button>
// 			</div>
// 		</li>
// 	);
// };

// export default React.memo(Navbar);

import React, { useState } from "react";
import { NavLink, useNavigate, Link } from "react-router-dom";
import useAuth from "../../util/state/authState";
import { Avatar } from "@mui/material";
import AdminTaskBar from "../Admin/AdminTaskBar";
import { encode } from "../../util/base64Encorder";
import Logo from "../../assets/images/logo.png";
import "../../styles/navBar.css";

const Navbar = () => {
	const { user, signOut, DP } = useAuth();
	const [showNav, setshowNav] = useState("")
	const navigate = useNavigate();
	return (
		<div>
			<div style={{ width: "100%" }}>
				<AdminTaskBar />
			</div>
			<div className="container">
				<nav
					className="navbar navbar-expand-lg navbar-light"
					style={{ paddingTop: "15px", paddingBottom: "15px" }}
				>
					<NavLink to="/">
						<img src={Logo} height="65" alt="company logo" />
					</NavLink>
					<button
						className="navbar-toggler"
						type="button"
						onClick={()=>setshowNav(prev=>prev===""?"show":"")}
						// data-toggle="collapse"
						// data-target="#navbarSupportedContent"
						// aria-controls="navbarSupportedContent"
						// aria-expanded="false"
						// aria-label="Toggle navigation"
					>
						<span className="navbar-toggler-icon"></span>
					</button>

					<div className={"collapse navbar-collapse "+showNav} id="navbarSupportedContent">
						<ul className="navbar-nav ml-auto">
							<li className="nav-item active">
								<Link
									className="nav-link"
									to={"/"}
									style={{
										fontSize: "17px",
										fontWeight: "500",
										paddingLeft: "1rem",
										paddingRight: "1rem",
										fontFamily: "Montserrat sans-serif",
										color: "#212121",
									}}
								>
									Home
								</Link>
							</li>

							{/* <li className="nav-item dropdown">
								<a
									className="nav-link dropdown-toggle"
									href="/"
									id="navbarDropdown"
									role="button"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false"
								>
									PBN Manager
								</a>
								<div className="dropdown-menu" aria-labelledby="navbarDropdown">
									<a className="dropdown-item" href="/">
										Action
									</a>
									<a className="dropdown-item" href="/">
										Another action
									</a>
									<div className="dropdown-divider"></div>
									<a className="dropdown-item" href="/">
										Something else here
									</a>
								</div>
							</li> */}
							<li className="nav-item">
								<Link
									className="nav-link"
									to={"/courses"}
									style={{
										fontSize: "17px",
										fontWeight: "500",
										paddingLeft: "1rem",
										paddingRight: "1rem",
										fontFamily: "Montserrat sans-serif",
										color: "#212121",
									}}
								>
									Courses
								</Link>
							</li>
							<li className="nav-item">
								<Link
									className="nav-link"
									to={"/about-us"}
									style={{
										fontSize: "17px",
										fontWeight: "500",
										paddingLeft: "1rem",
										paddingRight: "1rem",
										fontFamily: "Montserrat sans-serif",
										color: "#212121",
									}}
								>
									About Us
								</Link>
							</li>
							<li className="nav-item">
								<a
									className="nav-link"
									href="/guides"
									rel="noreferrer"
									style={{
										fontSize: "17px",
										fontWeight: "500",
										paddingLeft: "1rem",
										paddingRight: "1rem",
										fontFamily: "Montserrat sans-serif",
										color: "#212121",
									}}
								>
									Guides
								</a>
							</li>
							{/* <li className="nav-item">
								<a className="nav-link" href="/">
									Buy Domains
								</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" href="/">
									Reviews
								</a>
							</li>
							<li className="nav-item dropdown">
								<a
									className="nav-link dropdown-toggle"
									href="/"
									id="navbarDropdown"
									role="button"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false"
								>
									Tutorials
								</a>
								<div className="dropdown-menu" aria-labelledby="navbarDropdown">
									<a className="dropdown-item" href="/">
										Action
									</a>
									<a className="dropdown-item" href="/">
										Another action
									</a>
									<div className="dropdown-divider"></div>
									<a className="dropdown-item" href="/">
										Something else here
									</a>
								</div>
							</li> */}
							<li className="nav-item">
								<Link
									className="nav-link"
									to="contact-us"
									style={{
										fontSize: "17px",
										fontWeight: "500",
										paddingLeft: "1rem",
										paddingRight: "1rem",
										fontFamily: "Montserrat sans-serif",
										color: "#212121",
									}}
								>
									Contact Us
								</Link>
							</li>

							{user ? (
								<UserComponent
									DP={DP}
									type={user.type}
									signOut={signOut}
									navigate={navigate}
									user={user}
								/>
							) : (
								// fixed bug:0020

								<>
									<li className="nav-item">
										<a
											className="nav-link"
											href="https://portal.seekahost.app/login"
											target={"_blank"}
											rel="noreferrer"
											style={{
												fontSize: "17px",
												fontWeight: "500",
												paddingLeft: "1rem",
												paddingRight: "1rem",
												fontFamily: "Montserrat sans-serif",
												color: "#212121",
											}}
										>
											Sign In
										</a>
									</li>
									<li>
										<a
											className="nav-link"
											href="https://portal.seekahost.app/register"
											target={"_blank"}
											rel="noreferrer"
											style={{
												fontSize: "17px",
												fontWeight: "500",
												paddingLeft: "1rem",
												paddingRight: "1rem",
												fontFamily: "Montserrat sans-serif",
												color: "#212121",
											}}
										>
											Sign Up
										</a>
									</li>
								</>
							)}
						</ul>
					</div>
				</nav>
			</div>
		</div>
	);
};

const UserComponent = ({ type, signOut, navigate, user, DP }) => {
	if (type === "student") {
		return (
			<SignComponent
				dp={DP}
				user={user}
				path={"/student/profile"}
				type="student"
				navigate={navigate}
				signOut={signOut}
			/>
		);
	} else if (type === "instructor") {
		return (
			<SignComponent
				dp={DP}
				user={user}
				path={"/instructor/profile"}
				type="instructor"
				navigate={navigate}
				signOut={signOut}
			/>
		);
	} else {
		return <></>;
	}
};

const SignComponent = ({ user, path, navigate, signOut, type, dp }) => {
	const [show, setshow] = useState("");
	function handleavatarclick() {
		setshow((prev) => {
			if (prev === "show-dropdown") {
				return "";
			} else {
				return "show-dropdown";
			}
		});
	}
	return (
		<li className="dropdown">
			{/* <Button id="navbarProfileDropdown" className="custom-dropbtn"> */}
			<Avatar
				className="custom-dropbtn"
				role="button"
				onClick={handleavatarclick}
				sx={{ marginLeft: "0.5em" }}
				src={dp && dp.type === "Buffer" ? "data:image/jpeg;base64," + encode(dp.data) : "#"}
				alt={(user.name || " ") + "-Profile"}
				about={user.name || " "}
			/>
			{/* </Button> */}
			<div className={"dropdown-content " + show}>
				<Link className="dropdown-item" to={path || "/"} onClick={handleavatarclick}>
					Profile
				</Link>
				{type === "instructor" ? (
					<Link className="dropdown-item" to={"/instructor/dashboard"} onClick={handleavatarclick}>
						Dashboard
					</Link>
				) : (
					<></>
				)}
				<Link className="dropdown-item" to={"/instructor/dashboard"} onClick={handleavatarclick}>
					My Courses
				</Link>
				<div className="dropdown-divider"></div>
				<button
					className="dropdown-item"
					style={{ fontWeight: "600", paddingBottom: "16px" }}
					onClick={() => {
						signOut();
						handleavatarclick();
						navigate("/", { replace: true });
					}}
				>
					Sign Out
				</button>
			</div>
		</li>
	);
};

export default React.memo(Navbar);
