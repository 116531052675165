import React from "react";
import SectionThreeImg from "../../assets/images/sectionThree.png";

const SectionThree = () => {
	return (
		<>
			<section className="about-seek-sec-box">
				<div className="bottom-layer">
					<img src={SectionThreeImg} alt="" srcSet="" />
				</div>

				<div className="overlay"></div>

				<div className="about-seek-sec-text">
					<p className="about-seek-heading">About SeekaHost University</p>

					<p className="about-seek-para">
						This digital expert online academy provides you with access to all kinds of online courses
						related to eLearning. Whether you are looking for a course about blogging, digital marketing,
						WordPress management, mastering online systems and platforms or SEO – you’ll find a wealth of
						online tutorials to guide you step by step by our accredited industry experts. From short to
						in-depth online guides, choose what suits you and your lifestyle best.
					</p>

					<div className="counters">
						<div className="count">
							<p className="details">
								<span className="yellow-num">2k+</span>
								<br />
								students <br />
								learning
							</p>
						</div>

						<div className="count">
							<p className="details">
								<span className="yellow-num">30+</span>
								<br />
								active <br />
								course
							</p>
						</div>

						<div className="count">
							<p className="details">
								<span className="yellow-num">15+</span>
								<br />
								instructors <br />
								online
							</p>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default SectionThree;
