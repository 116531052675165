import React, { useEffect, useState } from "react";

import CourseSection from "./CourseSection";
import { getSections } from "../../../util/api/section";
import CourseEvaluation from "./CourseEvaluation";

export default function CourseTree({
	courseId,
	sectionSetter,
	numOfSections,
	setlecturePreview,
	setnumOfPerCurrentSection,
	currentPossition,
}) {
	// const subsections = [
	// 	{ title: "Subsection 1 - Introductiondfdfdfsdfdsfdsfdf kfkfjkfk fjjfj fjjfj fjfj" },
	// 	{ title: "Subsection 2 - Lorem ipsum " },
	// 	{ title: "Subsection 3 - dolor sit amet" },
	// ];

	const [courseSections, setcourseSections] = useState([]);
	useEffect(() => {
		async function getData() {
			const res = await getSections(courseId);
			if (res && Array.isArray(res)) {
				sectionSetter(res.length || 0);
				setcourseSections(res);
			}
		}
		getData();
	}, [courseId, sectionSetter]);
	return (
		<div>
			{courseSections.map((section, index) => (
				<CourseSection
					key={index}
					setLecture={setlecturePreview}
					_id={section._id}
					section_title={section.title} //unique
					section_id={index + 1} //unique
					current_section={currentPossition[0]} //common for all sections
					current_subsection={currentPossition[1]} //common for all sections
				/>
			))}
			<CourseEvaluation isFinished={false} />
		</div>
	);
}
