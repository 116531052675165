import React, { useState } from "react";
import { Button, TextField, Modal, Box, Alert } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import LoaderDefault from "../loaders/LoaderDefault";
import useAuth from "../../util/state/authState";
function AdminUpdatePassword() {
	const {
		register,
		formState: { errors },
		getValues,
		handleSubmit,
	} = useForm();
	const { adminUpdatePassword } = useAuth();
	const navigate = useNavigate();
	const [open, setopen] = useState(false);
	const [loading, setloading] = useState(false);
	const onSubmit = async (data) => {
		setloading(true);
		const res = await adminUpdatePassword({
			oldPassword: data.oldpassword,
			newPassword: data.newpassword1,
		});
		setloading(false);
		if (res) {
			alert("Faild");
		} else {
			handleClose();
			navigate("/admin", { replace: true });
		}
	};

	const handleClose = () => {
		setopen(false);
	};
	const handleOpen = () => {
		setopen(true);
	};
	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 400,
		bgcolor: "background.paper",
		borderRadius: 2,
		// border: "2px solid #000",
		boxShadow: 24,
		maxHeight: "95vh",
		p: 4,
		textAlign: "center",
	};

	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box
					sx={style}
					component="form"
					onSubmit={handleSubmit(onSubmit)}
				>
					<h5>Change Password</h5>

					{loading ? (
						<LoaderDefault />
					) : (
						<>
							<TextField
								sx={{ margin: 1 }}
								label="Old password"
								variant="outlined"
								type="password"
								error={errors.oldpassword && true}
								helperText={
									errors.oldpassword ? "Invalid Input" : " "
								}
								{...register("oldpassword", {
									required: true,
									minLength: 6,
								})}
							/>

							<TextField
								sx={{ margin: 1 }}
								label="New Password"
								variant="outlined"
								type="password"
								error={errors.newpassword1 && true}
								helperText={
									errors.newpassword1 ? "Invalid Input" : " "
								}
								{...register("newpassword1", {
									required: true,
									minLength: 6,
								})}
							/>
							<TextField
								label="Retype New Password"
								variant="outlined"
								error={errors.newpassword2 && true}
								type="password"
								helperText={
									errors.newpassword2 ? "Invalid Input" : " "
								}
								{...register("newpassword2", {
									required: "Please confirm password!",
									validate: {
										matchesPreviousPassword: (value) => {
											const { newpassword1 } =
												getValues();
											return (
												newpassword1 === value ||
												"Passwords should match!"
											);
										},
									},
								})}
							/>
							{errors.newpassword2 && (
								<Alert severity="error">
									{"Passwords should match!"}
								</Alert>
							)}
							<Box>
								<Button type="submit" variant="contained">
									Change
								</Button>

								<Button
									onClick={handleClose}
									variant="contained"
									color="error"
									sx={{ ml: 2 }}
								>
									Cancel
								</Button>
							</Box>
						</>
					)}
				</Box>
			</Modal>
			<Button
				sx={{
					textTransform: "lowercase",
					marginLeft: "10px",
					color: "#053075",
					fontWeight: "500",
				}}
				onClick={handleOpen}
			>
				Change Password
			</Button>
		</div>
	);
}

export default AdminUpdatePassword;
