import React from 'react';
import image1 from '../../assets/teachers-at-SeekaHost-University-600x355.jpg';
import image2 from '../../assets/creating-online-courses-600x355.jpg';
import image3 from '../../assets/Fernando-Raymond-online-educator.jpg';
import './ImageSetMiddle.css';

function ImageSetMiddle() {
  return (
    <div className="imageSet">
      <img src={image1} alt="" />
      <img src={image2} alt="" />
      <img src={image3} alt="" />
    </div>
  );
}

export default ImageSetMiddle;
