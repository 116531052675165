import React from "react";
import { useState, useRef } from "react";
import "./NewSlider.css";
import image1 from "../../assets/Neil-Franklin-130x130.jpg";
import image2 from "../../assets/Nickey-Pickorita-130x130.jpg";
import image3 from "../../assets/Miguel-Espinoza-130x130.jpg";
import { Carousel } from "react-bootstrap";
import twitterIcon from "../../assets/Icons/social-twitter-outline.svg";
import telePhoneIcon from "../../assets/Icons/ios-telephone-outline.svg";
import emailIcon from "../../assets/Icons/ios-email-outline.svg";

function NewSlider() {
	const [preClick, setPreClick] = useState(1);
	const ref = useRef(null);
	const onPrevClick = () => {
		ref.current.prev();
	};
	const onNextClick = () => {
		ref.current.next();
	};
	const [check1, setCheck1] = useState(true);
	const [check2, setCheck2] = useState(false);
	const [check3, setCheck3] = useState(false);

	return (
		<div className="sliderAll">
			<section id="slider">
				<input
					type="radio"
					name="slider"
					id="s1"
					checked={check3}
					onChange={function (e) {
						if (preClick === 1) {
							onNextClick();
							setPreClick(2);
						}
						if (preClick === 3) {
							onPrevClick();
							setPreClick(2);
						}
						// console.log("works1 miguel");

						return e.target.checked;
					}}
				/>
				<input
					type="radio"
					name="slider"
					id="s2"
					checked={check2}
					onChange={function (e) {
						// console.log("works2 nicky");
						// if (preClick === 1) {
						//   onNextClick();
						// }
						if (preClick === 2) {
							onNextClick();
							setPreClick(3);
						}
						if (preClick === 1) {
							onPrevClick();
							setPreClick(3);
						}
						// setChecked(!checked);
						return e.target.checked;
					}}
				/>
				<input
					type="radio"
					name="slider"
					id="s3"
					//   defaultChecked={checked}
					checked={check1}
					onChange={function (e) {
						if (preClick === 3) {
							onNextClick();
							setPreClick(1);
						}
						if (preClick === 2) {
							onPrevClick();
							setPreClick(1);
						}
						// console.log("works3 Neil");
						return e.target.checked;
					}}
				/>
				<label htmlFor="s1" id="slide1">
					<img src={image3} alt="" />
				</label>
				<label htmlFor="s2" id="slide2">
					<img src={image2} alt="" />
				</label>
				<label htmlFor="s3" id="slide3">
					<img src={image1} alt="" />
				</label>
			</section>

			{/* <div> L</div>
        <div> R</div> */}
			<div className="carousel-main" id="carousel-main-01">
				<Carousel
					fade
					controls={true}
					interval={null}
					ref={ref}
					autoPlay={false}
					onSlide={(slideNum, e) => {
						// console.log(slideNum + " slider");
						if (slideNum === 0) {
							setCheck1(true);
							setCheck2(false);
							setCheck3(false);
							setPreClick(1);
							// console.log("works3 Neil");
						} else if (slideNum === 2) {
							setCheck1(false);
							setCheck2(true);
							setCheck3(false);
							// console.log("works2 nicky");
							setPreClick(3);
						} else if (slideNum === 1) {
							setCheck1(false);
							setCheck2(false);
							setCheck3(true);
							setPreClick(2);
							// console.log("works1 miguel");
						}
					}}
				>
					<Carousel.Item>
						<h5 style={{ textAlign: "center" }}>Neil Franklin</h5>
						<p style={{ textAlign: "center" }}>
							I am 55 years old and have been an entrepreneur for over 35 years. I have specifially been
							involved with digital marketing for around 10 years, but only as an owner of a company and
							never took the time and trouble to learn. I met Fernando recently and not only did he
							inspire me with his overall attitude and experience, but also to take his course and I have
							learned so much. If I can do it, anyone can
						</p>
					</Carousel.Item>
					<Carousel.Item>
						<h5 style={{ textAlign: "center" }}>Miguel Espinoza</h5>
						<p style={{ textAlign: "center" }}>
							All I can say is that this course if so well put together that it leads you step by step on
							what to do. I have taken many SEO courses and this one is by far the best and easiest to
							understand. Fernando and his team have really put the time to understand those individuals
							that are not really understanding the SEO game. I would highly recommend any course Fernando
							and his team have to offer even if you are a seasoned SEO expert.
						</p>
					</Carousel.Item>
					<Carousel.Item>
						<h5 style={{ textAlign: "center" }}>Nickey Pickorita</h5>
						<p style={{ textAlign: "center" }}>
							Top SEO course if you are starting out, you definitely have to check this out. I assure you,
							you won't regret it as Fernando is a #1 SEO consultant in the UK according to Google. How
							cool is that? Right! He is definitely credible to teach you and this will be the best
							investment you can make. I can vouch for this course and highly recommend it! 🙂
						</p>
					</Carousel.Item>
				</Carousel>
			</div>

			<div className="buttonSection">
				<div>
					<div className="buttonBottom">
						<img src={twitterIcon} alt="" />
					</div>
					Twitter
				</div>
				<div>
					<div className="buttonBottom">
						<img src={telePhoneIcon} alt="" />
					</div>
					020 7175 1344
				</div>
				<div>
					<div className="buttonBottom">
						<img src={emailIcon} alt="" />
					</div>
					support@clickdoacademy.com
				</div>
			</div>
		</div>
	);
}

export default NewSlider;
