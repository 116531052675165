import React, { useState, useEffect } from "react";
import {
	Modal,
	Typography,
	TextField,
	Button,
	Box,
	MenuItem,
	Select,
	InputLabel,
	FormControl,
	FormHelperText,
	FormControlLabel,
	Switch,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { updateLecture } from "../../util/api/lecture";
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "100%",
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 2,
	textAlign: "center",
	overflow: "scroll",
};

function AddLectureModal({ open, handler, lectures, value, setisUIUpdated }) {
	const { register, setValue, getValues } = useForm();
	const lecture = lectures[value];
	const [loading, setloading] = useState(false);
	const [catergory, setcatergory] = useState("");
	const [ispublic, setispublic] = useState(false);

	useEffect(() => {
		if (lecture !== []) {
			setValue("title", lecture && lecture.title);
			setValue("lectureIndex", lecture && lecture.lectureIndex);
			setValue("description", lecture && lecture.description);
			setValue("contentURL", lecture && lecture.contentURL);
			setcatergory(lecture && lecture.catergory);
			setispublic(lecture && lecture.public);
		}
	}, [lecture, setValue]);

	const submit = async () => {
		console.log(getValues());
		setloading(true);

		await updateLecture(lecture._id, {
			...getValues(),
			catergory,
			public: ispublic,
		});
		setloading(false);
		setisUIUpdated(true);
		handler();
	};

	return (
		<Box>
			<Modal
				open={open}
				onClose={handler}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Typography variant="h5" component="h5">
						Edit Lecture
					</Typography>
					<FormControlLabel
						control={<Switch checked={ispublic} onChange={() => setispublic(!ispublic)} />}
						label=" Public"
					/>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<TextField
							sx={{ margin: 3 }}
							id="outlined-basic"
							label="Lecture Name"
							variant="outlined"
							required
							{...register("title")}
						/>
						<TextField
							sx={{ margin: 3 }}
							id="outlined-basic"
							label="Lecture Index"
							variant="outlined"
							type="number"
							required
							{...register("lectureIndex")}
						/>
					</Box>
					<br />
					<FormControl sx={{ m: 3 }}>
						<InputLabel id="Lecture-Catergory">Category</InputLabel>
						<Select
							labelId="Lecture-Catergory"
							value={catergory}
							label="Category"
							onChange={(e) => setcatergory(e.target.value)}
						>
							<MenuItem value={"DO"}>Description only</MenuItem>
							<MenuItem value={"DV"}>Description with Video</MenuItem>
							<MenuItem value={"DI"}>Description with Image</MenuItem>
							<MenuItem value={"DP"}>Description with PDF</MenuItem>
						</Select>
						<FormHelperText>Select a type of lecture</FormHelperText>
					</FormControl>

					{InputTextField(catergory, register("contentURL"))}

					<br></br>
					<TextField
						sx={{ margin: 1, width: "70%" }}
						id="outlined-basic"
						multiline
						minRows={5}
						maxRows={8}
						label="Lecture Description"
						variant="outlined"
						type="number"
						required
						{...register("description")}
					/>
					<br />
					<Box>
						<Button
							disabled={loading}
							onClick={submit}
							type="submit"
							sx={{ margin: 3 }}
							variant="contained"
						>
							Submit
						</Button>

						<Button sx={{ margin: 3 }} variant="contained" color="warning" onClick={handler}>
							Cancel
						</Button>
					</Box>
				</Box>
			</Modal>
		</Box>
	);
}

const InputTextField = (catergory, register) => {
	let output = <></>;
	switch (catergory) {
		case "DV":
			output = (
				<TextField
					sx={{ margin: 3 }}
					id="outlined-basic"
					label="Lecture Video"
					variant="outlined"
					type="url"
					{...register}
				/>
			);
			break;
		case "DI":
			output = (
				<TextField
					sx={{ margin: 3 }}
					id="outlined-basic"
					label="Lecture Image"
					variant="outlined"
					type="url"
					{...register}
				/>
			);
			break;
		case "DP":
			output = (
				<TextField
					sx={{ margin: 3 }}
					id="outlined-basic"
					label="Lecture PDF"
					variant="outlined"
					type="url"
					{...register}
				/>
			);
			break;

		default:
			output = <></>;
			break;
	}
	return output;
};

export default AddLectureModal;
