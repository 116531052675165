import React from "react";
import "../styles/WebMiddle.css";
import "../styles/contactUs.css";
import { FmdGood, Phone, SendSharp } from "@mui/icons-material";

function ContactUs() {
	return (
		<div>
			<div className="page-title">Contact</div>
			<div className="main-container">
				<div className="single-item">
					<div className="icon-circle">
						<FmdGood style={{ width: "90px", height: "100px" }} />
					</div>
					<p className="text-normal">
						SeekaHost Ltd. Angel <br></br> House 225 Marsh Wall <br></br>London.E14 9FW
					</p>
				</div>
				<div className="single-item">
					<div className="icon-circle">
						<Phone style={{ width: "90px", height: "100px" }} />
					</div>
					<p className="text-normal">
						CONTACT US
						<br></br> Phone:+44 033 0321 1344
					</p>
				</div>
				<div className="single-item">
					<div className="icon-circle">
						<SendSharp style={{ width: "90px", height: "100px" }} />
					</div>
					<p className="text-normal">
						WRITE SOME WORDS
						<br></br>university@seekahost.com
					</p>
				</div>
			</div>
		</div>
	);
}

export default ContactUs;
