import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { Avatar, Box, Breadcrumbs, Divider, Fab, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import CourseCard from "../components/Couses/CourseCard";
import useAuth from "../util/state/authState";
import { useForm } from "react-hook-form";
import LoaderDefault from "../components/loaders/LoaderDefault";
import { updateStudent } from "../util/api/student";
import { vallidateUser } from "../util/validations/dataValidation";
import UpdatePasswordModal from "../components/modals/UpdatePasswordModal";
import { allCompletedCourses, allEnrolledCourses } from "../util/api/enrollment";

function StudentDashboad() {
	const navigate = useNavigate();
	const [selectedCourse, setSelectedCourse] = useState([]);
	const [loading, setloading] = useState(false);
	const [courseCardloading, setcourseCardloading] = useState(false);
	const [modalopen, setmodalopen] = useState(false);

	const { register, setValue, getValues } = useForm();
	const { user, signOut } = useAuth();

	const handleModalopen = () => {
		setmodalopen(true);
	};

	const handleModalClose = () => {
		setmodalopen(false);
	};

	useEffect(() => {
		if (user) {
			setValue("data", user);
		}
	}, [setValue, user]);

	const handleGetAllCourses = async () => {
		setcourseCardloading(true);
		const res = await allEnrolledCourses(user._id);
		if (res) setSelectedCourse(res);
		setcourseCardloading(false);
	};

	const handleUpdate = async () => {
		setloading(true);
		if (user._id) {
			const res = await updateStudent(user._id, getValues("data"));
			if (res && vallidateUser(res)) {
				setValue("data", res);
			}
			//TODO:add error
		}
		setloading(false);
	};

	const handleCompletedCourse = async () => {
		setcourseCardloading(true);
		const res = await allCompletedCourses(user._id);
		if (res) setSelectedCourse(res);
		setcourseCardloading(false);
	};
	const handleCertificates = () => {
		setSelectedCourse([]);
	};
	return (
		<div style={{ margin: 0 }}>
			<UpdatePasswordModal open={modalopen} handler={handleModalClose}></UpdatePasswordModal>
			<center>
				<Box
					sx={{
						// bgcolor: "yellow",
						borderRadius: "5px",
						boxShadow: "-1px 0px 11px 0px #888888",
						maxWidth: "1000px",
						marginBottom: "20px",
						display: "flex",
						flexWrap: "wrap",
						alignContent: "center",
						justifyContent: "center",
						padding: "1em",
					}}
				>
					<Box
						sx={{
							// bgcolor: "red",

							padding: "10px",
							overflow: "hidden",
							minWidth: "150px",
						}}
					>
						<Avatar
							alt={user.name || ""}
							src={user.image ? "data:image/jpeg;base64," + user.image : "#"}
							sx={{ width: 150, height: 150 }}
						/>
						<Fab
							sx={{
								marginTop: "-70px",
								marginLeft: "100px",
							}}
							size="small"
						>
							<AddIcon />
						</Fab>
						<h1 style={{ fontSize: "1.5em" }}>{user.name || ""}</h1>
						<Button variant="contained" size="small" onClick={handleModalopen}>
							Change Password
						</Button>
						<br />
						<Button
							onClick={() => {
								signOut();
								navigate("/", { replace: "true" });
							}}
							variant="contained"
							size="small"
							sx={{ margin: "10px" }}
						>
							Sign Out
						</Button>
					</Box>
					<Box
						sx={{
							// bgcolor: "blue",
							width: "70%",
							minWidth: "150px",
							padding: "5px",
						}}
					>
						<h1
							style={{
								fontSize: "1.5em",
								display: "flex",
								marginLeft: "10px",
							}}
						>
							Personal Info
						</h1>
						<Divider />
						{loading ? (
							<LoaderDefault />
						) : (
							<Box
								sx={{
									display: "flex",
									// margin: "20px",
									flexWrap: "wrap",
									alignContent: "center",
								}}
							>
								<TextField
									id="outlined-basic"
									label="First Name"
									variant="outlined"
									sx={{ margin: "25px" }}
									{...register("data.name", { required: true })}
								/>
								<TextField
									id="outlined-basic"
									label="Last Name"
									variant="outlined"
									sx={{ margin: "25px" }}
									{...register("data.lastName", { required: false })}
								/>
								<TextField
									id="outlined-basic"
									label="Email"
									variant="outlined"
									sx={{ margin: "25px" }}
									disabled
									{...register("data.email")}
								/>
								<TextField
									id="outlined-basic"
									label="Phone"
									variant="outlined"
									sx={{ margin: "25px" }}
									{...register("data.phoneNumber", { required: false })}
								/>
								<TextField
									id="outlined-basic"
									label="Address 1"
									variant="outlined"
									sx={{ margin: "25px" }}
									{...register("data.Address.address1", { required: false })}
								/>
								<TextField
									id="outlined-basic"
									label="Address2 (optional)"
									variant="outlined"
									sx={{ margin: "25px" }}
									{...register("data.Address.address2", { required: false })}
								/>
								<TextField
									id="outlined-basic"
									label="City"
									variant="outlined"
									sx={{ margin: "25px" }}
									{...register("data.Address.city", { required: false })}
								/>
								<TextField
									id="outlined-basic"
									label="State/Region"
									variant="outlined"
									sx={{ margin: "25px" }}
									{...register("data.Address.state", { required: false })}
								/>
								<TextField
									id="outlined-basic"
									label="Zip/Postal Code"
									variant="outlined"
									sx={{ margin: "25px" }}
									type="number"
									{...register("data.Address.pincode", { required: false })}
								/>
								<TextField
									id="outlined-basic"
									label="Country"
									variant="outlined"
									sx={{ margin: "25px" }}
									{...register("data.Address.country", { required: false })}
								/>
							</Box>
						)}
						<Button variant="contained" onClick={handleUpdate}>
							Update
						</Button>
					</Box>
				</Box>
				<Box
					sx={{
						// bgcolor: "yellow",
						maxWidth: "1000px",
						borderRadius: "10px",
						boxShadow: "-1px 0px 11px 0px #888888",
					}}
				>
					<Box sx={{ display: "flex", paddingLeft: "10px" }}>
						<h1 style={{ fontsize: "1em" }}>My courses</h1>
					</Box>
					<Divider />
					<Box>
						<Breadcrumbs>
							<Button
								onClick={handleGetAllCourses}
								sx={selectedCourse === "Inprogress" ? { color: "black" } : {}}
							>
								All Courses
							</Button>
							<Button onClick={handleCompletedCourse}>Completed</Button>
							<Button onClick={handleCertificates}>Certificates</Button>
						</Breadcrumbs>
					</Box>
					{courseCardloading ? (
						<LoaderDefault />
					) : (
						<Box
							sx={{
								// bgcolor: "red",
								padding: "10px",
								display: "flex",
								flexWrap: "wrap",
								justifyContent: "center",
							}}
						>
							{selectedCourse.map((course, index) => (
								<CourseCard
									key={index}
									student_count={course.student_count}
									fees={course.fees}
									instructor={course.courseId && course.courseId.instructor}
									course_name={course.courseId && course.courseId.title}
									img_url={course.courseId && course.courseId.imageURL}
								/>
							))}
						</Box>
					)}
				</Box>
			</center>
		</div>
	);
}

export default StudentDashboad;
