import React from 'react';
// import buffer from '../Assets/Icons/social-buffer-outline.svg';
import './CategoriesItem.css';
function CategoriesItem({ icon, contentHeader, content }) {
  return (
    <div className="category-item">
      <span className="category-icon">
        <img src={icon} alt="" />
      </span>
      <div className="category-content">
        <span className="category-inner-header">{contentHeader}</span>
        <br />
        <br />
        {content}
      </div>
    </div>
  );
}

export default CategoriesItem;
