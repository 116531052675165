import { Box, useMediaQuery } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CourseProgress from "../components/Student/CoursePreview/CourseProgress";
import CourseTree from "../components/Student/CoursePreview/CourseTree";
import LecturePreview from "../components/Student/CoursePreview/LecturePreview";
import { getEnrollment } from "../util/api/enrollment";
import useAuth from "../util/state/authState";

function StudentCourseContent() {
	const matches = useMediaQuery("(min-width:1200px)");
	const params = useParams();
	const courseId = params.id;
	const { user } = useAuth();
	const [numOfSections, setnumOfSections] = useState(0);

	const [lecturePreview, setlecturePreview] = useState({});
	//todo get from db
	const [currentPossition, setcurrentPossition] = useState([0, 0]);

	useEffect(() => {
		async function getData(params) {
			if (user) {
				if (courseId) {
					const res = await getEnrollment({ studentId: user._id, courseId: courseId });
					if (res) setcurrentPossition([res.section, res.lecture]);
				}
			}
		}
		getData();
	
	}, [user, courseId]);

	return (
		<Box
			sx={{
				marginLeft: matches ? "3em" : "1em",
				padding: "2em",
				margin: "0.2em",
				bgcolor: "background.paper",
				display: "flex",
			}}
		>
			<Box sx={{ padding: "1em", width: "20%", minWidth: "350px" }}>
				<CourseProgress title={"Course title"} progress={60} />
				<CourseTree
					courseId={params.id}
					sectionSetter={setnumOfSections}
					numOfSections={numOfSections}
					setlecturePreview={setlecturePreview}
					currentPossition={currentPossition}
					setcurrentPossition={setcurrentPossition}
				/>
			</Box>
			<Box sx={{ padding: "1em", width: "80%" }}>
				<LecturePreview lecturePreview={lecturePreview}></LecturePreview>
			</Box>
		</Box>
	);
}

export default StudentCourseContent;
