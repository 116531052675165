import {
	Button,
	Modal,
	TextField,
	Box,
	Typography,
	Alert,
} from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useAuth from "../../util/state/authState";

function UpdatePasswordModal({ open, handler }) {
	const navigate = useNavigate();
	const [loading, setloading] = useState(false);
	const [showError, setshowError] = useState("");
	const { updatePassword } = useAuth();
	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 400,
		bgcolor: "background.paper",
		borderRadius: 2,
		// border: "2px solid #000",
		boxShadow: 24,
		maxHeight: "95vh",
		p: 4,
		textAlign: "center",
	};

	const {
		register,
		getValues,
		formState: { errors },
		handleSubmit,
		reset,
	} = useForm();

	const onSubmit = async (data) => {
		setshowError("");
		setloading(true);
		const res = await updatePassword({
			oldPassword: data.oldpassword,
			newPassword: data.newpassword2,
		});
		setloading(false);
		if (res) {
			return setshowError(res.error);
		}

		navigate("/signin", { replace: true });
	};

	return (
		<Box>
			<Modal
				open={open}
				onClose={handler}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box
					sx={style}
					component="form"
					onSubmit={handleSubmit(onSubmit)}
				>
					<Typography variant="h5" component="h5">
						Change Password
					</Typography>
					{showError && (
						<Alert sx={{ margin: "1em" }} severity="error">
							{showError || "Unkonow Error Occured"}
						</Alert>
					)}
					<TextField
						sx={{ margin: 1 }}
						label="Old Password"
						variant="outlined"
						type="password"
						error={errors.oldpassword}
						helperText={errors.oldpassword ? "Invalid Input" : " "}
						{...register("oldpassword", {
							required: true,
							minLength: 6,
						})}
					/>
					<TextField
						sx={{ margin: 1 }}
						label="New Password"
						variant="outlined"
						type="password"
						error={errors.newpassword1}
						helperText={errors.newpassword1 ? "Invalid Input" : " "}
						{...register("newpassword1", {
							required: true,
							minLength: 6,
						})}
					/>
					<TextField
						sx={{ margin: 1 }}
						label="Retype New Password"
						variant="outlined"
						error={errors.newpassword2}
						type="password"
						helperText={errors.newpassword2 ? "Invalid Input" : " "}
						{...register("newpassword2", {
							required: "Please confirm password!",
							validate: {
								matchesPreviousPassword: (value) => {
									const { newpassword1 } = getValues();
									return (
										newpassword1 === value ||
										"Passwords should match!"
									);
								},
							},
						})}
					/>
					{errors.newpassword2 && (
						<Alert severity="error">
							{"Passwords should match!"}
						</Alert>
					)}
					<br />
					<Box>
						<Button
							disabled={loading}
							type="submit"
							sx={{ margin: 3 }}
							variant="contained"
						>
							Change
						</Button>

						<Button
							sx={{ margin: 3 }}
							variant="contained"
							color="warning"
							onClick={() => {
								reset();
								handler();
							}}
						>
							Cancel
						</Button>
					</Box>
				</Box>
			</Modal>
		</Box>
	);
}

export default UpdatePasswordModal;
