import React from "react";
import SectionTypeOne from "./pagesComponents/SectionTypeOne";
import SectionFourImg from "../../assets/images/sectionFour.png";

const SectionFour = () => {
	return (
		<div>
			<SectionTypeOne
				title="About SeekaHost University"
				contentOne="This digital expert online academy provides you with access to all kinds of online courses related to eLearning. Whether you are looking for a course about blogging, digital marketing, WordPress management, mastering online systems and platforms or SEO – you’ll find a wealth of online tutorials to guide you step by step by our accredited industry experts. From short to in-depth online guides, choose what suits you and your lifestyle best."
				// contentSecond="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Cras semper auctor neque vitae tempus quam. Consectetur adipiscing elit duis tristique sollicitudin. Sollicitudin nibh sit amet commodo nulla facilisi."
				imgSTT={SectionFourImg}
				bgColor="#fff"
				sectionType="four"
			/>
		</div>
	);
};

export default SectionFour;
