import React from "react";
import "./RowComponentOne.css";
import RowContentOne from "./RowContentOne";

function RowComponentOne({ image, bigHeader, subHeader, description, ID }) {
	return (
		<div className="grid-row-1" id={ID}>
			<div
				style={{
					backgroundImage: "url(" + image + ")",
					// backgroundImage: `url(${image})`,
					backgroundPosition: "center center",
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
				}}
				className="one-grid-row-img"

				// `url(${image})`
			></div>
			<RowContentOne
				bigHeader={bigHeader}
				subHeader={subHeader}
				description={description}
			/>
		</div>
	);
}

export default RowComponentOne;
