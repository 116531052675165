import { Box } from "@mui/system";
import { List, ListItemText, Button, Divider, ListItem, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

import { addLecture, getLectures, deleteLecture } from "../../util/api/lecture";
import LoaderDefault from "../loaders/LoaderDefault";
import AddLectureModal from "../modals/EditLectureModal";
import CoursePreviewModal from "../modals/CoursePreviewModal";

//fixed bug: 0017 [add preivew button]
function LectureEditor({ sectionID, courseId }) {
	const [lectures, setlectures] = useState([]);
	const [section, setsection] = useState([]);
	const [loading, setloading] = useState(false);
	const [isUiupdated, setisUiupdated] = useState(false);
	const [selectedValue, setselectedValue] = useState(0);

	//=============modal
	const [isLectureModalOpen, setisLectureModalOpen] = useState(false);
	const [isPreviewModalOpen, setisPreviewModalOpen] = useState(false);

	const handleLectureClose = () => {
		setisLectureModalOpen(false);
	};
	const handleLectureOpen = () => {
		setisLectureModalOpen(true);
	};
	const handlePreviewClose = () => {
		setisPreviewModalOpen(false);
	};
	const handlePreviewOpen = () => {
		setisPreviewModalOpen(true);
	};

	useEffect(() => {
		async function getData() {
			setisUiupdated(false);
			setloading(true);
			const res = await getLectures(sectionID);
			setlectures(res);
			setsection({ lectures: res });
			setloading(false);
		}
		getData();
	}, [isUiupdated, sectionID]);

	return (
		<Box>
			<>
				<AddLectureModal
					open={isLectureModalOpen}
					handler={handleLectureClose}
					lectures={lectures}
					value={selectedValue}
					setisUIUpdated={setisUiupdated}
				></AddLectureModal>
				{loading ? (
					<LoaderDefault></LoaderDefault>
				) : (
					<Box>
						<Divider />
						<List>
							{lectures &&
								lectures.map((lecture, index) => (
									<div key={index}>
										<CoursePreviewModal
											open={isPreviewModalOpen}
											id={index}
											handleClose={handlePreviewClose}
											section={section}
											selectedSection={0}
											selectedIndex={selectedValue}
										></CoursePreviewModal>
										<ListItem key={index} onClick={() => setselectedValue(index)}>
											<ListItemText
												key={index}
												sx={{ marginLeft: "5em" }}
												primary={lecture.lectureIndex + ". " + lecture.title}
											/>
											<Button onClick={handleLectureOpen}>
												<Tooltip title="Edit">
													<EditIcon></EditIcon>
												</Tooltip>
											</Button>
											<Button onClick={handlePreviewOpen}>
												<Tooltip title="Preview">
													<RemoveRedEyeIcon></RemoveRedEyeIcon>
												</Tooltip>
											</Button>
											<Button
												onClick={async () => {
													setloading(true);
													await deleteLecture(lecture._id);
													setloading(false);
													setisUiupdated(true);
												}}
											>
												<Tooltip title="Delete">
													<DeleteIcon></DeleteIcon>
												</Tooltip>
											</Button>
										</ListItem>
										<Divider />
									</div>
								))}
						</List>

						<Button
							sx={{ margin: "2px", marginLeft: "2em" }}
							variant="outlined"
							onClick={async () => {
								setloading(true);
								await addLecture({
									courseId,
									sectionId: sectionID,
									title: "Lecture " + lectures.length,
									catergory: "DO",
									lectureIndex: lectures.length + 1,
									descrition: "Description",
								});
								setloading(false);
								setisUiupdated(true);
							}}
						>
							Add Lecture
						</Button>
					</Box>
				)}
			</>
		</Box>
	);
}

export default LectureEditor;
