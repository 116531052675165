import React, { useEffect, useState } from "react";
import useAuth from "../../util/state/authState";
import AccessDenied from "../AccessDenied";

function InstructorPrivateRoute({ children }) {
	const { user } = useAuth();
	const [isfirst, setisfirst] = useState(true);
	useEffect(() => {
		setisfirst(false);
	}, []);
	if (user && user.type === "instructor" && !isfirst) {
		return children;
	} else if (isfirst) {
		return <div style={{ height: "80vh" }}></div>;
	} else {
		return <AccessDenied />;
	}
}

export default InstructorPrivateRoute;
