// import { Tab, TabList, Tabs } from "@chakra-ui/react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Button,
	Box,
	Typography,
	Link,
	Avatar,
	Rating,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { Delete } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { getNotApprovedReviews, rejectReview, approveReview } from "../util/api/review";
import {
	getNotApprovedCourse,
	approveCourse,
	admindeleteCourse,
	getAdminApprovedCourses,
	// updateToDraft, //fixed bug:0023
} from "../util/api/courses";
import {
	getNotApprovedInstructors,
	approveInstructor,
	removeInstructor,
	getApprovedInstructors,
} from "../util/api/instructor";
import { getAdmins, deleteAdmin, changeRating } from "../util/api/admin";
import LoaderDefault from "../components/loaders/LoaderDefault";
import AddAdminRow from "../components/Admin/AddAdminRow";
import { useSnackbar } from "notistack";
import useAuth from "../util/state/authState";
import { encode } from "../util/base64Encorder";

//TODO:Confirm models
export default function AdminDashboard() {
	const { admin } = useAuth();
	const { enqueueSnackbar } = useSnackbar();
	const [pendingReveiwsRows, setpendingReveiwsRows] = useState([]);
	const [pendingCoursesRows, setpendingCoursesRows] = useState([]);
	const [pendingInstructorsRow, setpendingInstructorsRow] = useState([]);
	const [approvedInstructor, setapprovedInstructor] = useState([]);
	const [activecourses, setactivecourses] = useState([]);
	const [pendingAdminsRow, setpendingAdminsRow] = useState([]);
	const [updateReview, setupdateReview] = useState(false);

	const [loading, setloading] = useState(true);

	//TODO show review sum in review row
	useEffect(() => {
		setupdateReview(false);

		async function getReviews() {
			// setloading(true);
			const res = await getNotApprovedReviews();
			// setloading(false);
			if (Array.isArray(res)) setpendingReveiwsRows(res);
		}
		async function fetchActiveCourses() {
			// setloading(true);
			const res = await getAdminApprovedCourses();
			// setloading(false);
			if (Array.isArray(res)) setactivecourses(res);
		}

		async function getCourses() {
			// setloading(true);
			const res = await getNotApprovedCourse();
			// setloading(false);
			if (res.error)
				return enqueueSnackbar(res.error, {
					variant: "error",
					autoHideDuration: 6000,
				});
			if (Array.isArray(res)) setpendingCoursesRows(res);
		}
		async function getInstructors() {
			// setloading(true);
			const res = await getNotApprovedInstructors();
			// setloading(false);
			if (Array.isArray(res)) setpendingInstructorsRow(res);
		}
		async function fetchApprovedInstructors() {
			// setloading(true);
			const res = await getApprovedInstructors();
			// setloading(false);
			if (Array.isArray(res)) setapprovedInstructor(res);
		}
		async function getAdminsfunc() {
			// setloading(true);
			const res = await getAdmins();
			// setloading(false);
			if (Array.isArray(res)) setpendingAdminsRow(res);
		}

		async function doAll() {
			setloading(true);
			await fetchActiveCourses();
			await getReviews();
			await getCourses();
			await getInstructors();
			await getAdminsfunc();
			await fetchApprovedInstructors();
			await setloading(false);
		}
		if (admin) {
			doAll();
		}
	}, [updateReview, enqueueSnackbar, admin]);
	return loading ? (
		<LoaderDefault></LoaderDefault>
	) : (
		<div>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					textAlign: "center",
					marginTop: "30px",
				}}
			>
				<center>
					{activecourses && activecourses.length > 0 ? (
						<>
							<Typography sx={{ fontSize: "1.3em", fontWeight: "400" }}>
								{"Active Courses (" + (activecourses && activecourses.length) + ")"}
							</Typography>
							<TableContainer component={Paper} sx={{ maxWidth: "800px", marginTop: "10px" }}>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>Course Name</TableCell>
											<TableCell>Instructor</TableCell>
											<TableCell>Remove</TableCell>
											<TableCell>Change Rating</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{activecourses &&
											activecourses.map((row, idx) => (
												<TableRow key={row._id} scope="row">
													<TableCell>
														<Link underline="none" href={"/courses/" + row.searchId}>
															{row.title}
														</Link>
													</TableCell>
													<TableCell>{row.instructor && row.instructor.name}</TableCell>
													<TableCell>
														<Button
															size="small"
															onClick={async () => {
																const nm = Math.floor(
																	Math.random() * 10000 + 10000
																).toString();
																const cnf = window.prompt(
																	"Are you sure you want to delete? Type " +
																		nm +
																		" to remove."
																);
																if (cnf === nm) {
																	setloading(true);
																	await admindeleteCourse(row._id);
																	setupdateReview(true);
																}
															}}
														>
															<Delete />
														</Button>
													</TableCell>
													<TableCell>
														<Rating
															precision={0.5}
															value={row.rating && row.rating.ratingFinal}
															onChange={async (e, v) => {
																console.log(row.rating);
																if (row.rating && v !== row.rating.ratingFinal) {
																	const res = await changeRating(row._id, {
																		rating: v,
																	});
																	//TODO:handle error
																	if (res) {
																		setupdateReview(true);
																	}
																}
															}}
														/>
													</TableCell>
												</TableRow>
											))}
									</TableBody>
								</Table>
							</TableContainer>
						</>
					) : (
						<></>
					)}
					<br />
					{pendingCoursesRows && pendingCoursesRows.length > 0 ? (
						<>
							<Typography sx={{ fontSize: "1.3em", fontWeight: "400" }}>
								{"Pending Courses (" + (pendingCoursesRows && pendingCoursesRows.length) + ")"}
							</Typography>
							<TableContainer component={Paper} sx={{ maxWidth: "800px", marginTop: "10px" }}>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>Course Name</TableCell>
											<TableCell>Instructor</TableCell>
											<TableCell>Approval</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{pendingCoursesRows &&
											pendingCoursesRows.map((row) => (
												<TableRow key={row._id} scope="row">
													<TableCell>
														<Link underline="none" href={"/courses/" + row.searchId}>
															{row.title}
														</Link>
													</TableCell>
													<TableCell>{row.instructor.name}</TableCell>
													<TableCell>
														<Button
															color="success"
															variant="contained"
															size="small"
															onClick={async () => {
																if (
																	window.confirm(
																		"Are you sure you want to Approve?"
																	) === true
																) {
																	setloading(true);
																	await approveCourse(row._id);
																	setupdateReview(true);
																}
															}}
														>
															Approve
														</Button>

														<Button
															color="error"
															variant="contained"
															sx={{ marginLeft: "10px" }}
															size="small"
															onClick={async () => {
																if (
																	window.confirm(
																		"Are you sure you want to reject?"
																	) === true
																) {
																	setloading(true);
																	//fixed bug:0023
																	await admindeleteCourse(row._id);
																	setupdateReview(true);
																}
															}}
														>
															Reject
														</Button>
													</TableCell>
												</TableRow>
											))}
									</TableBody>
								</Table>
							</TableContainer>
						</>
					) : (
						<></>
					)}
					<br />
					{pendingReveiwsRows && pendingReveiwsRows.length > 0 ? (
						<>
							<Typography sx={{ fontSize: "1.3em", fontWeight: "400" }}>
								{"Pending Reveiws (" + (pendingReveiwsRows && pendingReveiwsRows.length) + ")"}
							</Typography>
							<TableContainer component={Paper} sx={{ maxWidth: "800px", marginTop: "10px" }}>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>User</TableCell>
											<TableCell>Course</TableCell>

											<TableCell>Reveiw</TableCell>
											<TableCell>Approval</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{pendingReveiwsRows &&
											pendingReveiwsRows.map((row) => (
												<TableRow key={row._id} scope="row">
													<TableCell>{row.createdBy && row.createdBy.name}</TableCell>
													<TableCell>{row.course && row.course.title}</TableCell>
													<TableCell>{row.review}</TableCell>
													<TableCell>
														<Button
															color="success"
															variant="contained"
															size="small"
															onClick={async () => {
																setloading(true);
																await approveReview(row._id);
																setupdateReview(true);
															}}
														>
															Approve
														</Button>

														<Button
															color="error"
															variant="contained"
															sx={{ margin: "10px" }}
															size="small"
															onClick={async () => {
																if (
																	window.confirm(
																		"Are you sure you want to delete?"
																	) === true
																) {
																	setloading(true);
																	await rejectReview(row._id);
																	setupdateReview(true);
																}
															}}
														>
															Reject
														</Button>
													</TableCell>
												</TableRow>
											))}
									</TableBody>
								</Table>
							</TableContainer>
						</>
					) : (
						<></>
					)}
					<br />
					<br />
					{pendingInstructorsRow && pendingInstructorsRow.length > 0 ? (
						<>
							<Typography sx={{ fontSize: "1.3em", fontWeight: "400" }}>
								{"Pending Instructors(" + (pendingInstructorsRow && pendingInstructorsRow.length) + ")"}
							</Typography>
							<TableContainer component={Paper} sx={{ maxWidth: "800px", marginTop: "10px" }}>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>Email</TableCell>
											<TableCell>User Name</TableCell>
											<TableCell>Approval</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{pendingInstructorsRow.map((row) => (
											<TableRow key={row._id} scope="row">
												<TableCell>{row.email}</TableCell>
												<TableCell>{row.name}</TableCell>
												<TableCell>
													<Button
														color="success"
														variant="contained"
														size="small"
														onClick={async () => {
															setloading(true);
															await approveInstructor(row._id);
															setupdateReview(true);
														}}
													>
														Approve
													</Button>

													<Button
														color="error"
														variant="contained"
														sx={{ margin: "10px" }}
														size="small"
														onClick={async () => {
															if (
																window.confirm("Are you sure you want to delete?") ===
																true
															) {
																setloading(true);
																await removeInstructor(row._id);
																setupdateReview(true);
															}
														}}
													>
														Reject
													</Button>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</>
					) : (
						<></>
					)}
					{approvedInstructor && approvedInstructor.length > 0 ? (
						<>
							<Typography sx={{ fontSize: "1.3em", fontWeight: "400" }}>
								{"Active Instructors (" + (approvedInstructor && approvedInstructor.length) + ")"}
							</Typography>
							<TableContainer component={Paper} sx={{ maxWidth: "800px", marginTop: "10px" }}>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell></TableCell>
											<TableCell>Email</TableCell>
											<TableCell>User Name</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{approvedInstructor.map((row) => (
											<TableRow key={row._id} scope="row">
												<TableCell>
													{row.image ? (
														<Avatar
															alt={row.name}
															src={
																row.image.type === "Buffer"
																	? "data:image/png;base64," +
																	  encode(row.image.data).toString()
																	: ""
															}
														></Avatar>
													) : (
														<Avatar alt="placeholder"></Avatar>
													)}
												</TableCell>
												<TableCell>{row.email}</TableCell>
												<TableCell>{row.name}</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</>
					) : (
						<></>
					)}
					<br></br>
					<Typography>Manage Admins</Typography>
					<TableContainer component={Paper} sx={{ maxWidth: "800px", marginTop: "10px" }}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>User Name</TableCell>
									<TableCell>Email</TableCell>
									<TableCell>Status</TableCell>
									<TableCell>Comfirmation Code</TableCell>
									<TableCell>Remove</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{pendingAdminsRow.map((row) => (
									<TableRow key={row._id} scope="row">
										<TableCell>{row.name}</TableCell>
										<TableCell>{row.email}</TableCell>
										<TableCell>{row.Accountstatus}</TableCell>
										<TableCell>{row.confirmationCode > 0 ? row.confirmationCode : ""}</TableCell>
										<TableCell>
											<Button
												disabled={admin.role !== "superadmin"}
												onClick={async () => {
													const cnf = window.prompt(
														"Are you sure you want to delete?\nType " +
															row.name +
															" to remove."
													);

													if (cnf === row.name) {
														setloading(true);
														await deleteAdmin(row._id, { performedBy: admin._id });
														setloading(false);
														setupdateReview(true);
													}
												}}
											>
												<Delete />
											</Button>
										</TableCell>
									</TableRow>
								))}
								<AddAdminRow uiUpdated={setupdateReview} />
							</TableBody>
						</Table>
					</TableContainer>
				</center>
			</Box>
		</div>
	);
}
