import React from "react";
import { Box, Typography } from "@mui/material";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

function CourseProgress({ title, progress }) {
	const BorderLinearProgress = styled(LinearProgress)(() => ({
		height: 10,
		borderRadius: 5,
		[`&.${linearProgressClasses.colorPrimary}`]: {
			backgroundColor: "#4992c9",
		},
		[`& .${linearProgressClasses.bar}`]: {
			borderRadius: 5,
			backgroundColor: "white",
		},
	}));
	return (
		<Box sx={{ padding: "1em", borderRadius: "0.5em", backgroundColor: "#0670bf", marginBottom: "1em" }}>
			<Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", paddingBottom: "0.5em" }}>
				<Typography component={"h2"} color={"white"}>
					{title || "..."}
				</Typography>
				<Box sx={{ padding: "0.2em", borderRadius: "0.3em", backgroundColor: "#4992c9", color: "white" }}>
					{(progress || 0) + "%"}
				</Box>
			</Box>
			<BorderLinearProgress variant="determinate" value={progress || 0} />
		</Box>
	);
}

export default CourseProgress;
