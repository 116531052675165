import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { Avatar, Box, Divider, Fab, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useState, useRef } from "react";
import useAuth from "../util/state/authState";
import { useForm } from "react-hook-form";
import LoaderDefault from "../components/loaders/LoaderDefault";
import { vallidateUser } from "../util/validations/dataValidation";
import UpdatePasswordModal from "../components/modals/UpdatePasswordModal";
import { updateInstructor, getInstructorProfileData } from "../util/api/instructor";
import { addImage } from "../util/api/image";
import { encode } from "../util/base64Encorder";

//fixed bug:0019
function InstructorProfile() {
	const navigate = useNavigate();
	const upload = useRef(null);
	const [loading, setloading] = useState(false);
	const [modalopen, setmodalopen] = useState(false);
	const [imageData, setimageData] = useState("");
	const [imageloading, setimageloading] = useState(false);

	const { register, setValue, getValues } = useForm();
	const { user, signOut, DP, changeDP } = useAuth();

	const handleModalopen = () => {
		setmodalopen(true);
	};

	const handleModalClose = () => {
		setmodalopen(false);
	};

	const renderImage = (file) => {
		try {
			if (file.type === "Buffer") {
				const reader = "data:image/png;base64," + encode(file.data);

				return setimageData(reader);
			} else {
				const reader = new FileReader();
				reader.readAsDataURL(file);

				reader.onloadend = async () => {
					return setimageData(reader.result);
				};
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handleUpload = async (e) => {
		const file = e.target.files[0];

		if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
			try {
				renderImage(file);
				const formData = new FormData();
				formData.append("image", file);
				formData.append("category", "profile");
				setimageloading(true);
				const res = await addImage(user._id, formData);
				if (res) {
					changeDP(res);
					//TODO:handle errors
				}
			} catch (error) {
				//TODO:show error
				console.log("Faild");
			}
			setimageloading(false);
		} else {
			//TODO:show error
			console.log("no file selected");
		}
	};

	useEffect(() => {
		async function getData() {
			const res = await getInstructorProfileData();
			if (res && vallidateUser(res)) {
				setValue("data", res);
			}
		}
		if (user) {
			getData();
			renderImage(DP);
		}
	}, [setValue, user, DP]);

	const handleUpdate = async () => {
		setloading(true);
		if (user._id) {
			const res = await updateInstructor(user._id, getValues("data"));
			if (res && vallidateUser(res)) {
				setValue("data", res);
			}
			//TODO:add error
		}
		setloading(false);
	};

	return (
		<div style={{ margin: 0 }}>
			<UpdatePasswordModal open={modalopen} handler={handleModalClose}></UpdatePasswordModal>
			<center>
				<Box
					sx={{
						// bgcolor: "yellow",
						borderRadius: "5px",
						boxShadow: "-1px 0px 11px 0px #888888",
						maxWidth: "1000px",
						marginBottom: "20px",
						display: "flex",
						flexWrap: "wrap",
						alignContent: "center",
						justifyContent: "center",
						padding: "1em",
					}}
				>
					<Box
						sx={{
							// bgcolor: "red",

							padding: "10px",
							overflow: "hidden",
							minWidth: "150px",
						}}
					>
						<Avatar
							alt={user.name || ""}
							src={imageData}
							// src={user.image ? "data:image/jpeg;base64," + user.image : "#"}
							sx={{ width: 150, height: 150 }}
						/>
						{/* <Box component="input" type="file"> */}

						<label htmlFor="instructorImage">
							<Fab
								onClick={() => {
									upload.current.click();
								}}
								sx={{
									marginTop: "-70px",
									marginLeft: "100px",
								}}
								size="small"
							>
								{imageloading ? <></> : <AddIcon />}
							</Fab>
						</label>
						<input
							id="instructorImage"
							type={"file"}
							hidden
							ref={upload}
							onChange={handleUpload}
							accept="image/png,  image/jpeg"
						></input>
						{/* </Box> */}
						<h1 style={{ fontSize: "1.5em" }}>{user.name || ""}</h1>
						<Button variant="contained" size="small" onClick={handleModalopen}>
							Change Password
						</Button>
						<br />
						<Button
							onClick={() => {
								signOut();
								navigate("/", { replace: "true" });
							}}
							variant="contained"
							size="small"
							sx={{ margin: "10px" }}
						>
							Sign Out
						</Button>
					</Box>
					<Box
						sx={{
							// bgcolor: "blue",
							width: "70%",
							minWidth: "150px",
							padding: "5px",
						}}
					>
						<h1
							style={{
								fontSize: "1.5em",
								display: "flex",
								marginLeft: "10px",
							}}
						>
							Personal Info
						</h1>
						<Divider />
						{loading ? (
							<LoaderDefault />
						) : (
							<Box
								sx={{
									display: "flex",
									// margin: "20px",
									flexWrap: "wrap",
									alignContent: "center",
								}}
							>
								<TextField
									id="inpname"
									placeholder="First Name"
									label="First Name"
									variant="outlined"
									InputLabelProps={{ shrink: true }}
									sx={{ margin: "25px" }}
									{...register("data.name", {
										required: true,
									})}
								/>
								<TextField
									id="inplname"
									label="Last Name"
									variant="outlined"
									sx={{ margin: "25px" }}
									InputLabelProps={{ shrink: true }}
									{...register("data.lastName", {
										required: false,
									})}
								/>
								<TextField
									id="inpemail"
									label="Email"
									variant="outlined"
									sx={{ margin: "25px" }}
									disabled
									InputLabelProps={{ shrink: true }}
									{...register("data.email")}
								/>
								<TextField
									id="inpphone"
									label="Phone"
									variant="outlined"
									sx={{ margin: "25px" }}
									InputLabelProps={{ shrink: true }}
									{...register("data.phoneNumber", {
										required: false,
									})}
								/>
								<TextField
									id="inpadd1"
									label="Address 1"
									variant="outlined"
									sx={{ margin: "25px" }}
									InputLabelProps={{ shrink: true }}
									{...register("data.Address.address1", {
										required: false,
									})}
								/>
								<TextField
									id="inpadd2"
									label="Address2 (optional)"
									variant="outlined"
									sx={{ margin: "25px" }}
									InputLabelProps={{ shrink: true }}
									{...register("data.Address.address2", {
										required: false,
									})}
								/>
								<TextField
									id="inpcity"
									label="City"
									variant="outlined"
									sx={{ margin: "25px" }}
									InputLabelProps={{ shrink: true }}
									{...register("data.Address.city", {
										required: false,
									})}
								/>
								<TextField
									id="inpregieon"
									label="State/Region"
									variant="outlined"
									sx={{ margin: "25px" }}
									InputLabelProps={{ shrink: true }}
									{...register("data.Address.state", {
										required: false,
									})}
								/>
								<TextField
									id="inpzip"
									label="Zip/Postal Code"
									variant="outlined"
									sx={{ margin: "25px" }}
									InputLabelProps={{ shrink: true }}
									type="number"
									{...register("data.Address.pincode", {
										required: false,
									})}
								/>
								<TextField
									id="inpcountry"
									label="Country"
									variant="outlined"
									sx={{ margin: "25px" }}
									InputLabelProps={{ shrink: true }}
									{...register("data.Address.country", {
										required: false,
									})}
								/>

								<TextField
									id="inpdes"
									label="Short Description"
									multiline
									rows={3}
									variant="outlined"
									fullWidth
									InputLabelProps={{ shrink: true }}
									sx={{ m: "25px", width: "75%" }}
									{...register("data.description.shortDescription", {
										required: false,
									})}
								/>
								<TextField
									id="outlined-multiline-flexible"
									label="Long Description"
									multiline
									rows={5}
									variant="outlined"
									fullWidth
									InputLabelProps={{ shrink: true }}
									sx={{ m: "25px", width: "75%" }}
									{...register("data.description.longDescription", {
										required: false,
									})}
								/>
							</Box>
						)}
						<Button variant="contained" onClick={handleUpdate}>
							Update
						</Button>
					</Box>
				</Box>
			</center>
		</div>
	);
}

export default InstructorProfile;
