import {
	Card,
	CardActionArea,
	CardContent,
	Divider,
	Typography,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Box } from "@mui/system";

function ReveiwCard({ name, review, maxWidth }) {
	return (
		<div>
			<Card
				sx={{
					maxWidth: { maxWidth },
					maxHeight: 200,
					overflowY: "auto",
					m: 5,
				}}
			>
				<CardActionArea>
					<CardContent>
						<Box
							sx={{
								display: "flex",
								flexWrap: "wrap",
								mb: 1,
							}}
						>
							<AccountCircleIcon />
							<Typography
								variant="h7"
								color="text.primary"
								ml={2}
							>
								{name}
							</Typography>
						</Box>

						<Divider />
						<Typography variant="body2" color="text.secondary">
							{review}
						</Typography>
					</CardContent>
				</CardActionArea>
			</Card>
		</div>
	);
}

export default ReveiwCard;
