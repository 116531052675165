import {
	Button,
	Chip,
	Dialog,
	DialogTitle,
	useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import Vimeo from "@u-wave/react-vimeo";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

//TODO: IMPORTENT fixed bug:double content and same video
function CoursePreviewModal({
	open,
	handleClose,
	selectedIndex,
	section,
	selectedSection,
	id,
}) {
	const data = (section.lectures && section.lectures[selectedIndex]) || {};
	const matches = useMediaQuery("(min-width:600px)");
	const convertTextToLink = (params) => {
		try {
			var urlRegex = /(https?:\/\/[^\s]+)/g;
			const textlist = params.split(urlRegex);

			return (
				<pre
					style={{
						textAlign: "justify",
						padding: "2.5em",

						whiteSpace: "pre-wrap",
						fontSize: "16px",
						fontWeight: "300",
						lineHeight: "26px",
					}}
				>
					{textlist.map((e) => {
						if (e.startsWith("http")) {
							return (
								<a
									key={e}
									href={e}
									target="_blank"
									rel="noreferrer"
								>
									{e}
								</a>
							);
						} else {
							return e;
						}
					})}
				</pre>
			);
		} catch (error) {
			return params;
		}
	};
	if (matches) {
		return (
			<Dialog
				open={open && selectedSection === id}
				fullWidth="true"
				maxWidth="xl"
			>
				<Button
					sx={{
						position: "absolute",
						right: 5,
						top: 10,
					}}
					onClick={handleClose}
				>
					<CloseIcon />
				</Button>

				<Box sx={{ width: "100%", mb: 5 }}>
					<Chip label="Preview" sx={{ mt: 3, ml: 2 }} />
					<DialogTitle>{data.title}</DialogTitle>
					<Box sx={{ mx: "auto", width: "100%" }}>
						<center>
							{data.catergory && data.catergory === "DV" ? (
								<Vimeo video={data.contentURL} width="600px" />
							) : (
								<></>
							)}
							{data.catergory && data.catergory === "DI" ? (
								<img
									src={data.contentURL}
									width={600}
									alt="contentPreviewSeakhost"
								/>
							) : (
								<></>
							)}
							{data.catergory && data.catergory === "DP" ? (
								<iframe
									title={data.title || "title"}
									src={data.contentURL}
									height="500px"
									width={"100%"}
								/>
							) : (
								<></>
							)}
							{/* {//TODO: add draft js editor} */}
							{convertTextToLink(data.description)}
						</center>
					</Box>
				</Box>
			</Dialog>
		);
	} else {
		return (
			<div>
				<center>
					<Box>
						<Dialog
							open={open && selectedSection === id}
							maxWidth="xl"
						>
							<Button
								sx={{
									position: "absolute",
									right: 5,
									top: 10,
								}}
								onClick={handleClose}
							>
								<CloseIcon />
							</Button>

							<Box sx={{ width: "100%", mb: 1 }}>
								<Chip label="Preview" sx={{ mt: 3, ml: 2 }} />
								<DialogTitle>
									Introduction to Python
								</DialogTitle>
								<center>
									<Box
										sx={{
											mt: 0,
											overflow: "auto",
											maxWidth: "300px",
										}}
									>
										<center>
											{data.catergory &&
											data.catergory === "DV" ? (
												<Vimeo
													video={data.contentURL}
													width={300}
												/>
											) : (
												<></>
											)}
											{data.catergory &&
											data.catergory === "DI" ? (
												<img
													src={data.contentURL}
													width={300}
													alt="contentPreviewSeakhost"
												/>
											) : (
												<></>
											)}
											{data.catergory &&
											data.catergory === "DP" ? (
												<iframe
													title={
														data.title || "title"
													}
													src={data.contentURL}
													height="500px"
													width={"100%"}
												/>
											) : (
												<></>
											)}
											{/* {//TODO: add draft js editor} */}
											{convertTextToLink(
												data.description
											)}
										</center>
									</Box>
								</center>
							</Box>
						</Dialog>
					</Box>
				</center>
			</div>
		);
	}
}
export default CoursePreviewModal;
