import React from "react";
import RowComponentOne from "../components/AboutUs/RowComponentOne";
import "../styles/WebMiddle.css";
import image1 from "../assets/our-story.jpg";
import image2 from "../assets/clickdo-our-values.jpg";
import image3 from "../assets/clickdo-our-education.jpg";
import RowComponentSec from "../components/AboutUs/RowComponentSec";
import ImageSetMiddle from "../components/AboutUs/ImageSetMiddle";
import ServiceCategories from "../components/AboutUs/ServiceCategories";
// import AvatarSwiper from '../components/AboutUs/AvatarSwiper';
// import NewSlider from '../components/AboutUs/NewSlider';
import SliderSection from "../components/AboutUs/SliderSection";

function WebMiddle() {
	return (
		<div>
			<h1 className="page-title">About us</h1>
			<div className="page-lineup">
				<a href="#ourstory">OUR STORY</a>

				<a href="#ourvalues">OUR VALUES</a>
				<a href="#ouredu">OUR EDUCATIONS</a>
				<a href="#ourservice">OUR SERVICE</a>
			</div>
			<div className="grid">
				<RowComponentOne
					image={image1}
					bigHeader={"Our story"}
					subHeader={"It is a big vision that got us started"}
					description={
						"The SeekaHost University was started by ClickDo founder Fernando Raymond, a digital strategist and business visionary. After 5 years of running digital marketing agency ClickDo Ltd. it’s our time to give something back to the world and help the digitally inspired to get online and learn the proven digital marketing techniques that help businesses thrive online. All our courses are created by industry experts with years of experience in what they teach. By trialling & testing their techniques, they have built a repertoire that works exactly how it’s supposed to. They know how to get the desired results in the modern digital world and marketplace for businesses to be profitable and individuals to be successful. "
					}
					ID={"ourstory"}
				/>
				<RowComponentSec
					image={image2}
					bigHeader={"Our Values"}
					subHeader={
						"To help humanity live a better life doing  what they enjoy"
					}
					description={
						"We value freedom and believe people can do what they love while helping others and getting paid for it. SeekaHost University provides many free courses to help people get started online and embark on their digital journey and career. Ultimately, you’ll learn skills that will help you make money online while doing work you’ll enjoy from anywhere you choose to be. Our main focus is to help 100 million people to acquire the digital skills that will give them the opportunities and tools to work with other business owners online. We’ve lived it and are still living it every day as we are helping the digital economy grow!"
					}
				/>
				<RowComponentOne
					image={image3}
					bigHeader={"Our educations"}
					subHeader={"It is a big vision that got us started"}
					description={
						"The SeekaHost University was started by ClickDo founder Fernando Raymond, a digital strategist and business visionary. After 5 years of running digital marketing agency ClickDo Ltd. it’s our time to give something back to the world and help the digitally inspired to get online and learn the proven digital marketing techniques that help businesses thrive online. All our courses are created by industry experts with years of experience in what they teach. By trialling & testing their techniques, they have built a repertoire that works exactly how it’s supposed to. They know how to get the desired results in the modern digital world and marketplace for businesses to be profitable and individuals to be successful. "
					}
					ID="ouredu"
				/>
				<ImageSetMiddle />
				<ServiceCategories />
				<SliderSection />
			</div>
		</div>
	);
}

export default WebMiddle;
