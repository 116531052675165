import baseApi from "./@baseURL";

const addImage = async (id, formdata) => {
	try {
		const res = await baseApi.post("image/" + id, formdata);
		return res.data;
	} catch (error) {
		//TODO: add custom error

		console.log(error);
	}
};

export { addImage };
