import React from "react";
import SectionTypeOne from "./pagesComponents/SectionTypeOne";
import SectionFiveImg from "../../assets/images/sectionFive.png";

const SectionFive = () => {
	return (
		<div>
			<SectionTypeOne
				title="Share your knowledge"
				contentOne="Create a course for free in minutes with SeekaHost University"
				contentSecond="If you feel you’ve got what it takes to become an instructor and share your online expertise with our students, you can upload your recorded course onto our SeekaHost University platform. We will guide you through the whole process and make it very easy & quick. Once your course is up and running you can track and manage it – you’re in charge."
				imgSTT={SectionFiveImg}
				bgColor="#f5fafe"
				sectionType="five"
			/>
		</div>
	);
};

export default SectionFive;
