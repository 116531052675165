import React from 'react';
import './RowContentSec.css';
import textLayerBackImg from '../../assets/Layer-472-e1503482002519.png';

function RowContentSec({ bigHeader, subHeader, description }) {
  return (
    <div
      className="grid-row-content-sec"
      style={{
        background: `linear-gradient(
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    url(${textLayerBackImg})`,
        // backgroundImage: 'url(' + image + ')',
        // backgroundImage: `url(${textLayerBackImg})`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <div className="grid-row-innerContent-sec">
        <div className="contentBox-bigHeader-sec">{bigHeader}</div>
        <div className="contentBox-subHeader-sec">{subHeader}</div>
        <div className="contentBox-underline-sec"></div>
        <div className="contentBox-description-sec"> {description}</div>
      </div>
    </div>
  );
}

export default RowContentSec;
