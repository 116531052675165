import React, { useEffect, useState } from "react";
import useAuth from "../../util/state/authState";
import AccessDenied from "../AccessDenied";
import ConfirmAdmin from "../Admin/ConfirmAdmin";
function StudentPrivateRoute({ children }) {
	const { admin } = useAuth();
	const [isfirst, setisfirst] = useState(true);
	useEffect(() => {
		setisfirst(false);
	}, []);

	if (isfirst) {
		return <div style={{ height: "80vh" }}></div>;
	} else {
		if (admin && ["active", "inactive"].indexOf(admin.Accountstatus) >= 0) {
			return children;
		} else if (admin && admin.Accountstatus === "new") {
			return <ConfirmAdmin />;
		} else {
			return <AccessDenied />;
		}
	}
}

export default StudentPrivateRoute;
