import React from "react";
import "./RowComponentSec.css";
import RowContentSec from "./RowContentSec";

function RowComponentSec({ image, bigHeader, subHeader, description }) {
	return (
		<div className="grid-row-1 grid-row-3" id="ourvalues">
			<RowContentSec
				bigHeader={bigHeader}
				subHeader={subHeader}
				description={description}
			/>
			<div
				style={{
					// backgroundImage: 'url(' + image + ')',
					backgroundImage: `url(${image})`,
					backgroundPosition: "center center",
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
				}}
				className="one-grid-row-img"

				// `url(${image})`
			></div>
		</div>
	);
}

export default RowComponentSec;
