import React from 'react';
import textLayerBackImg from '../../assets/627.jpg';
import NewSlider from './NewSlider';
// import { Carousel, Container } from 'react-bootstrap';
// import image1 from '../../assets/Neil-Franklin-130x130.jpg';
// import image2 from '../../assets/Nickey-Pickorita-130x130.jpg';
// import image3 from '../../assets/Miguel-Espinoza-130x130.jpg';
import { Parallax, Background } from 'react-parallax';
import './SliderSection.css';

function SliderSection() {
  return (
    <Parallax strength={300}>
      <Background className="custom-bg">
        <div
          style={{
            height: 2000,
            width: 2000,
            background: `linear-gradient(
      rgba(1, 1, 1, 0.6),
      rgba(1, 1, 1, 0.6)
    ),
    url(${textLayerBackImg})`,
          }}
        />
      </Background>
      <NewSlider />
    </Parallax>
  );
}

export default SliderSection;
