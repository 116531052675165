import { Avatar, Divider, Rating } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Vimeo from "@u-wave/react-vimeo";
import { getCourseBySearchString } from "../../util/api/courses";
import { getSections } from "../../util/api/section";
import LoaderDefault from "../loaders/LoaderDefault";
// import NotFound from "../NotFound";
import CourseContentPreview from "./CourseContentPreview";
import useAuth from "../../util/state/authState";
//fixed bug:0020
// import { enrollForCourse } from "../../util/api/enrollment";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSnackbar } from "notistack";
import AdminCourseContentPreview from "../Admin/AdminCourseContent";
import RichTextViewer from "../TextFormater/RichTextViewer";
import { encode } from "../../util/base64Encorder";
import ReveiwCard from "../../components/ReveiwCard";
import { getApprovedReview } from "../../util/api/review"; //mock data

function CousePreview() {
	const { enqueueSnackbar } = useSnackbar();
	const { admin } = useAuth();
	//fixed bug:0020
	// const { user, admin } = useAuth();
	const params = useParams();
	const [course, setcourse] = useState();
	const [sections, setsections] = useState();
	const [loading, setloading] = useState(true);
	const [reviews, setreviews] = useState([]);

	const matches = useMediaQuery("(min-width:600px)");

	//fixed bug:0020

	// const enroll = async () => {
	// 	if (user && user.type === "student") {
	// 		setloading(true);
	// 		await enrollForCourse({
	// 			courseId: course._id,
	// 			studentId: user._id,
	// 		});
	// 		setloading(false);
	// 	}
	// };

	useEffect(() => {
		async function getCouseFun() {
			setloading(true);
			const res = await getCourseBySearchString(params.searchId);
			if (res.error)
				return enqueueSnackbar(res.error, {
					variant: "error",
					autoHideDuration: 6000,
				});
			setcourse(res);
			if (res._id) {
				const sec = await getSections(res._id);

				setsections(sec);
				const rew = await getApprovedReview(res._id);
				setreviews(rew);
				//setcourseContent(res.sections || []);
			}
			setloading(false);
		}
		if (params.searchId) {
			getCouseFun();
		}
	}, [params, enqueueSnackbar]);

	// useEffect(() => {
	// 	async function getData() {
	// 		setloading(true);
	// 		const res = await getApprovedReview(params.courseId);
	// 		setreviews(res);
	// 		setloading(false);
	// 	}
	// 	if (params.courseId) {
	// 		getData();
	// 	}
	// }, [params]);

	return loading ? (
		<LoaderDefault></LoaderDefault>
	) : course ? (
		matches ? (
			<div
				style={{
					backgroundColor: "#1d2b3a",
					paddingTop: "60px",
					paddingBottom: "60px",
					paddingLeft: "40px",
					paddingRight: "40px",
				}}
			>
				<center>
					<Box
						sx={{
							bgcolor: "white",
							maxWidth: "1000px",
							p: 3,
							borderRadius: 5,
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
								marginBottom: "20px",
								flexWrap: "wrap",
							}}
						>
							<Avatar
								src={
									course.instructor &&
									course.instructor.image &&
									course.instructor.image.type === "Buffer"
										? "data:image/png;base64," + encode(course.instructor.image.data).toString()
										: ""
								}
								alt={course.instructor && course.instructor.name}
								sx={{ width: 65, height: 65 }}
							></Avatar>

							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									marginLeft: "2em",
									flexWrap: "wrap",
								}}
							>
								<h5
									style={{
										color: "rgb(18,49,91)",
										fontFamily: "monospace",
										fontSize: "1em",
									}}
								>
									Instructor
								</h5>
								<h5
									style={{
										fontSize: "1em",
										fontWeight: "600",
										fontFamily: "monospace",
									}}
								>
									{" "}
									{course.instructor && course.instructor.name}
								</h5>
							</Box>
							<Divider orientation="vertical" variant="middle" flexItem sx={{ margin: "1em" }} />
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									flexWrap: "wrap",
								}}
							>
								<h5
									style={{
										color: "rgb(18,49,91)",
										fontFamily: "monospace",
										fontSize: "1em",
									}}
								>
									Student Registered
								</h5>
								<h5
									style={{
										fontSize: "1em",
										fontWeight: "600",
									}}
								>
									{course.studentCount}
								</h5>
							</Box>
						</Box>
						<Box
							sx={{
								p: 0,
							}}
						>
							{/* fixed bug:0025 */}
							<Vimeo video={course.introVideo} width="550px" />
						</Box>
						<Divider variant="middle" flexItem sx={{ margin: "1em" }} />
						<Rating
							name="read-only"
							precision={0.5}
							value={course.rating && course.rating.ratingFinal}
							readOnly
						/>
						<h1
							style={{
								fontFamily: "sans-serif",
								color: "rgb(18,49,91)",
								fontSize: "3em",
								fontWeight: "550",
							}}
						>
							{course.title}
						</h1>
						<div style={{ padding: "25px" }}>
							<a
								href={"https://portal.seekahost.app/university/course-overview?id=" + course._id}
								target={"_blank"}
								className="custom-button-v1"
								rel="noreferrer"
								style={{ margin: "25px" }}
							>
								Enroll Now
							</a>
						</div>
						{/* <Button
							// disabled={!user || user.type !== "student"}
							// onClick={enroll}
							target={"_blank"}
							rel="noreferrer"
							href={"https://portal.seekahost.app/university/course-overview?id=" + course._id}
							variant="contained"
							size="large"
							// color="error"
							sx={{ margin: "25px" }}
						>
							Enroll Now
						</Button> */}
						<p
							style={{
								fontSize: "1.5em",
								fontWeight: "500",
							}}
						>
							<RichTextViewer rawStringData={course.description} />
						</p>
						<Box
							sx={{
								bgcolor: "#1d2b3a",
								color: "white",
								width: "100%",
								borderRadius: "5px",
								paddingTop: "10px",
							}}
						>
							<h4
								style={{
									marginBottom: "10px",
									fontWeight: "500",
									fontSize: "20px",
									lineHeight: "2.5em",
								}}
							>
								Course Outline
							</h4>
							<Divider />
							{admin ? (
								<AdminCourseContentPreview sections={sections} />
							) : (
								<CourseContentPreview sections={sections} />
							)}
						</Box>
						{reviews.length ? (
							<Box>
								<div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
									<div className="carousel-inner">
										<div className="carousel-item active">
											<ReveiwCard
												name={
													reviews[reviews.length - 1].createdBy &&
													reviews[reviews.length - 1].createdBy.name
												}
												review={reviews[reviews.length - 1].review}
												maxWidth={600}
											/>
										</div>
										{reviews.map((rev) => (
											<div className="carousel-item">
												<ReveiwCard
													name={rev.createdBy && rev.createdBy.name}
													review={rev.review}
													maxWidth={600}
												/>
											</div>
										))}
									</div>

									<a
										className="carousel-control-prev"
										href="#carouselExampleControls"
										role="button"
										data-slide="prev"
									>
										<span className="carousel-control-prev-icon" aria-hidden="true"></span>
										<span className="sr-only">Previous</span>
									</a>
									<a
										className="carousel-control-next"
										href="#carouselExampleControls"
										role="button"
										data-slide="next"
									>
										<span className="carousel-control-next-icon" aria-hidden="true"></span>
										<span className="sr-only">Next</span>
									</a>
								</div>
							</Box>
						) : (
							<></>
						)}
					</Box>
				</center>
			</div>
		) : (
			<div style={{ backgroundColor: "#1d2b3a", padding: "30px" }}>
				<center>
					<Box sx={{ bgcolor: "white", maxWidth: "1000px", p: 1 }}>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								flexWrap: "wrap",
							}}
						>
							<Box>
								<Avatar
									src={course.instructor && course.instructor.image}
									alt={course.instructor && course.instructor.name}
									sx={{ width: 65, height: 65 }}
								></Avatar>
							</Box>

							<Box sx={{ mt: 2 }}>
								<h4
									style={{
										color: "rgb(18,49,91)",
										fontFamily: "monospace",
										fontSize: "1em",
									}}
								>
									Instructor
								</h4>
								<h4
									style={{
										fontSize: "1em",
										fontWeight: "700",
									}}
								>
									{" "}
									{course.instructor && course.instructor.name}
								</h4>
							</Box>
							<Box sx={{ mt: 2 }}>
								<h4
									style={{
										color: "rgb(18,49,91)",
										fontFamily: "monospace",
										fontSize: "1em",
									}}
								>
									Student Registered
								</h4>
								<h4
									style={{
										fontSize: "1em",
										fontWeight: "700",
									}}
								>
									{course.studentCount}
								</h4>
							</Box>
						</Box>
						<Box
							sx={{
								p: 1,
								display: "flex",
								flexWrap: "wrap",
								justifyContent: "center",
							}}
						>
							<Vimeo video={course.introVideo} width={280} height={216} />
						</Box>
						<Divider variant="middle" flexItem sx={{ margin: "1em" }} />
						<Rating name="read-only" value={course.rating && course.rating.ratingFinal} readOnly />
						<h1
							style={{
								fontFamily: "sans-serif",
								color: "rgb(18,49,91)",
								fontSize: "1.5em",
								fontWeight: "550",
							}}
						>
							{course.title}
						</h1>
						<div style={{ padding: "25px" }}>
							<a
								href={"https://portal.seekahost.app/university/course-overview?id=" + course._id}
								target={"_blank"}
								rel="noreferrer"
								className="custom-button-v1"
								style={{ margin: "25px" }}
							>
								Enroll Now
							</a>
						</div>
						{/* <Button
							// disabled={!user || user.type !== "student"}
							// onClick={enroll}
							target={"_blank"}
							rel="noreferrer"
							href={"https://portal.seekahost.app/university/course-overview?id=" + course._id}
							variant="contained"
							size="medium"
							sx={{ margin: "22px" }}
						>
							Enroll Now
						</Button> */}

						<p
							style={{
								fontSize: "1em",
								fontWeight: "500",
							}}
						>
							<RichTextViewer rawStringData={course.description} />
						</p>

						<Box
							sx={{
								bgcolor: "#1d2b3a",
								color: "white",
								width: "100%",
								borderRadius: "5px 5px 0px 0px",
								paddingTop: "10px",
							}}
						>
							<h4
								style={{
									fontWeight: "500",
									fontSize: "15px",
									lineHeight: "2em",
								}}
							>
								Course Outline
							</h4>
							<Divider />
						</Box>
						<Box>
							{admin ? (
								<AdminCourseContentPreview sections={sections} />
							) : (
								<CourseContentPreview sections={sections} />
							)}
						</Box>
						{reviews.length ? (
							<Box>
								<div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
									<div className="carousel-inner">
										<div className="carousel-item active">
											<ReveiwCard
												name={
													reviews[reviews.length - 1].createdBy &&
													reviews[reviews.length - 1].createdBy.name
												}
												review={reviews[reviews.length - 1].review}
												maxWidth={600}
											/>
										</div>
										{reviews.map((rev) => (
											<div className="carousel-item">
												<ReveiwCard
													name={rev.createdBy && rev.createdBy.name}
													review={rev.review}
													maxWidth={600}
												/>
											</div>
										))}
									</div>

									<a
										className="carousel-control-prev"
										href="#carouselExampleControls"
										role="button"
										data-slide="prev"
									>
										<span className="carousel-control-prev-icon" aria-hidden="true"></span>
										<span className="sr-only">Previous</span>
									</a>
									<a
										className="carousel-control-next"
										href="#carouselExampleControls"
										role="button"
										data-slide="next"
									>
										<span className="carousel-control-next-icon" aria-hidden="true"></span>
										<span className="sr-only">Next</span>
									</a>
								</div>
							</Box>
						) : (
							<></>
						)}
					</Box>
				</center>
			</div>
		)
	) : (
		<div>
			<></>
		</div>
	);
}

export default CousePreview;
