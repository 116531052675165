import React from 'react';
import './RowContentOne.css';
import textLayerBackImg from '../../assets/Layer-472-e1503482002519.png';

function RowContentOne({ bigHeader, subHeader, description }) {
  return (
    <div
      className="grid-row-content"
      style={{
        background: `linear-gradient(
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    url(${textLayerBackImg})`,
        // backgroundImage: 'url(' + image + ')',
        // backgroundImage: `url(${textLayerBackImg})`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <div className="grid-row-innerContent">
        <div className="contentBox-bigHeader">{bigHeader}</div>
        <div className="contentBox-subHeader">{subHeader}</div>
        <div className="contentBox-underline"></div>
        <div className="contentBox-description"> {description}</div>
      </div>
    </div>
  );
}

export default RowContentOne;
