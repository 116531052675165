import { Button, Card, CardActions, CardContent, CardMedia, Typography } from "@mui/material";

import { Link } from "react-router-dom";
import { encode } from "../../util/base64Encorder";
import { makeStyles } from "@mui/styles";

//TODO: start button to bottom
function CourseCard({ student_count, fees, instructor, course_name, img_url, id, data, searchId }) {
	const getImage = (image) => {
		if (image) {
			if (image.type === "Buffer") {
				return "data:image/png;base64," + encode(img_url.data).toString();
			} else if (typeof image === "string") {
				return img_url;
			} else {
				return "";
			}
		} else {
			return "";
		}
	};
	const useStyles = makeStyles({
		Card: {
			borderRadius: "5px",
		},
	});
	const classes = useStyles();

	return (
		<div>
			<Card
				sx={{
					width: "240px",
					height: "300px",
					marginBottom: "30px",
					marginLeft: "30px",
					marginTop: "30px",
					position: "relative",
				}}
				classes={{ root: classes.Card }}
			>
				<CardMedia
					alt="course"
					component="img"
					// height="100px"
					style={{ height: "120px", width: "100%" }}
					// width="15em"
					image={getImage(img_url)}
				/>
				<CardContent>
					{/* <Badge
						sx={{ position: "absolute", top: "1em", right: "2em" }}
						badgeContent={fees}
						color="warning"
					></Badge> */}
					<Typography gutterBottom variant="h6" component="div">
						{course_name}
					</Typography>
					<Typography variant="body2" color="text.secondary" component="div">
						{instructor}
					</Typography>
					{student_count && <Typography variant="body2">{student_count + " "}Students</Typography>}
				</CardContent>
				<div style={{ height: "100%" }}></div>
				<CardActions style={{ position: "sticky", bottom: "5px" }}>
					<Link to={"/courses/" + searchId} style={{ textDecoration: "none" }}>
						<Button variant="contained" color="primary" size="small">
							Start
						</Button>
					</Link>
				</CardActions>
			</Card>
		</div>
	);
}

export default CourseCard;
