import React from "react";
import SectionOneImgOne from "../../assets/images/sectionOneImgOne.png";
import SectionOneImgSecond from "../../assets/images/SectionOneImgSecond.png";
import ButtonTypeOne from "./pagesComponents/ButtonTypeOne";

const SectionOne = () => {
	return (
		<>
			<section className="header">
				<div className="gradient"></div>

				<div className="image-layer-1">
					<img src={SectionOneImgOne} alt="" />
				</div>

				<div className="corner">
					<img src={SectionOneImgSecond} alt="" />
				</div>

				<div className="explore-box">
					<div className="cent-text">
						<span className="explore-heading">Explore your</span>
						<span className="creative-text">Creativity</span>

						<p className="explore-text">
							Gain the online and digital skills you need, to live a life you love and deserve!
						</p>

						<ButtonTypeOne name="Learn Online" bgColor="#fecb00" btnCount="one" href="/courses" />
					</div>
				</div>
			</section>
		</>
	);
};

export default SectionOne;
