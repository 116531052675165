import baseApi from "./@baseURL";

/*
Error response decording
	in catch block->
		error.response.message - gives the message about error from server
		error.response.status - gives the satutus code of error


*/

//get approved courses

const getApprovedCourses = async () => {
	/*
sample response item
{
		approve: false
	category: "java"
	description: "des 1"
	rating: 0
	reviews: ['617fa3e021b68c8c81a02665']
	sections: (4) ['617cef01a7e700d5ed90dc23', '617cef0ca7e700d5ed90dc27', '617cef12a7e700d5ed90dc2b', '617cef19a7e700d5ed90dc2f']
	title: "course 1"
	__v: 9
	_id: "617ceddca7e700d5ed90dc1a"}
*/
	//TODO: filter aproved couse

	try {
		const res = await baseApi.get("course/approved");
		return res.data;
	} catch (error) {
		//TODO:show custom error message
		return { error: "Unkown Error Occured!" };
		//console.log(error.response.status);
	}
};
const getAdminApprovedCourses = async () => {
	try {
		const res = await baseApi.post("course/admin/approved");
		return res.data;
	} catch (error) {
		//TODO:show custom error message
		return { error: "Unkown Error Occured!" };
		//console.log(error.response.status);
	}
};

const getCourse = async (id, showAll = { showAll: false }) => {
	try {
		const res = await baseApi.get("course/" + id, showAll);
		return res.data;
	} catch (error) {
		if (error.response.status === 404) {
			return { error: "Not Found!" };
		} else {
			return { error: "Unknown Error Occured!" };
		}
	}
};
const getCourseBySearchString = async (id, showAll = { showAll: false }) => {
	try {
		const res = await baseApi.get("course/get/" + id, showAll);
		return res.data;
	} catch (error) {
		if (error.response.status === 404) {
			return { error: "Not Found!" };
		} else {
			return { error: "Unknown Error Occured!" };
		}
	}
};

const getNotApprovedCourse = async () => {
	try {
		const res = await baseApi.get("course/all/notApproved");
		return res.data;
	} catch (error) {
		return { error: "Unknown Error Occured!" };
	}
};

const approveCourse = async (id) => {
	try {
		const res = await baseApi.patch("course/approve/" + id);
		return res.data;
	} catch (error) {
		//TODO:show custom error message
		console.log(error.response);
	}
};

const deleteCourse = async (id) => {
	try {
		const res = await baseApi.delete("course/" + id);
		return res.data;
	} catch (error) {
		//TODO:show custom error message
		console.log(error.response);
	}
};
const admindeleteCourse = async (id) => {
	try {
		const res = await baseApi.delete("course/admin/" + id);
		return res.data;
	} catch (error) {
		//TODO:show custom error message
		console.log(error.response);
	}
};

const updateToDraft = async (id) => {
	try {
		const res = await baseApi.patch("course/draft/" + id);
		return res.data;
	} catch (error) {
		//TODO:show custom error message
		console.log(error.response);
	}
};
const updateToPending = async (id) => {
	try {
		const res = await baseApi.patch("course/pending/" + id);
		return res.data;
	} catch (error) {
		//TODO:show custom error message
		console.log(error.response);
	}
};

//fixed bug:0015
const addCourse = async (courseContent) => {
	try {
		const res = await baseApi.post("course/", courseContent);
		return res.data;
	} catch (error) {
		return { error: "Unable to add the Course." };
	}
};
const updateCourse = async (courseContent, id) => {
	try {
		const res = await baseApi.patch("course/" + id, courseContent);
		return res.data;
	} catch (error) {
		//TODO:show custom error message
		console.log(error.response);
	}
};
const getHomelimitedCourses = async () => {
	try {
		const res = await baseApi.get("course/home/limited");
		return res.data;
	} catch (error) {
		//TODO:show custom error message
		console.log(error.response);
	}
};

//===============EXPORTS============================

export {
	getApprovedCourses,
	getCourse,
	getNotApprovedCourse,
	approveCourse,
	deleteCourse,
	admindeleteCourse,
	updateToDraft,
	updateToPending,
	addCourse,
	updateCourse,
	getAdminApprovedCourses,
	getHomelimitedCourses,
	getCourseBySearchString,
};
