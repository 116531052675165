import baseApi from "./@baseURL";

const getNotApprovedReviews = async () => {
	try {
		const res = await baseApi.get("/review/notApproved");
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};

const rejectReview = async (id) => {
	try {
		const res = await baseApi.delete("/review/" + id);
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};

const approveReview = async (id) => {
	try {
		const res = await baseApi.patch("/review/approve/" + id);
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};
const getApprovedReview = async (id) => {
	try {
		const res = await baseApi.get("/review/approved/" + id);
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};

export { getNotApprovedReviews, rejectReview, approveReview, getApprovedReview };
