import baseApi from "./@baseURL";


const addSection = async (Courseid,body) => {
	try {
		const res = await baseApi.post("section/"+Courseid,body);
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};
const getSections = async (Courseid) => {
	try {
		const res = await baseApi.get("section/all/"+Courseid);
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
}
const updateSection = async (Courseid,body) => {
	try {
		const res = await baseApi.patch("section/"+Courseid,body);
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};
const deleteSection = async (Courseid) => {
	try {
		const res = await baseApi.delete("section/"+Courseid);
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};





export {addSection,getSections,updateSection,deleteSection}