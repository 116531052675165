import React from "react";

function NotFound() {
	return (
		<div
			style={{
				backgroundColor: "#0f171e",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				fontSize: "1.5em",
				color: "white",
				fontFamily: "Open Sans",
				fontWeight: "lighter",
			}}
		>
			<h1>404 Page</h1>
			<h1>PAGE NOT FOUND!</h1>
			<h2>
				Sorry, we can't find the page you are looking for. Please go to{" "}
				<a href="/" style={{ color: "white" }}>
					Home
				</a>
			</h2>
		</div>
	);
}

export default NotFound;
