import React from "react";
import SectionTypeOne from "./pagesComponents/SectionTypeOne";
import SectionSixImg from "../../assets/images/sectionSix.png";
import SectionSixLeftImg from "../../assets/images/sectionSixLeft.png";

const SectionSix = () => {
	return (
		<div>
			<SectionTypeOne
				title="Learn from Anywhere"
				contentOne="Join thousands of people learning online every day"
				contentSecond="Welcome to the most comprehensive online digital marketing & learning training platform in the world. SeekaHost University online educational courses are designed to give the world access to the skills of the future for working online. Your teachers and mentors all have a track record of successful online businesses or online work experience and earn a living working online remotely.

				This digital marketing & learning institute approves all of the courses and the founder, Fernando, has a wealth of knowledge and expertise building and successfully growing online businesses.
				
				"
				contentThird="We’ve taken the initiative to provide selected free courses so anyone from anywhere in the world with an internet connection and mobile device can access our digital marketing & learning courses and study online in their own time.
				
				Our goal is to help 100 million people to learn vital digital skills and join the digital economy, earning a living doing what they enjoy from wherever they choose to.
				
				Freedom is at the core of our eLearning platform, and we thrive on bringing in the best educators to create compelling courses that can easily provide the skill sets to newbies to become advanced, digital marketers and experts.
				
				The future is digital – don’t remain a Neanderthal!"
				imgSTT={SectionSixImg}
				bgColor="#fff"
				sectionType="six"
				imgLeft={SectionSixLeftImg}
			/>
		</div>
	);
};

export default SectionSix;
