import React, { useState, useEffect } from "react";
import { Button, Box, ListItemText, ListItemButton, Divider, Stack, TextField, Fab, Tooltip } from "@mui/material";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import Zoom from "@mui/material/Zoom";

import { useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { getCourse, updateCourse } from "../util/api/courses";
import { addSection, getSections, deleteSection } from "../util/api/section";
import { addImage } from "../util/api/image";
import useAuth from "../util/state/authState";

import LoaderDefault from "../components/loaders/LoaderDefault";
import Error from "../components/Error";
import EdditSectionModal from "../components/modals/EdditSectionModal";
import LectureEditor from "../components/Couses/LectureEditor";
import RichTextEditor from "../components/TextFormater/RichTextEditor";

function CourseEditor() {
	const params = useParams();
	const { user } = useAuth();
	//========form
	const { register, setValue, handleSubmit } = useForm();
	const coursestring = "pageData";
	const onSubmit = async (data, e) => {
		setloading(true);
		await updateCourse({ ...data.pageData, description }, params.courseId);
		setloading(false);
	};
	const onError = (data, e) => {
		console.log(data);
	};

	const [sections, setsections] = useState([]);
	const [selectedSection, setselectedSection] = useState(0);

	//============Loading state
	const [loading, setloading] = useState(true);
	const [pageError, setpageError] = useState(false);
	const [isUiUpdated, setisUiUpdated] = useState(false);
	const [addSectionBtnLoader, setaddSectionBtnLoader] = useState(false);
	const [imageloading, setimageloading] = useState(false);
	const [addSectionViewLoader, setaddSectionViewLoader] = useState(false);
	const [sectionviewUpdated, setsectionviewUpdated] = useState(false);
	const [courseQuiz, setcourseQuiz] = useState("");
	const [description, setdescription] = useState("");

	//==================modals
	const [openSectionEditor, setopenSectionEditor] = useState(false);
	const handleSectioneditorClose = () => {
		setopenSectionEditor(false);
	};
	const handleSectioneditorOpen = () => {
		setopenSectionEditor(true);
	};

	const handleUpload = async (e) => {
		const file = e.target.files[0];

		if (file.size > 1000000) return alert("Maximum supported size is 1MB");
		if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
			try {
				const formData = new FormData();
				formData.append("image", file);
				formData.append("category", "thumbnail");
				formData.append("courseId", params.courseId);
				setimageloading(true);
				await addImage(user._id, formData);
			} catch (error) {
				//TODO:show error
				console.log("Faild");
			}
			setimageloading(false);
		} else {
			//TODO:show error
			console.log("no file selected");
		}
	};

	useEffect(() => {
		async function getData() {
			setsectionviewUpdated(false);
			setaddSectionViewLoader(true);
			const res = await getSections(params.courseId);
			if (!res) {
				return setpageError(true);
			}
			setsections(res);
			setaddSectionViewLoader(false);
		}
		getData();
	}, [params, sectionviewUpdated]);

	useEffect(() => {
		async function getCouseFun() {
			setloading(true);
			const res = await getCourse(params.courseId);
			if (res.error) {
				return setpageError(true);
			}

			setValue(coursestring, {
				title: res.title,
				category: res.category,
				introVideo: res.introVideo,
			});
			setdescription(res.description);
			setcourseQuiz(res.evaluation);
			setloading(false);
			setisUiUpdated(false);
		}
		getCouseFun();
	}, [params, setValue, isUiUpdated]);

	return pageError ? (
		<Error></Error>
	) : loading ? (
		<Box sx={{ width: "100%", padding: "1em" }}>
			<center>
				<LoaderDefault></LoaderDefault>
			</center>
		</Box>
	) : (
		<>
			<EdditSectionModal
				open={openSectionEditor}
				handler={handleSectioneditorClose}
				value={selectedSection}
				sections={sections}
				sectionViewUpdater={setsectionviewUpdated}
			></EdditSectionModal>
			<div
				style={{
					padding: "20px",
					backgroundColor: "#1d2b3a",
					position: "relative",
				}}
			>
				<center>
					<Box
						sx={{
							maxWidth: "1008px",
							display: "flex",
						}}
					>
						<Box
							sx={{
								backgroundColor: "#357be6",
								width: "150px",

								borderRadius: "7px 7px 0px 0px",
							}}
						>
							<h1
								style={{
									fontSize: "18px",
									fontFamily: "sans-serif",
									color: "white",
									fontWeight: "300",
								}}
							>
								Course Details
							</h1>
						</Box>
					</Box>

					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							boxShadow: 3,
							bgcolor: "white",
							maxWidth: "1008px",
						}}
					>
						<Stack spacing={2}>
							<form onSubmit={handleSubmit(onSubmit, onError)}>
								<Stack>
									<TextField
										sx={{ margin: "1em" }}
										label="Title"
										variant="filled"
										type="text"
										required
										{...register("pageData.title")}
									/>
									<TextField
										sx={{ margin: "1em" }}
										label="Category"
										variant="filled"
										type="text"
										required
										{...register("pageData.category")}
									/>
									<TextField
										sx={{ margin: "1em" }}
										label="Introduction Video url"
										variant="filled"
										type="text"
										{...register("pageData.introVideo")}
									/>
									<Button variant="outlined" component="label" disabled={imageloading}>
										Upload Cource Thumbnail
										<input
											type="file"
											hidden
											accept="image/png,image/jpeg"
											onChange={handleUpload}
										/>
									</Button>
									<RichTextEditor setouput={setdescription} initial={description} />
									<Button type="submit" style={{ marginBottom: "10px" }}>
										save
									</Button>
								</Stack>
							</form>
							<Box>
								{courseQuiz === "none" ? (
									<Link to={"/quizeditor/" + params.courseId}>
										<Button sx={{ margin: "1em" }} variant="outlined" color="success">
											Add a Quiz
										</Button>
									</Link>
								) : (
									<>
										<Link to={"/quizeditor/" + params.courseId}>
											<Button sx={{ margin: "1em" }} variant="outlined" color="secondary">
												Edit Quiz
											</Button>
										</Link>
									</>
								)}
							</Box>
						</Stack>
					</Box>

					<br />
					<Box
						sx={{
							maxWidth: "1008px",
							display: "flex",
						}}
					>
						<Box
							sx={{
								backgroundColor: "#357be6",
								width: "150px",

								borderRadius: "7px 7px 0px 0px",
							}}
						>
							<h1
								style={{
									fontSize: "18px",
									fontFamily: "sans-serif",
									color: "white",
									fontWeight: "300",
								}}
							>
								Course Content
							</h1>
						</Box>
					</Box>

					<Box
						sx={{
							boxShadow: 3,
							bgcolor: "white",
							maxWidth: "1008px",

							// margin: "5em",
						}}
					>
						{addSectionViewLoader ? (
							<LoaderDefault />
						) : sections.length === 0 ? (
							<Box>Add a section</Box>
						) : (
							<Box>
								{sections.map((section, index) => (
									<Box key={index}>
										<ListItemButton
											sx={{ bgcolor: "#dce1e3" }}
											variant="primary"
											key={index}
											onClick={() => setselectedSection(index)}
										>
											<ListItemText
												primary={section.title}
												primaryTypographyProps={{
													fontSize: 18,
													fontWeight: "bold",
													lineHeight: "22px",
													mb: "2px",
												}}
												sx={{ my: 0 }}
											/>
											<Button onClick={handleSectioneditorOpen}>
												<Tooltip title="Edit Section">
													<EditIcon />
												</Tooltip>
											</Button>
											<Button
												onClick={async () => {
													setaddSectionViewLoader(true);
													await deleteSection(section._id);
													setaddSectionViewLoader(false);
													setsectionviewUpdated(true);
												}}
											>
												<Tooltip title="Delete Section" TransitionComponent={Zoom}>
													<DeleteIcon />
												</Tooltip>
											</Button>
											<KeyboardArrowDown
												sx={{
													mr: -1,

													transform:
														selectedSection === index ? "rotate(-180deg)" : "rotate(0)",
													transition: "0.2s",
												}}
											/>
										</ListItemButton>

										{selectedSection === index ? (
											<div>
												<LectureEditor
													sectionID={section._id}
													courseId={params.courseId}
												></LectureEditor>
											</div>
										) : (
											<></>
										)}

										<Divider variant="middle" />
									</Box>
								))}
							</Box>
						)}
						<Fab
							sx={{
								position: "absolute",
								bottom: "10px",
								left: "200px",
								size: "small",
							}}
							disabled={addSectionBtnLoader}
							onClick={async () => {
								setaddSectionViewLoader(true);
								setaddSectionBtnLoader(true);
								await addSection(params.courseId, {
									title: "Section " + (sections.length + 1),
									sectionIndex: sections.length + 1,
								});
								setaddSectionBtnLoader(false);

								setsectionviewUpdated(true);
							}}
						>
							<Tooltip title="Add section" placement="top">
								<AddIcon />
							</Tooltip>
						</Fab>
					</Box>
				</center>
			</div>
		</>
	);
}

export default CourseEditor;
