import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button, Collapse, List, ListItemButton, ListItemIcon, ListItemText, Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { getLectures } from "../../../util/api/lecture";
import LoaderDefault from "../../loaders/LoaderDefault";

export default function CourseSection({
	_id,
	section_title,
	current_section,
	current_subsection,
	section_id,
	setLecture,
}) {
	const [open, setOpen] = useState(false);
	const [subsections, setsubsections] = useState([]);
	const [loading, setloading] = useState(false);

	const handleclick = () => {
		setOpen(true);
	};
	useEffect(() => {
		async function getData() {
			setloading(true);
			const res = await getLectures(_id);
			if (res && Array.isArray(res)) {
				setsubsections(res);
			}
			setloading(false);
		}
		getData();
	}, [_id]);
	return loading ? (
		<LoaderDefault />
	) : (
		<div>
			<center>
				<Box
					sx={{
						width: "100%",
						display: "flex",
						// bgcolor: "yellow",
						padding: "10px",
						marginBottom: "1em",
						borderRadius: "10px",
						boxShadow: "-1px 0px 11px 0px #888888",
						flexWrap: "wrap",
					}}
				>
					<Box
						sx={{
							width: "50px",
							height: "50px",
							color: "#1e85e6",
							alignContent: "center",
							justifyContent: "center",
						}}
					>
						<Box sx={{ bgcolor: "white", zIndex: "5", borderRadius: "50%" }}>
							{section_id < current_section ? (
								<CheckCircleIcon
									sx={{
										height: "40px",
										width: "40px",
										marginTop: "-5px",
									}}
								/>
							) : (
								<RadioButtonUncheckedIcon
									sx={{
										height: "40px",
										width: "40px",
										marginTop: "-5px",
									}}
								/>
							)}
						</Box>
					</Box>
					<Box
						sx={{
							width: "240px",
							height: "50px",
							// ml: "5px",
							display: "flex",
							alignContent: "center",
							justifyContent: "center",
						}}
					>
						<Button
							variant="contained"
							size="small"
							sx={{
								width: "230px",
								overflow: "clip",
							}}
							onClick={handleclick}
						>
							{section_title}
						</Button>
					</Box>

					<Box
						sx={{
							marginLeft: "-6px",
						}}
					>
						<Collapse in={open}>
							<List>
								{subsections.map((section, index) => (
									<ListItemButton
										key={index}
										sx={{ width: "100%" }}
										onClick={() => setLecture(section)}
									>
										{
											<ListItemIcon>
												<div
													style={{
														height: "100%",

														display: "flex",
														flexDirection: "column",
														justifyContent: "center",
														alignContent: "center",
													}}
												>
													<Box
														sx={{
															bgcolor: "#1e85e6",
															padding: "1.5px",
															marginTop: "-25px",
															zIndex: "2",
															height: "118%",
															marginLeft: "14px",
															position: "absolute",
														}}
													></Box>
													<Box sx={{ bgcolor: "white", zIndex: "5", borderRadius: "50%" }}>
														{(section_id < current_section) |
														((section_id === current_section) &
															(index + 1 < current_subsection)) ? (
															<CheckCircleOutlineIcon
																sx={{
																	height: "30px",
																	width: "30px",

																	color: "#1e85e6",
																}}
															/>
														) : (
															<RadioButtonUncheckedIcon
																sx={{
																	height: "30px",
																	width: "30px",

																	color: "#1e85e6",
																}}
															/>
														)}
													</Box>
												</div>
											</ListItemIcon>
										}
										<ListItemText primary={section.title} />
									</ListItemButton>
								))}
							</List>
						</Collapse>
					</Box>
				</Box>
			</center>
		</div>
	);
}
