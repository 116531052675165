import baseApi from "./@baseURL";

const addQuiz = async (id, body) => {
	try {
		const res = await baseApi.post("quiz/add/" + id, body);
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};
const updateQuiz = async (id, body) => {
	try {
		const res = await baseApi.patch("quiz/" + id, body);
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};
const getQuiz = async (id) => {
	try {
		const res = await baseApi.get("quiz/get/" + id);
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};
const deleteQuiz = async (id) => {
	try {
		const res = await baseApi.delete("quiz/" + id);
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};
const getAnswerScripts = async (id) => {
	try {
		const res = await baseApi.get("quiz/answerscripts/" + id);
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};
const passTheStudent = async (id) => {
	try {
		const res = await baseApi.post("quiz/pass/" + id);
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};
const repeatTheStudent = async (id) => {
	try {
		const res = await baseApi.post("quiz/repeat/" + id);
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};

export { addQuiz, getQuiz, updateQuiz, deleteQuiz, getAnswerScripts, repeatTheStudent, passTheStudent };
