import React from "react";
import { Link } from "react-router-dom";
const ButtenTypeOne = ({ name, bgColor, btnCount, href }) => {
	if (btnCount === "one") {
		return (
			<>
				<div className="row w-100">
					<Link to={href || "/"} className="view-btn">
						{name}
					</Link>
				</div>
			</>
		);
	}
	if (btnCount === "second") {
		return (
			<>
				<center>
					<div className="row w-100 justify-content-center align-items-center">
						<Link to={href || "/"} className="view-btn">
							{name}
						</Link>
					</div>
				</center>
			</>
		);
	}
};

export default ButtenTypeOne;
