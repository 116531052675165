import { Button, Collapse, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Box } from "@mui/system";
import { Link, useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import useAuth from "../../util/state/authState";
import AdminUpdatePassword from "./AdminUpdatePassword";

function TaskBar(params) {
	const navigate = useNavigate();
	const { admin, adminSignOut } = useAuth();
	const matches = useMediaQuery("(min-width:600px)");
	const [openMenu, setOpenMenu] = useState(false);
	const handleOpen = (prev) => {
		setOpenMenu(!prev);
	};

	if (!admin) return <></>;
	if (matches) {
		return (
			<div>
				<Box
					sx={{
						bgcolor: "#d7dbd8",
						padding: "10px",
						display: "flex",
						justifyContent: "space-between",
						flexWrap: "wrap",
					}}
				>
					{
						<>
							<Button
								sx={{
									textTransform: "lowercase",
									marginLeft: "10px",
									color: "#053075",
									fontWeight: "500",
								}}
							>
								<AccountCircleIcon sx={{ marginRight: "5px" }} />
								{admin.email}
							</Button>
							<Box
								sx={{
									display: "flex",
									flexWrap: "wrap",
									width: "40%",
									justifyContent: "right",
								}}
							>
								<AdminUpdatePassword />
								<Button
									onClick={() => {
										adminSignOut();
										navigate("/", { replaced: true });
									}}
									sx={{ color: "#053075" }}
								>
									<ExitToAppIcon />
									Sign out
								</Button>
								<Link to="/unisuperuser/dashboard" style={{ textDecoration: "none" }}>
									<Button sx={{ marginLeft: "30px", color: "#053075" }}>
										<AdminPanelSettingsIcon />
										Dashboard
									</Button>
								</Link>
							</Box>
						</>
					}
				</Box>
			</div>
		);
	} else {
		return (
			<div>
				<Box
					sx={{
						bgcolor: "#d7dbd8",
					}}
				>
					<Button onClick={() => handleOpen()}>
						<ArrowDropDownIcon sx={{ color: "#053075" }} />
					</Button>
				</Box>

				<Collapse in={openMenu}>
					<Box
						sx={{
							bgcolor: "#d7dbd8",
							alignContent: "left",
							display: "flex",
							justifyContent: "center",
							flexWrap: "wrap",
							flexDirection: "column",
						}}
					>
						<List>
							<Divider />
							<ListItem>
								<ListItemButton sx={{ color: "#053075" }}>
									<ListItemIcon>
										<AccountCircleIcon sx={{ color: "#053075" }} />
									</ListItemIcon>
									<ListItemText primary="user@seekahost.com" />
								</ListItemButton>
							</ListItem>
							<Divider />
							<ListItem>
								<ListItemButton sx={{ color: "#053075" }}>
									<ListItemIcon>
										<ExitToAppIcon sx={{ color: "#053075" }} />
									</ListItemIcon>
									<ListItemText primary="SIGN OUT" />
								</ListItemButton>
							</ListItem>
							<Divider />
							<ListItem>
								<ListItemButton sx={{ color: "#053075" }}>
									<ListItemIcon>
										<AdminPanelSettingsIcon sx={{ color: "#053075" }} />
									</ListItemIcon>
									<ListItemText primary="DASHBOARD" />
								</ListItemButton>
							</ListItem>
						</List>
					</Box>
				</Collapse>
			</div>
		);
	}
}
export default TaskBar;
