import React from "react";
import SectionOne from "../components/Homepage/SectionOne";
import SectionTwo from "../components/Homepage/SectionTwo";
import SectionThree from "../components/Homepage/SectionThree";
import SectionFour from "../components/Homepage/SectionFour";
import SectionFive from "../components/Homepage/SectionFive";
import SectionSix from "../components/Homepage/SectionSix";

function Main() {
	return (
		<>
			<SectionOne />
			<SectionTwo />
			<SectionThree />
			<SectionFour />
			<SectionFive />
			<SectionSix />
		</>
	);
}

export default Main;
