import { TextField, Box, Button,Alert } from "@mui/material";
import React, {  useState } from "react";
import { confirmAdmin } from "../../util/api/admin";
import useAuth from "../../util/state/authState";
import {useForm} from "react-hook-form"
import { useNavigate } from "react-router-dom";
import LoaderDefault from "../loaders/LoaderDefault";

function ConfirmAdmin() {
	const {register,handleSubmit,formState:{errors},getValues} =useForm()
	const [loading, setloading] = useState(false);
	const navigate = useNavigate();
	const { admin } = useAuth();
	const [showError, setshowError] = useState("")
	

	const onSubmit = async (data) => {
		if (admin ) {
			setloading(true);
			const res = await confirmAdmin({ email: admin.email, code: data.code,newpassword:data.newpassword1 });
			setloading(false);
			if (res) {
				navigate("/admin", { replace: true });
			} else {
				setshowError("Something went wrong")
				
			}
		}
	};

	return loading ? (
		<LoaderDefault></LoaderDefault>
	) : (
		<Box sx={{ padding: "3em", mx: "auto" }}>
			<h4>Ask other admins to get confirm code</h4>
			{/* <TextField type="number" required variant="filled" inputRef={ref} label="Confirmation code"></TextField>
			<Button onClick={submit} sx={{ margin: "1em" }}>
				Confirm
			</Button> */}
			<Box
					
					component="form"
					onSubmit={handleSubmit(onSubmit)}
				>
					
					{showError && (
						<Alert sx={{ margin: "1em" }} severity="error">
							{showError || "Unkonow Error Occured"}
						</Alert>
					)}
					<TextField
						sx={{ margin: 1 }}
						label="Confirmation code"
						variant="outlined"
						type="number"
						error={errors.code && true}
						helperText={errors.code ? "Invalid Input" : " "}
						{...register("code", {
							required: true,
							minLength: 3,
						})}
					/>
					<TextField
						sx={{ margin: 1 }}
						label="New Password"
						variant="outlined"
						type="password"
						error={errors.newpassword1 && true}
						helperText={errors.newpassword1 ? "Invalid Input" : " "}
						{...register("newpassword1", {
							required: true,
							minLength: 6,
						})}
					/>
					<TextField
						sx={{ margin: 1 }}
						label="Retype New Password"
						variant="outlined"
						error={errors.newpassword2 && true}
						type="password"
						helperText={errors.newpassword2 ? "Invalid Input" : " "}
						{...register("newpassword2", {
							required: "Please confirm password!",
							validate: {
								matchesPreviousPassword: (value) => {
									const { newpassword1 } = getValues();
									return (
										newpassword1 === value ||
										"Passwords should match!"
									);
								},
							},
						})}
					/>
					{errors.newpassword2 && (
						<Alert severity="error">
							{"Passwords should match!"}
						</Alert>
					)}
					<br />
					<Box>
						<Button
							disabled={loading}
							type="submit"
							sx={{ margin: 3 }}
							variant="contained"
						>
							Change
						</Button>

						
					</Box>
				</Box>
		</Box>
	);
}

export default ConfirmAdmin;
