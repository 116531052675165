import React from "react";
// import draftToHtml from "draftjs-to-html";
import "../../styles/richtexteditor.css";

function RichTextViewer({ rawStringData }) {
	//	const [description, setdescription] = useState("");

	// const handleDescription = (descr) => {
	// 	try {
	// 		const raw = JSON.parse(descr);
	// 		const html = draftToHtml(raw);
	// 		setdescription(html);
	// 	} catch (error) {
	// 		setdescription("");
	// 	}
	// };
	// useEffect(() => {
	// 	handleDescription(rawStringData);
	// }, [rawStringData]);
	return (
		<div
			style={{
				display: "flex",
				flexWrap: "wrap",
				overflowX: "clip",
				alignItems: "center",
				width: "100%",
				justifyContent: "center",
			}}
		>
			{rawStringData ? (
				<div className="imageView TextView cutom-div" dangerouslySetInnerHTML={{ __html: rawStringData }}></div>
			) : (
				<></>
			)}
		</div>
	);
}

export default RichTextViewer;
