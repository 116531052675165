import baseApi from "./@baseURL";

const getAdmins = async () => {
	try {
		const res = await baseApi.get("admin");
		if (res.headers["x-access-token"]) {
			sessionStorage.setItem("_AT", res.headers["x-access-token"]);
		}
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};
const signOut = async () => {
	try {
		const res = await baseApi.post("admin/signOut");

		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};
const addAdmin = async (body) => {
	try {
		const res = await baseApi.post("admin/add", body);
		if (res.headers["x-access-token"]) {
			sessionStorage.setItem("_AT", res.headers["x-access-token"]);
		}
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};
const confirmAdmin = async (body) => {
	try {
		const res = await baseApi.post("admin/confirm", body);
		if (res.headers["x-access-token"]) {
			sessionStorage.setItem("_AT", res.headers["x-access-token"]);
		}
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};
const deleteAdmin = async (id, body) => {
	try {
		const res = await baseApi.patch("admin/" + id, body);
		if (res.headers["x-access-token"]) {
			sessionStorage.setItem("_AT", res.headers["x-access-token"]);
		}
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};

const signIn = async (body) => {
	try {
		const res = await baseApi.post("admin/signIn", body);
		localStorage.setItem("_RT", res.headers["x-refresh-token"]);

		sessionStorage.setItem("_AT", res.headers["x-access-token"]);
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};
const updatePasssword = async (id, body) => {
	try {
		const res = await baseApi.patch("admin/changePassword/" + id, body);
		if (res.headers["x-access-token"]) {
			sessionStorage.setItem("_AT", res.headers["x-access-token"]);
		}
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};
const changeRating = async (id, body) => {
	try {
		const res = await baseApi.patch("admin/changeRating/" + id, body);
		if (res.headers["x-access-token"]) {
			sessionStorage.setItem("_AT", res.headers["x-access-token"]);
		}
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};

export { getAdmins, addAdmin, deleteAdmin, signIn, signOut, confirmAdmin, updatePasssword, changeRating };
