import React from "react";
import { Typography, Button, Box, Alert } from "@mui/material";
import Logo from "../assets/images/logo.png";
import { sendRestPasswordRq } from "../util/api/instructor";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

//TODO:handle errors
//TODO:add custom error to instructor 401 {message:request account aprovel}
function FogotPassword() {
	const {
		register,
		
		formState: { errors },
		handleSubmit,
	} = useForm();

	const navigate=useNavigate()


	const onSubmit = async(data) => {
		const res =await sendRestPasswordRq({email:data.email})
		console.log(res)
		//TODO:custom alert
		alert("Please check the emails!")
		navigate("/",{replace:true})
	};

	return (
		<div>
			<div className="loginContainer">
				<div
					style={{
						display: "flex",
						alignContent: "center",
						flexWrap: "wrap",
						justifyContent: "center",
						flexDirection: "column",
						margin: "0px",
					}}
				>
					<center>
						<div className="logoContainer">
							<img src={Logo} width="150px" alt="seekahost"></img>
						</div>
					</center>
					<Box
						sx={{
							boxShadow: "0 0 1px rgb(0 0 0 / 10%), 0 2px 24px rgb(0 0 0 / 8%)",
							width: "100%",
							maxWidth: "420px",
							margin: "1em",
							borderRadius: "1%",
							padding: "3em",
						}}
						component="form"
						onSubmit={handleSubmit(onSubmit)}
					>
						<center>
							<Typography
								variant="h1"
								sx={{
									color: "#0c0d0f",
									fontSize: "32px",
									lineHeight: "40px",
									marginBottom: "32px",
									fontWeight: "700",
									fontFamily: "inherit",
									textTransform: "capitalize",
								}}
							>
								{"FogotPassword"}
							</Typography>
							<Alert
								severity="error"
								sx={{ marginBottom: "2px" }}
								hidden={!(errors.email )}
							>
								Invalid Email
							</Alert>
						</center>
						<div className="formControll">
							<label className="label">Email Address</label>
							<input
								type="email"
								name="email"
								placeholder="Enter Email"
								className="inputLogin"
								{...register("email", { required: true })}
							></input>
						</div>
						
						<center>
							<Button sx={{marginTop:"1em"}} variant="contained" className="loginButton" size="large" type="submit">
							Send Request
							</Button>
						</center>
						<center></center>
					</Box>
				</div>
			</div>
		</div>
	);
}
export default FogotPassword;
