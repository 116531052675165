import baseApi from "./@baseURL";

//fixed bug:0019
const getInstructorProfileData = async () => {
	try {
		const res = await baseApi.get("instructor/getprofile");
		if (res.headers["x-access-token"]) {
			sessionStorage.setItem("_AT", res.headers["x-access-token"]);
		}
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};
const getNotApprovedInstructors = async () => {
	try {
		const res = await baseApi.get("instructor/notApproved");
		if (res.headers["x-access-token"]) {
			sessionStorage.setItem("_AT", res.headers["x-access-token"]);
		}
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};
const getApprovedInstructors = async () => {
	try {
		const res = await baseApi.get("instructor/approved");
		if (res.headers["x-access-token"]) {
			sessionStorage.setItem("_AT", res.headers["x-access-token"]);
		}
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};

const approveInstructor = async (id) => {
	try {
		const res = await baseApi.patch("instructor/approve/" + id);
		if (res.headers["x-access-token"]) {
			sessionStorage.setItem("_AT", res.headers["x-access-token"]);
		}
		return res.data;
	} catch (error) {
		//TODO:show custom error message
		console.log(error.response);
	}
};

//============================================================
const getActiveCourses = async (id) => {
	try {
		const res = await baseApi.get("instructor/ApprovedCourses/" + id);
		return res.data;
	} catch (error) {
		//TODO:show custom error message
		console.log(error.response);
	}
};
const getPendingCourses = async (id) => {
	try {
		const res = await baseApi.get("instructor/pendingCourses/" + id);
		if (res.headers["x-access-token"]) {
			sessionStorage.setItem("_AT", res.headers["x-access-token"]);
		}
		return res.data;
	} catch (error) {
		//TODO:show custom error message
		console.log(error.response);
	}
};
const getDraftCourses = async (id) => {
	try {
		const res = await baseApi.get("instructor/draftCourses");
		if (res.headers["x-access-token"]) {
			sessionStorage.setItem("_AT", res.headers["x-access-token"]);
		}
		return res.data;
	} catch (error) {
		//TODO:show custom error message
		console.log(error.response);
	}
};

const removeInstructor = async (id) => {
	try {
		const res = await baseApi.delete("instructor/" + id);
		if (res.headers["x-access-token"]) {
			sessionStorage.setItem("_AT", res.headers["x-access-token"]);
		}
		return res.data;
	} catch (error) {
		//TODO:show custom error message
		console.log(error.response);
	}
};

//===========================Authrentications============================
const signIn = async (body) => {
	try {
		const res = await baseApi.post("instructor/signIn", body);
		localStorage.setItem("_RT", res.headers["x-refresh-token"]);
		sessionStorage.setItem("_AT", res.headers["x-access-token"]);
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};
const signOut = async (id) => {
	//TODO:add this in auth
	try {
		const res = await baseApi.post("instructor/signOut/");
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};
const updateInstructor = async (id, body) => {
	try {
		const res = await baseApi.patch("instructor/", body);
		if (res.headers["x-access-token"]) {
			sessionStorage.setItem("_AT", res.headers["x-access-token"]);
		}
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};
const updatePassword = async (id, body) => {
	try {
		const res = await baseApi.patch("instructor/changePassword", body);
		if (res.headers["x-access-token"]) {
			sessionStorage.setItem("_AT", res.headers["x-access-token"]);
		}
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};
const signUp = async (body) => {
	try {
		const res = await baseApi.post("instructor/signUp", body);
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};
const sendRestPasswordRq = async (body) => {
	try {
		const res = await baseApi.post("instructor/resetpassword", body);

		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};
const confirmRestPasswordRq = async (body) => {
	try {
		const res = await baseApi.post("instructor/resetpasswordconfirm", body);

		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};

export {
	getNotApprovedInstructors,
	approveInstructor,
	getPendingCourses,
	getActiveCourses,
	getDraftCourses,
	removeInstructor,
	signIn,
	signOut,
	updateInstructor,
	updatePassword,
	signUp,
	confirmRestPasswordRq,
	sendRestPasswordRq,
	getApprovedInstructors,
	getInstructorProfileData,
};
