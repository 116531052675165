import React, { useState, useEffect } from "react";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LockIcon from "@mui/icons-material/Lock";
import PdfIcon from "@mui/icons-material/PictureAsPdf";
import ImageIcon from "@mui/icons-material/Image";
import TextIcon from "@mui/icons-material/TextFields";
import VideoIcon from "@mui/icons-material/VideoLibrary";

// import ArticleIcon from "@mui/icons-material/Article";
import {
	Collapse,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Divider,
} from "@mui/material";
import { getAdminPreviewLectures } from "../../util/api/lecture";
import LoaderDefault from "../loaders/LoaderDefault";
import CoursePreviewModal from "../modals/CoursePreviewModal";

function AdminCourseContentPreview({ sections }) {
	const [open, setOpen] = useState(false);
	const [clickedSection, setClickedSection] = useState(0);
	const [loading, setloading] = useState(false);
	const [openPreview, setopenPreview] = useState(false);
	const [selectedIndex, setselectedIndex] = useState("");

	const hndleClosePreview = () => {
		setopenPreview(false);
	};
	const hndleOpenPreview = () => {
		setopenPreview(true);
	};

	useEffect(() => {
		async function getData() {
			if (!sections[clickedSection].lectures) {
				setloading(true);
				const res = await getAdminPreviewLectures(
					sections[clickedSection]._id
				);
				sections[clickedSection].lectures = res;
				setloading(false);
			}
		}
		if (Array.isArray(sections) && sections.length !== 0) {
			getData();
		}
	}, [clickedSection, sections]);

	return (
		<div>
			{Array.isArray(sections) &&
				sections.map((section, idx) => (
					<List
						// component="nav"
						sx={{
							bgcolor: "#1d2b3a",

							color: "white",
						}}
						key={idx}
					>
						<ListItemButton
							primary="Lesson 1"
							sx={
								{
									// minWidth: "700px",
								}
							}
							onClick={() => {
								if (clickedSection === idx) {
									setOpen(!open);
								} else {
									setOpen(true);
									setClickedSection(idx);
								}
							}}
						>
							{(idx === clickedSection) & open ? (
								<ExpandLess />
							) : (
								<ExpandMore />
							)}
							<ListItemText
								primary={section.title}
								sx={{ marginLeft: "10px" }}
							/>

							{/* <ListItemIcon>
								<ArticleIcon sx={{ color: "white" }} />
							</ListItemIcon>
							<ListItemText primary={section.lectures && section.lectures.length + " Lessons"} /> */}
						</ListItemButton>
						<CoursePreviewModal
							open={openPreview}
							id={idx}
							handleClose={hndleClosePreview}
							section={section}
							selectedSection={clickedSection}
							selectedIndex={selectedIndex}
						></CoursePreviewModal>
						<Collapse
							in={idx === clickedSection && open}
							timeout="auto"
							unmountOnExit
						>
							{loading ? (
								<LoaderDefault />
							) : (
								<List
									component="div"
									// disablePadding
									sx={{
										bgcolor: "white",
										color: "#1d2b3a",
									}}
								>
									<Divider />

									{section.lectures &&
										section.lectures.map(
											(topic, lectureIdx) => (
												<div key={lectureIdx}>
													<ListItemButton
														key={lectureIdx}
													>
														<ListItemIcon>
															<LectureIcon
																type={
																	topic.catergory
																}
															/>
														</ListItemIcon>
														<ListItemText
															primary={
																idx +
																1 +
																"." +
																(lectureIdx +
																	1) +
																" " +
																topic.title
															}
														/>

														<ListItemText
															sx={{
																display: "flex",
																justifyContent:
																	"end",
																paddingRight:
																	"3em",
															}}
															primary="Preview"
															primaryTypographyProps={{
																color: "blue",
															}}
															onClick={() => {
																setselectedIndex(
																	lectureIdx
																);
																hndleOpenPreview();
															}}
														/>
													</ListItemButton>
													<Divider />
												</div>
											)
										)}
								</List>
							)}
						</Collapse>
					</List>
				))}
		</div>
	);
}

const LectureIcon = ({ type }) => {
	switch (type) {
		case "DO":
			return <TextIcon></TextIcon>;
		case "DI":
			return <ImageIcon></ImageIcon>;
		case "DV":
			return <VideoIcon></VideoIcon>;
		case "DP":
			return <PdfIcon></PdfIcon>;
		default:
			return <LockIcon></LockIcon>;
	}
};

export default AdminCourseContentPreview;
