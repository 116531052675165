import React from "react";
import { Routes, Route } from "react-router-dom";

import Main from "./pages/Main";
import Couses from "./pages/Couses";
import CousePreview from "./components/Couses/CousePreview";
import NavBar from "./components/HeaderFooter/Navbar";
import Footer from "./components/HeaderFooter/Footer";
import AdminDashboard from "./pages/AdminDashboad";
import NotFound from "./components/NotFound";
import InstructorDashboard from "./pages/InstructorDashboard";
import StudentProfile from "./pages/StudentProfile";
import CourseEditor from "./pages/CourseEditor";
import QuizeEditor from "./components/Questions/QuizeEditor";

import "../src/styles/App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import StudentCourseContent from "./pages/StudentCourseContent";

import { AuthProvider } from "./util/state/authState";
import Login from "./pages/Login";
import StudentPrivateRoute from "./components/PrivateRoutes/StudentPrivateRoute";
import InstructorPrivateRoute from "./components/PrivateRoutes/InstructorPrivateRoute";
import AdminPrivateRoute from "./components/PrivateRoutes/AdminPrivateRoute";
import InstructorProfile from "./pages/InstructorProfile";
import AdminLoginPage from "./components/Admin/AdminLoginPage";
import InstructorSignUp from "./pages/InstructorSignUp";
import { SnackbarProvider } from "notistack";
import FogotPassword from "./pages/FogotPassword";
import FogotPasswordConfirm from "./pages/FogotPasswordConfirm";
import WebMiddle from "./pages/WebMiddle";
import ContactUs from "./pages/ContactUs";

//TODO: make prtected route
//TODO; caching system

function App() {
	return (
		<>
			<AuthProvider>
				<SnackbarProvider>
					<NavBar />
					<Routes>
						<Route path="/" element={<Main></Main>}></Route>
						<Route path="/courses" element={<Couses></Couses>}></Route>
						<Route path="/forgotpassword" element={<FogotPassword></FogotPassword>}></Route>
						<Route
							path="/forgotpasswordconfirm/:token"
							element={<FogotPasswordConfirm></FogotPasswordConfirm>}
						></Route>
						<Route path="/courses/:searchId" element={<CousePreview></CousePreview>}></Route>

						<Route path="*" element={<NotFound></NotFound>}></Route>
						<Route path="/signin" element={<Login></Login>}></Route>
						<Route path="/about-us" element={<WebMiddle></WebMiddle>}></Route>
						<Route path="/contact-us" element={<ContactUs></ContactUs>}></Route>

						{/* can Custemize */}
						<Route path="/unisuperuser" element={<AdminLoginPage />}></Route>

						{/* private route */}

						<Route
							path="/unisuperuser/dashboard"
							element={
								<AdminPrivateRoute>
									<AdminDashboard></AdminDashboard>
								</AdminPrivateRoute>
							}
						></Route>

						<Route path="/Courses/editor/:courseId" element={<CourseEditor></CourseEditor>}></Route>

						<Route
							path="/instructor/dashboard"
							element={
								<InstructorPrivateRoute>
									<InstructorDashboard />
								</InstructorPrivateRoute>
							}
						></Route>
						<Route
							path="/instructor/profile"
							element={
								<InstructorPrivateRoute>
									<InstructorProfile />
								</InstructorPrivateRoute>
							}
						></Route>

						<Route
							path="/student/profile"
							element={
								<StudentPrivateRoute>
									<StudentProfile />
								</StudentPrivateRoute>
							}
						></Route>
						<Route path="/instructor/signup" element={<InstructorSignUp></InstructorSignUp>}></Route>

						<Route path="/CourseContent/:id" element={<StudentCourseContent />}></Route>

						<Route path="/quizeditor/:id" element={<QuizeEditor></QuizeEditor>}></Route>
					</Routes>
					<Footer />
				</SnackbarProvider>
			</AuthProvider>
		</>
	);
}

export default App;
