import React, { useState } from "react";
import { TableRow, Button, TableCell, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { addAdmin } from "../../util/api/admin";

function AddAdminRow({ uiUpdated }) {
	const [loading, setloading] = useState(false);
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();
	const onSubmit = async (data, e) => {
		setloading(true);
		await addAdmin(data);
		setloading(false);
		reset({});
		uiUpdated(true);
	};
	const onError = (err, e) => {
		console.log(err);
	};

	return (
		<>
			<TableRow>
				<TableCell>
					<TextField
						type="email"
						error={errors.email}
						helperText={errors.email ? "*Email Required" : ""}
						variant="outlined"
						label="Email"
						{...register("email", { required: true })}
					></TextField>
				</TableCell>

				<TableCell>
					<TextField
						error={errors.username}
						helperText={errors.username ? "*UserName Required" : ""}
						variant="outlined"
						label="UserName"
						{...register("name", { required: true })}
					></TextField>
				</TableCell>

				<TableCell>
					<Button
						disabled={loading}
						onClick={handleSubmit(onSubmit, onError)}
						sx={{ margin: "1em" }}
						variant="outlined"
					>
						{loading ? "Loading" : "Add an Admin"}
					</Button>
				</TableCell>
			</TableRow>
		</>
	);
}

export default AddAdminRow;
