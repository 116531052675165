import React from "react";

const SectionType = ({
	title,
	contentOne,
	contentSecond,
	contentThird,
	imgSTT,
	sectionType,
	bgColor,
	imgLeft,
}) => {
	if (sectionType === "four") {
		return (
			<>
				<section
					className="typeone"
					style={{ backgroundColor: bgColor }}
				>
					<div className="container mt-5 mb-5">
						<div className="row">
							<div className="col-md-6">
								<span className="round-bar"></span>
								<p className="section-heading">{title}</p>
								<br />
								<p>{contentOne}</p>
								<p>{contentSecond}</p>
							</div>
							<br />
							<div className="col-md-6">
								<img
									src={imgSTT}
									alt=""
									className="img-fluid"
								/>
							</div>
						</div>
					</div>
				</section>
			</>
		);
	} else if (sectionType === "five") {
		return (
			<>
				<section
					className="typetwo"
					style={{ backgroundColor: bgColor }}
				>
					<div className="container mt-5">
						<div className="row">
							<div className="col-xs-12 col-md-6 order-md-2">
								<span className="round-bar"></span>
								<p className="section-heading">{title}</p>
								<p>{contentOne}</p>
								<p>{contentSecond}</p>
							</div>
							<div className="col-xs-12 col-md-6 order-md-1">
								<img
									src={imgSTT}
									alt=""
									className="img-fluid"
									style={{ marginBottom: "3rem" }}
								/>
							</div>
						</div>
					</div>
				</section>
			</>
		);
	} else if (sectionType === "six") {
		return (
			<>
				<section
					className="typeone"
					style={{ backgroundColor: bgColor }}
				>
					<div className="container mt-5 mb-5">
						<div className="row">
							<div className="col-md-6">
								<span className="round-bar"></span>
								<p className="section-heading">{title}</p>
								<br />
								<p>{contentOne}</p>
								<p>{contentSecond}</p>
							</div>
							<br />
							<div className="col-md-6">
								<img
									src={imgSTT}
									alt=""
									className="img-fluid"
								/>
							</div>
						</div>

						<div className="row last-row">
							<div className="col-md-6">
								<br />
								<img
									src={imgLeft}
									alt=""
									className="img-fluid"
								/>
							</div>
							<div className="col-md-6">
								<p>{contentThird}</p>
							</div>
							<br />
						</div>
					</div>
				</section>
			</>
		);
	} else {
		return <></>;
	}
};

export default SectionType;
