import React from 'react';
import CategoriesItem from './CategoriesItem';
import './ServiceCategories.css';
import buffer from '../../assets/Icons/social-buffer-outline.svg';
import bulb from '../../assets/Icons/ios-lightbulb-outline.svg';
import bookMark from '../../assets/Icons/ios-bookmarks-outline.svg';
import bell from '../../assets/Icons/ios-bell-outline.svg';
import briefCase from '../../assets/Icons/ios-briefcase-outline.svg';
import browsers from '../../assets/Icons/ios-browsers-outline.svg';

function ServiceCategories() {
  return (
    <div className="categories" id="ourservice">
      <div className="categories-bigHeader">Our Service Categories</div>
      <div className="categories-subHeader">
        So many academic goals that we want you to achieve
      </div>
      <div className="categoriesSection">
        <div className="categories-row">
          <CategoriesItem
            icon={buffer}
            contentHeader="SKILLS"
            content="From learning the fundamentals of SEO to becoming a digital marketing master, from understanding how to start a blog to managing your and other’s online presence – whatever your academic goal is for your future career in digital marketing & learning, you will find the knowledge and expertise required here."
          />
          <CategoriesItem
            icon={bulb}
            contentHeader="SELF LEARNING"
            content="Online learning is almost like a one-to-one tutorial with the difference that you can’t interact directly with the instructor. It’s therefore vital that you apply what you learn without delay and get started with your online journey. Learning by doing is the principle we believe in and especially with digital skills it is important to use them daily, if possible.
For any additional questions you can contact the course instructor, but it’s really down to you to implement what you learn."
          />
          <CategoriesItem
            icon={bookMark}
            contentHeader="KNOWLEDGE"
            content="Gaining the knowledge about online businesses, digital learning and working, digital marketing and more is important for comprehending what you’re learning the skills for. Do your own research on top of the courses you take here. The digital world is moving and changing fast – never stand still!"
          />
        </div>
        <div className="categories-row">
          <CategoriesItem
            icon={bell}
            contentHeader="DISCUSSION"
            content="Prompt conversation and communication within your community of learners and instructors with our internal communication system integrated in every course."
          />
          <CategoriesItem
            icon={briefCase}
            contentHeader="SKILLS"
            content="We accept online payments from almost every country in any currency. You can checkout via popular payment gateways like PayPal, Stripe, BACS and debit and credit cards."
          />
          <CategoriesItem
            icon={browsers}
            contentHeader="HOSTED CONTENT"
            content="As an instructor, upload documents, presentations, videos and images, and learning resources. Simply drag and drop content from your computer straight into your online course."
          />
        </div>
      </div>
    </div>
  );
}

export default ServiceCategories;
