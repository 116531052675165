import baseApi from "./@baseURL";

const addLecture = async ( body) => {
	try {
		const res = await baseApi.post("lecture/" , body);
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};
const getLectures = async (sectionId) => {
	try {
		const res = await baseApi.get("lecture/all/" + sectionId);
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};
const getPreviewLectures = async (sectionId) => {
	try {
		const res = await baseApi.get("lecture/preview/" + sectionId);
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};
const getAdminPreviewLectures = async (sectionId) => {
	try {
		const res = await baseApi.get("lecture/preview/admin/" + sectionId);
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};
const updateLecture = async (lectureID, body) => {
	try {
		const res = await baseApi.patch("lecture/" + lectureID, body);
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};
const deleteLecture = async (sectionId) => {
	try {
		const res = await baseApi.delete("lecture/" + sectionId);
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};

export { getLectures, addLecture, deleteLecture, updateLecture, getPreviewLectures, getAdminPreviewLectures };
