// import { Checkbox, FormControlLabel, Typography, Button, Alert, Box } from "@mui/material";
import { Typography, Button, Alert, Box } from "@mui/material";
import "../styles/login.css";
import Logo from "../assets/images/logo.png";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import useAuth from "../util/state/authState";
import LoaderDefault from "../components/loaders/LoaderDefault";

export default function Login() {
	const navigate = useNavigate();
	const { signIn } = useAuth();
	const [loginType, setloginType] = useState("instructor");
	const [loading, setloading] = useState(false);
	const {
		formState: { errors },
		register,
		handleSubmit,
	} = useForm();

	const onSubmit = async (data) => {
		//TODO handle errors
		setloading(true);
		const res = await signIn(data.username, data.password, loginType);
		setloading(false);
		if (res && res.error) {
			//TODO:handle error
			alert("loging faild");
		} else {
			navigate("/", { replace: true });
			window.location.reload();
		}
	};
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return loading ? (
		<LoaderDefault></LoaderDefault>
	) : (
		<div className="loginContainer">
			<div
				style={{
					display: "flex",
					alignContent: "center",
					flexWrap: "wrap",
					justifyContent: "center",
					flexDirection: "column",
					margin: "0px",
				}}
			>
				<center>
					<div className="logoContainer">
						<img src={Logo} width="150px" alt="seekahost"></img>
					</div>
				</center>
				<Box
					sx={{
						boxShadow: "0 0 1px rgb(0 0 0 / 10%), 0 2px 24px rgb(0 0 0 / 8%)",
						width: "100%",
						maxWidth: "420px",
						margin: "1em",
						borderRadius: "1%",
						padding: "3em",
					}}
					component="form"
					onSubmit={handleSubmit(onSubmit)}
				>
					<center>
						<Typography
							variant="h1"
							sx={{
								color: "#0c0d0f",
								fontSize: "32px",
								lineHeight: "40px",
								marginBottom: "32px",
								fontWeight: "700",
								fontFamily: "inherit",
								textTransform: "capitalize",
							}}
						>
							{"sign in as " + loginType}
						</Typography>
						<Alert
							severity="error"
							sx={{ marginBottom: "2px" }}
							hidden={!(errors.username && errors.password)}
						>
							Invalid Credentials
						</Alert>
						<Alert severity="error" sx={{ marginBottom: "30px" }} hidden={!errors.login}>
							Login Error
						</Alert>
					</center>
					<div className="formControll">
						<label className="label">Email Address</label>
						<input
							type="email"
							name="username"
							placeholder="Enter Email"
							className="inputLogin"
							{...register("username", { required: true })}
						></input>
					</div>
					<div className="formGrp">
						<label className="label">Password</label>
						<input
							type="password"
							name="password"
							placeholder="Password"
							className="inputLogin"
							{...register("password", { required: true, minLength: 6 })}
						></input>
					</div>
					<div className="inputGrp" style={{ margin: "16px 0px" }}>
						{/* fixed bug:0025 */}
						{/* <FormControlLabel control={<Checkbox />} label="Remeber me"></FormControlLabel> */}
					</div>
					<center>
						<Button variant="contained" className="loginButton" size="large" type="submit">
							Login
						</Button>
					</center>
					<center>
						<Button
							onClick={() => setloginType("instructor")}
							hidden={loginType === "instructor"}
							className="loginButton"
							size="large"
							sx={{ marginTop: "2em" }}
						>
							Sign In As an Instructor
						</Button>
						<br></br>
						<Link style={{ textDecoration: "none" }} to={"/instructor/signup"}>
							<Button
								hidden={loginType === "student"}
								className="loginButton"
								size="large"
								sx={{ marginTop: "2em" }}
							>
								Become an Instructor
							</Button>
						</Link>
						<Link style={{ textDecoration: "none" }} to={"/forgotpassword"}>
							<Button className="loginButton" size="large" sx={{ marginTop: "2em" }}>
								FORGOT PASSWORD?
							</Button>
						</Link>
					</center>
				</Box>
			</div>
		</div>
	);
}
