import React, { useEffect, useState } from "react";
import ButtonTypeOne from "./pagesComponents/ButtonTypeOne";
import CardComponent from "./pagesComponents/CardComponent";
import { getHomelimitedCourses } from "../../util/api/courses";

const SectionTwo = () => {
	const [limitedCourses, setlimitedCourses] = useState([]);

	useEffect(() => {
		async function getData() {
			const res = await getHomelimitedCourses();
			if (res && Array.isArray(res)) {
				setlimitedCourses(res);
			}
		}
		getData();
	}, []);

	return (
		<>
			<section className="descoverCourses mb-5">
				<div className="container">
					<div className="row w-100 justify-content-center align-items-center">
						<h2 className="discover-heading">Discover Our Featured Courses Online</h2>
					</div>
					<div className="row">
						{limitedCourses.map((course) => (
							<CardComponent
								key={course._id}
								id={course.searchId}
								title={course.title}
								description={course.instructor && course.instructor.name}
								image={course.image}
							/>
						))}
						{/* <CardComponent
							title="SEO TRAINING COURSE"
							description=" first name"
							image="#"
						/>

						<CardComponent
							title="SEO TRAINING COURSE"
							description=" first name"
							image="#"
						/>

						<CardComponent
							title="SEO TRAINING COURSE"
							description=" first name"
							image="#"
						/>

						<CardComponent
							title="SEO TRAINING COURSE"
							description=" first name"
							image="#"
						/>

						<CardComponent
							title="SEO TRAINING COURSE"
							description=" first name"
							image="#"
						/>

						<CardComponent
							title="SEO TRAINING COURSE"
							description=" first name"
							image="#"
						/>

						<CardComponent
							title="SEO TRAINING COURSE"
							description=" first name"
							image="#"
						/>

						<CardComponent
							title="SEO TRAINING COURSE"
							description=" first name"
							image="#"
						/> */}
					</div>
					<ButtonTypeOne name="View All Courses" bgColor="#fecb00" btnCount="second" href="/courses" />
				</div>
			</section>
		</>
	);
};

export default SectionTwo;
