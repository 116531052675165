import baseApi from "./@baseURL";

const signIn = async (body) => {
	try {
		const res = await baseApi.post("student/signIn", body);
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};
const signOut = async (id) => {
	try {
		const res = await baseApi.post("student/signOut/" + id);
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};
const updateStudent = async (id, body) => {
	try {
		const res = await baseApi.patch("student/" + id, body);
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};
const updatePassword = async (id, body) => {
	try {
		const res = await baseApi.patch("student/changePassword/" + id, body);
		return res.data;
	} catch (error) {
		//TODO: add custom error
		console.log(error);
	}
};

export { signIn, updateStudent, signOut, updatePassword };
